var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-roles"},[_c('bread-crumb',{attrs:{"custom-padding":"pt-3 pl-5","items":_vm.itemsMenu}}),_c('h2',{staticClass:"pl-5 pt-3 pb-4"},[_vm._v("Manage Roles")]),_c('a-row',{staticClass:"p-4",attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"roles",attrs:{"md":24,"lg":10}},[_c('list-role',{key:_vm.keyListRole,attrs:{"toggle-role":_vm.toggleRole,"selected-role-id":_vm.selectedRoleId},on:{"role-selected":_vm.onRoleSelected}})],1),(_vm.roleDetails.code)?_c('a-col',{staticClass:"role-details pt-3",attrs:{"md":24,"lg":14}},[_c('div',{staticClass:"d-flex"},[_c('h3',[_vm._v(_vm._s(_vm.roleDetails.name))]),(
            !_vm.roleDetails.isLock && _vm.$can(_vm.MAINTAIN_PERMISSION, _vm.IDENTITY_ROLE)
          )?_c('div',{staticClass:"ml-auto"},[_c('a-popconfirm',{attrs:{"placement":"bottomRight","ok-text":"Yes","ok-type":"danger","cancel-text":"No"},on:{"confirm":function($event){return _vm.deleteRole(_vm.roleDetails.code)}}},[_c('template',{slot:"title"},[_c('p',[_vm._v("Are you sure you want to delete this role?")])]),_c('a-button',{attrs:{"type":"danger","ghost":""}},[_vm._v("Delete")])],2),_c('a-switch',{staticClass:"ml-3",attrs:{"checked":_vm.roleDetails.isActive},on:{"change":function($event){return _vm.toggleRole(_vm.roleDetails, _vm.roleToggleCallback)}}})],1):_vm._e()]),_c('a-row',{staticClass:"entity-details mt-3",attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{attrs:{"md":6}},[_vm._v(" Application "),_c('p',[_vm._v(_vm._s(_vm.roleDetails.appName))])])],1),_c('a-tabs',[_c('a-tab-pane',{key:"1",attrs:{"tab":"Permissions"}},[_c('section',{staticClass:"permissions"},[_c('h4',{staticClass:"mt-3"},[(_vm.permissionsLoading)?_c('a-icon',{attrs:{"slot":"indicator","type":"loading","spin":""},slot:"indicator"}):_vm._e()],1),_c('div',{staticClass:"wrapper"},_vm._l((_vm.permissions),function(p,i){return _c('div',{key:p.key},[_c('div',{staticClass:"d-flex permission-wrapper align-items-center"},[(
                      _vm.expandingObj[p.code] &&
                      !_vm.expandingObj[p.code]['isExpanded'] &&
                      p.operations &&
                      p.operations.length > 0
                    )?_c('a-icon',{staticStyle:{"cursor":"pointer"},attrs:{"type":"right"},on:{"click":function($event){return _vm.toggleOperationSection(p.code)}}}):_vm._e(),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.toggleOperationSection(p.code)}}},[(
                        _vm.expandingObj[p.code] &&
                        _vm.expandingObj[p.code]['isExpanded'] &&
                        p.operations &&
                        p.operations.length > 0
                      )?_c('a-icon',{attrs:{"type":"down"}}):_vm._e(),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(p.name))])],1),_c('state-selector',{staticClass:"ml-auto",attrs:{"disabled":p.disabled || !_vm.$can(_vm.MAINTAIN_PERMISSION, _vm.IDENTITY_ROLE),"item":p,"name":p.id,"item-idx":i,"is-two-states":true},on:{"element-clicked":_vm.onUserPermissionChange}})],1),(
                    _vm.expandingObj[p.code] && _vm.expandingObj[p.code]['isExpanded']
                  )?_c('div',{staticClass:"operator-wrapper"},_vm._l((p.operations),function(o,j){return _c('div',{key:o.key,staticClass:"operator"},[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(o.name))]),_c('state-selector',{staticClass:"ml-auto",attrs:{"is-two-states":true,"disabled":o.disabled ||
                          p.isRestricted ||
                          !_vm.$can(_vm.MAINTAIN_PERMISSION, _vm.IDENTITY_ROLE),"item":Object.assign({}, o,
                          {permissionCode: p.code,
                          permissionIdx: i}),"name":o.id,"item-idx":j},on:{"element-clicked":_vm.onUserOperationChange}})],1)])}),0):_vm._e()])}),0)])]),_c('a-tab-pane',{key:"2",attrs:{"tab":"Overrides"}},[_c('section',[_c('resource',{attrs:{"name":"identities.common.overrided-permissions","api-url":_vm.apiUrl}},[_c('overrided-permissions',{attrs:{"role-id":_vm.roleDetails.id}})],1)],1)])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }