export const GENERAL_COMMENTS_STATUS = [
  {
    id: 1,
    status: "New",
  },
  {
    id: 2,
    status: "WIP",
  },
  {
    id: 3,
    status: "On Hold",
  },
  {
    id: 4,
    status: "Done",
  },
];
