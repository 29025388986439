const STERILIZATION_AUDITS_AUDITGROUP = "sah.auditgrp";
const STERILIZATION_AUDITS_CONTAINERSIZE = "sah.ctnsize";
const STERILIZATION_AUDITS_FILLINGLINE = "sah.fillingline";
const STERILIZATION_AUDITS_LOGGERTYPE = "sah.loggertype";
const STERILIZATION_AUDITS_PDCONTACT = "sah.pdcontact";
const STERILIZATION_AUDITS_PROCESSHISTORY = "sah.processhistory";
const STERILIZATION_AUDITS_TPCONTACT = "sah.tpcontact";
const STERILIZATION_AUDITS_STERILISER = "sah.steriliser";
const APPROVE_PERMISSION = "approve";
const VIEW_PERMISSION = "view";
const SterilizationAudits = () => import("./SterilizationAudits.js");
const ListTPContact = () => import("./index2.js");
const CreateTPContact = () => import("./index3.js");
const EditTPContact = () => import("./index4.js");
const ListPDContact = () => import("./index5.js");
const CreatePDContact = () => import("./index6.js");
const EditPDContact = () => import("./index7.js");
const ListFillingLine = () => import("./index8.js");
const CreateFillingLine = () => import("./index9.js");
const EditFillingLine = () => import("./index10.js");
const ListProcessHistory = () => import("./index11.js");
const CreateProcessHistory = () => import("./index12.js");
const EditProcessHistory = () => import("./index13.js");
const ListLoggerType = () => import("./index14.js");
const CreateLoggerType = () => import("./index15.js");
const EditLoggerType = () => import("./index16.js");
const ListContainerSize = () => import("./index17.js");
const CreateContainerSize = () => import("./index18.js");
const EditContainerSize = () => import("./index19.js");
const ListSteriliser = () => import("./index20.js");
const CreateSteriliser = () => import("./index21.js");
const EditSteriliser = () => import("./index22.js");
const AuditGroups = () => import("./index23.js");
const ListAuditGroups = () => import("./index24.js");
const CreateAuditGroup = () => import("./index25.js");
const EditAuditGroup = () => import("./index26.js");
const FilterAuditGroups = () => import("./index27.js");
const CreateLink = () => import("./index28.js");
const ItemCodeDetail = () => import("./index29.js");
const CreateProductDevelopment = () => import("./index30.js");
const EditProductDevelopment = () => import("./index31.js");
const CreatAuditRecord = () => import("./index32.js");
const EditAuditRecord = () => import("./index34.js");
const CreateMeasurement = () => import("./index35.js");
const EditMeasurement = () => import("./index36.js");
const ReportsMenu = () => import("./index37.js");
var routes = [
  {
    path: "/sterilization-audits",
    name: "SterilizationAudits",
    component: SterilizationAudits,
    redirect: { name: "AuditReports" },
    children: [
      {
        path: "tp-contact",
        name: "TPContact",
        component: ListTPContact,
        meta: `;${VIEW_PERMISSION};${STERILIZATION_AUDITS_TPCONTACT}`,
        children: [
          {
            path: "create",
            name: "CreateTPContact",
            component: CreateTPContact,
            meta: {
              level: 2
            }
          },
          {
            path: ":id",
            name: "EditTPContact",
            component: EditTPContact,
            meta: {
              level: 2
            }
          }
        ]
      },
      {
        path: "pd-contact",
        name: "PDContact",
        component: ListPDContact,
        meta: `;${VIEW_PERMISSION};${STERILIZATION_AUDITS_PDCONTACT}`,
        children: [
          {
            path: "create",
            name: "CreatePDContact",
            component: CreatePDContact,
            meta: {
              level: 2
            }
          },
          {
            path: ":id",
            name: "EditPDContact",
            component: EditPDContact,
            meta: {
              level: 2
            }
          }
        ]
      },
      {
        path: "filling-line",
        name: "FillingLine",
        component: ListFillingLine,
        meta: `;${VIEW_PERMISSION};${STERILIZATION_AUDITS_FILLINGLINE}`,
        children: [
          {
            path: "create",
            name: "CreateFillingLine",
            component: CreateFillingLine,
            meta: {
              level: 2
            }
          },
          {
            path: ":id",
            name: "EditFillingLine",
            component: EditFillingLine,
            meta: {
              level: 2
            }
          }
        ]
      },
      {
        path: "process-history",
        name: "ProcessHistory",
        component: ListProcessHistory,
        meta: `;${VIEW_PERMISSION};${STERILIZATION_AUDITS_PROCESSHISTORY}`,
        children: [
          {
            path: "create",
            name: "CreateProcessHistory",
            component: CreateProcessHistory,
            meta: {
              level: 2
            }
          },
          {
            path: ":id",
            name: "EditProcessHistory",
            component: EditProcessHistory,
            meta: {
              level: 2
            }
          }
        ]
      },
      {
        path: "logger-type",
        name: "LoggerType",
        component: ListLoggerType,
        meta: `;${VIEW_PERMISSION};${STERILIZATION_AUDITS_LOGGERTYPE}`,
        children: [
          {
            path: "create",
            name: "CreateLoggerType",
            component: CreateLoggerType,
            meta: {
              level: 2
            }
          },
          {
            path: ":id",
            name: "EditLoggerType",
            component: EditLoggerType,
            meta: {
              level: 2
            }
          }
        ]
      },
      {
        path: "container-size",
        name: "ContainerSize",
        component: ListContainerSize,
        meta: `;${VIEW_PERMISSION};${STERILIZATION_AUDITS_CONTAINERSIZE}`,
        children: [
          {
            path: "create",
            name: "CreateContainerSize",
            component: CreateContainerSize,
            meta: {
              level: 2
            }
          },
          {
            path: ":id",
            name: "EditContainerSize",
            component: EditContainerSize,
            meta: {
              level: 2
            }
          }
        ]
      },
      {
        path: "steriliser",
        name: "Steriliser",
        component: ListSteriliser,
        meta: `;${VIEW_PERMISSION};${STERILIZATION_AUDITS_STERILISER}`,
        children: [
          {
            path: "create",
            name: "CreateSteriliser",
            component: CreateSteriliser,
            meta: {
              level: 2
            }
          },
          {
            path: ":id",
            name: "EditSteriliser",
            component: EditSteriliser,
            meta: {
              level: 2
            }
          }
        ]
      },
      {
        path: "audit-groups",
        component: AuditGroups,
        name: "AuditGroups",
        children: [
          {
            path: "",
            name: "ListAuditGroups",
            component: ListAuditGroups,
            meta: `;${VIEW_PERMISSION};${STERILIZATION_AUDITS_AUDITGROUP}`,
            children: [
              {
                path: "advanced-search",
                name: "FilterAuditGroups",
                component: FilterAuditGroups,
                meta: {
                  level: 2
                }
              }
            ]
          },
          {
            path: "create",
            name: "CreateAuditGroup",
            component: CreateAuditGroup,
            meta: {
              level: 2
            }
          },
          {
            path: "create-audit-record",
            name: "CreatAuditRecord",
            component: CreatAuditRecord,
            meta: {
              level: 2
            }
          },
          {
            path: "audit-record/:recordId",
            name: "EditAuditRecord",
            component: EditAuditRecord,
            meta: {
              level: 2
            },
            children: [
              {
                path: "create-measurement",
                name: "CreateMeasurement",
                component: CreateMeasurement,
                meta: {
                  level: 3
                }
              },
              {
                path: "measurement/:id",
                name: "EditMeasurement",
                component: EditMeasurement,
                meta: {
                  level: 3
                }
              }
            ]
          },
          {
            path: ":id",
            name: "EditAuditGroup",
            component: EditAuditGroup,
            meta: {
              level: 2
            },
            children: [
              {
                path: "create-link",
                name: "CreateLink",
                component: CreateLink,
                meta: {
                  level: 3
                }
              }
            ]
          },
          {
            path: ":id/item/:itemId",
            name: "ItemCodeDetail",
            component: ItemCodeDetail,
            meta: {
              level: 2
            },
            children: [
              {
                path: "create-prod-dev",
                name: "CreateProductDevelopment",
                component: CreateProductDevelopment,
                meta: {
                  level: 3
                }
              },
              {
                path: "prod-dev/:prodDevId",
                name: "EditProductDevelopment",
                component: EditProductDevelopment,
                meta: {
                  level: 3
                }
              }
            ]
          }
        ]
      },
      {
        path: "reports",
        name: "AuditReports",
        component: ReportsMenu
      }
    ]
  }
];
const install = (Vue, options = {}) => {
  const { router } = options;
  if (router) {
    router.addRoutes(routes);
  }
};
export { APPROVE_PERMISSION as A, install as i };
