import { VIEW_PERMISSION, WEB_ANALYTICS_CLUSTERGROUP, WEB_ANALYTICS_CLUSTERGROUPL1, WEB_ANALYTICS_CLUSTERGROUPL2, WEB_ANALYTICS_VERSION, IMPORT_PERMISSION, WEB_ANALYTICS_MANUALFORECAST, WEB_ANALYTICS_REPORT, WEB_ANALYTICS_OOS, WEB_ANALYTICS_ADDATE } from "@kraftheinz/common";
const WebAnalytics = () => import("./WebAnalytics.js");
const ListClusterOwner = () => import("./index.js");
const CreateClusterOwner = () => import("./index2.js");
const EditClusterOwner = () => import("./index3.js");
const ListClusterGroupMappingL1 = () => import("./index4.js");
const EditClusterGroupMappingL1 = () => import("./index5.js");
const ListClusterGroupMappingL2 = () => import("./index6.js");
const CreateGroupMappingL2 = () => import("./index7.js");
const MoveClusterMappingL2 = () => import("./index8.js");
const FilterStocks = () => import("./index9.js");
const FilterDeliveryDate = () => import("./index10.js");
const EditOOSMaintaince = () => import("./index11.js");
const ListMaintainVersions = () => import("./index12.js");
const EditMaintainVersions = () => import("./index13.js");
const CreateMaintainVersions = () => import("./index14.js");
const ListImportManualForecast = () => import("./index15.js");
const ListReports = () => import("./index16.js");
const MaintainVersionDetails = () => import("./index17.js");
const CreateMaintainVersionDetails = () => import("./index18.js");
const EditMaintainVersionDetails = () => import("./index19.js");
const ViewLoadHistory = () => import("./index20.js");
const AdditionalCharges = () => import("./index21.js");
const CreateAdditionalCharges = () => import("./index22.js");
const EditAdditionalCharges = () => import("./index23.js");
const ExchangeRates = () => import("./index24.js");
const CreateExchangeRates = () => import("./index25.js");
const EditExchangeRates = () => import("./index26.js");
const ExtraCosts = () => import("./index27.js");
const CreateExtraCosts = () => import("./index28.js");
const EditExtraCosts = () => import("./index29.js");
const Prices = () => import("./index30.js");
const CreatePrices = () => import("./index31.js");
const EditPrices = () => import("./index32.js");
const LoadPrices = () => import("./index33.js");
const Volume = () => import("./index34.js");
const CreateVolumeOverride = () => import("./index35.js");
const EditVolume = () => import("./index36.js");
const EditVolumeOverride = () => import("./index37.js");
const LoadVolume = () => import("./index38.js");
const TypeAItems = () => import("./index39.js");
const CreateTypeAItems = () => import("./index40.js");
const FixPo = () => import("./index41.js");
const CreateFixPo = () => import("./index42.js");
const EditFixPo = () => import("./index43.js");
const PayrollReport = () => import("./index44.js");
const ImportAuditData = () => import("./index45.js");
const Control = () => import("./index46.js");
const EditControl = () => import("./index47.js");
const Inquiry = () => import("./index48.js");
const Update = () => import("./index49.js");
const EditVersions = () => import("./index50.js");
const PpvReports = () => import("./index51.js");
const ProcurementSpecialists = () => import("./index52.js");
const CreateProcurementSpecialists = () => import("./index53.js");
const EditProcurementSpecialists = () => import("./index54.js");
const AssignCategories = () => import("./index55.js");
const CreateAssignCategories = () => import("./index56.js");
const EditAssignCategories = () => import("./index57.js");
const AssignGlobalPortfolio = () => import("./index58.js");
const CreateAssignGlobalPortfolio = () => import("./index59.js");
const EditAssignGlobalPortfolio = () => import("./index60.js");
const BudgetExchangeRates = () => import("./index61.js");
const CreateBudgetExchangeRates = () => import("./index62.js");
const CopyBudgetExchangeRates = () => import("./index63.js");
const EditBudgetExchangeRates = () => import("./index64.js");
const CostBuckets = () => import("./index65.js");
const EditCostBuckets = () => import("./index66.js");
const CreateCostBuckets = () => import("./index67.js");
const ExchangeRateOverrides = () => import("./index68.js");
const CreateExchangeRateOverrides = () => import("./index69.js");
const EditExchangeRateOverrides = () => import("./index70.js");
const ExtraItems = () => import("./index71.js");
const CreateExtraItems = () => import("./index72.js");
const EditExtraItems = () => import("./index73.js");
const GlobalPortfolios = () => import("./index74.js");
const CreateGlobalPortfolios = () => import("./index75.js");
const EditGlobalPortfolios = () => import("./index76.js");
const ItemTranslations = () => import("./index77.js");
const CreateItemTranslations = () => import("./index78.js");
const EditItemTranslations = () => import("./index79.js");
const MaintainPostings = () => import("./index80.js");
const ListMaintainPostings = () => import("./index81.js");
const CreateMaintainPostings = () => import("./index82.js");
const EditMaintainPostings = () => import("./index83.js");
const SplitLine = () => import("./index84.js");
const ProjectItemTranslations = () => import("./index85.js");
const CreateProjectItemTranslations = () => import("./index86.js");
const EditProjectItemTranslations = () => import("./index87.js");
const ProjectSubclassTranslations = () => import("./index88.js");
const CreateProjectSubclassTranslation = () => import("./index89.js");
const EditProjectSubclassTranslations = () => import("./index90.js");
const ProjectTypes = () => import("./index91.js");
const CreateProjectTypes = () => import("./index92.js");
const EditProjectTypes = () => import("./index93.js");
const SubclassTranslations = () => import("./index94.js");
const CreateSubclassTranslations = () => import("./index95.js");
const EditSubclassTranslations = () => import("./index96.js");
const RefreshCube = () => import("./index97.js");
const PpvActualReports = () => import("./index98.js");
const ReportsAdmin = () => import("./index99.js");
const CreateReportsAdmin = () => import("./index100.js");
const EditReportsAdmin = () => import("./index101.js");
const UploadAccessDatabase = () => import("./index102.js");
const OverlayScreen = () => import("./index103.js");
const FilterComment = () => import("./index104.js");
const EditCommentMaintenance = () => import("./index105.js");
const AddCommentMaintenance = () => import("./index106.js");
var routes = [
  {
    path: "/web-analytics",
    name: "WebAnalytics",
    component: WebAnalytics,
    redirect: { name: "OutOfStock" },
    children: [
      {
        path: "cluster-owner",
        name: "ClusterOwner",
        component: ListClusterOwner,
        meta: `profitability_cluster-mapping;${VIEW_PERMISSION};${WEB_ANALYTICS_CLUSTERGROUP}`,
        children: [
          {
            path: "create",
            name: "CreateClusterOwner",
            component: CreateClusterOwner
          },
          {
            path: ":id",
            name: "EditClusterOwner",
            component: EditClusterOwner
          }
        ]
      },
      {
        path: "cluster-l1-trans",
        name: "ClusterGroupMappingL1",
        component: ListClusterGroupMappingL1,
        meta: `profitability_cluster-mapping;${VIEW_PERMISSION};${WEB_ANALYTICS_CLUSTERGROUPL1}`,
        children: [
          {
            path: ":id",
            name: "EditClusterGroupMappingL1",
            component: EditClusterGroupMappingL1
          }
        ]
      },
      {
        path: "cluster-l2-trans",
        name: "ClusterGroupMappingL2",
        component: ListClusterGroupMappingL2,
        meta: `profitability_cluster-mapping;${VIEW_PERMISSION};${WEB_ANALYTICS_CLUSTERGROUPL2}`,
        children: [
          {
            path: "create",
            name: "CreateGroupMapping",
            component: CreateGroupMappingL2
          },
          {
            path: ":id",
            name: "MoveClusterMappingL2",
            component: MoveClusterMappingL2
          }
        ]
      },
      {
        path: "maintain-versions",
        name: "MaintainVersions",
        component: ListMaintainVersions,
        meta: `profitability;${VIEW_PERMISSION};${WEB_ANALYTICS_VERSION}`,
        children: [
          {
            path: "create",
            name: "CreateMaintainVersions",
            component: CreateMaintainVersions
          },
          {
            path: ":id",
            name: "EditMaintainVersions",
            component: EditMaintainVersions
          }
        ]
      },
      {
        path: "maintain-version-details",
        name: "MaintainVersionDetails",
        component: MaintainVersionDetails,
        meta: `profitability;`,
        children: [
          {
            path: "create",
            name: "CreateMaintainVersionDetails",
            component: CreateMaintainVersionDetails
          },
          {
            path: ":id",
            name: "EditMaintainVersionDetails",
            component: EditMaintainVersionDetails
          }
        ]
      },
      {
        path: "import-manual-forecast",
        name: "ImportManualForecast",
        component: ListImportManualForecast,
        meta: `profitability;${IMPORT_PERMISSION};${WEB_ANALYTICS_MANUALFORECAST}`
      },
      {
        path: "view-load-history",
        name: "ViewLoadHistory",
        component: ViewLoadHistory,
        meta: `profitability;`
      },
      {
        path: "reports",
        name: "Reports",
        component: ListReports,
        meta: `profitability;${VIEW_PERMISSION};${WEB_ANALYTICS_REPORT}`
      },
      {
        path: "oo-out-of-stocks-stagings",
        name: "OutOfStock",
        component: FilterStocks,
        meta: `casefillReporting;${VIEW_PERMISSION};${WEB_ANALYTICS_OOS}`,
        children: [
          {
            path: ":id",
            name: "EditOOSMaintaince",
            component: EditOOSMaintaince,
            props: (route) => ({
              entityId: +route.params.id,
              country: route.query.country
            })
          }
        ]
      },
      {
        path: "actual-delivery-date",
        name: "ActualDeliveryDate",
        component: FilterDeliveryDate,
        meta: `casefillReporting;${VIEW_PERMISSION};${WEB_ANALYTICS_ADDATE}`,
        children: []
      },
      {
        path: "additional-charges",
        name: "AdditionalCharges",
        component: AdditionalCharges,
        meta: `ppv-forecast`,
        children: [
          {
            path: "create",
            name: "CreateAdditionalCharges",
            component: CreateAdditionalCharges
          },
          {
            path: ":id",
            name: "EditAdditionalCharges",
            component: EditAdditionalCharges
          }
        ]
      },
      {
        path: "extra-costs",
        name: "ExtraCosts",
        component: ExtraCosts,
        meta: `ppv-forecast`,
        children: [
          {
            path: "create",
            name: "CreateExtraCosts",
            component: CreateExtraCosts
          },
          {
            path: ":id",
            name: "EditExtraCosts",
            component: EditExtraCosts
          }
        ]
      },
      {
        path: "exchange-rates",
        name: "ExchangeRates",
        component: ExchangeRates,
        meta: `ppv-forecast`,
        children: [
          {
            path: "create",
            name: "CreateExchangeRates",
            component: CreateExchangeRates
          },
          {
            path: ":id",
            name: "EditExchangeRates",
            component: EditExchangeRates
          }
        ]
      },
      {
        path: "prices",
        name: "Prices",
        component: Prices,
        meta: `ppv-forecast`,
        children: [
          {
            path: "create",
            name: "CreatePrices",
            component: CreatePrices
          },
          {
            path: ":id",
            name: "EditPrices",
            component: EditPrices
          }
        ]
      },
      {
        path: "load-prices",
        name: "LoadPrices",
        component: LoadPrices,
        meta: `ppv-forecast`,
        children: []
      },
      {
        path: "volume",
        name: "Volume",
        component: Volume,
        meta: `ppv-forecast`,
        children: [
          {
            path: ":id",
            name: "EditVolume",
            component: EditVolume
          },
          {
            path: "volume-override/create",
            name: "CreateVolumeOverride",
            component: CreateVolumeOverride
          },
          {
            path: "volume-override/:id",
            name: "EditVolumeOverride",
            component: EditVolumeOverride
          }
        ]
      },
      {
        path: "load-volume",
        name: "LoadVolume",
        component: LoadVolume,
        meta: `ppv-forecast`,
        children: []
      },
      {
        path: "include-type-a",
        name: "TypeAItems",
        component: TypeAItems,
        meta: `ppv-forecast`,
        children: [
          {
            path: "create",
            name: "CreateTypeAItems",
            component: CreateTypeAItems
          }
        ]
      },
      {
        path: "control",
        name: "Control",
        component: Control,
        meta: `ppv-forecast`,
        children: [
          {
            path: ":id",
            name: "EditControl",
            component: EditControl
          }
        ]
      },
      {
        path: "inquiry",
        name: "Inquiry",
        component: Inquiry,
        meta: `ppv-forecast`,
        children: []
      },
      {
        path: "ppv-reports",
        name: "PpvReports",
        component: PpvReports,
        meta: `ppv-forecast`,
        children: []
      },
      {
        path: "fix-po",
        name: "FixPo",
        component: FixPo,
        meta: `ppv-forecast`,
        children: [
          {
            path: "create",
            name: "CreateFixPo",
            component: CreateFixPo
          },
          {
            path: ":id",
            name: "EditFixPo",
            component: EditFixPo
          }
        ]
      },
      {
        path: "update",
        name: "Update",
        component: Update,
        meta: `ppv-forecast`,
        children: [
          {
            path: ":id",
            name: "EditVersions",
            component: EditVersions
          }
        ]
      },
      {
        path: "payroll-report",
        name: "PayrollReport",
        component: PayrollReport,
        meta: `PayrollReport`,
        children: []
      },
      {
        path: "import-audit-data",
        name: "ImportAuditData",
        component: ImportAuditData,
        meta: `ImportAuditData`,
        children: []
      },
      {
        path: "procurement-specialists",
        name: "ProcurementSpecialists",
        component: ProcurementSpecialists,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateProcurementSpecialists",
            component: CreateProcurementSpecialists
          },
          {
            path: ":id",
            name: "EditProcurementSpecialists",
            component: EditProcurementSpecialists
          }
        ]
      },
      {
        path: "assign-categories",
        name: "AssignCategories",
        component: AssignCategories,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateAssignCategories",
            component: CreateAssignCategories
          },
          {
            path: ":id",
            name: "EditAssignCategories",
            component: EditAssignCategories
          }
        ]
      },
      {
        path: "assign-global-portfolio",
        name: "AssignGlobalPortfolio",
        component: AssignGlobalPortfolio,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateAssignGlobalPortfolio",
            component: CreateAssignGlobalPortfolio
          },
          {
            path: ":id",
            name: "CreateAssignGlobalPortfolio",
            component: EditAssignGlobalPortfolio
          }
        ]
      },
      {
        path: "budget-exchange-rates",
        name: "BudgetExchangeRates",
        component: BudgetExchangeRates,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateBudgetExchangeRates",
            component: CreateBudgetExchangeRates
          },
          {
            path: "copy",
            name: "CopyBudgetExchangeRates",
            component: CopyBudgetExchangeRates
          },
          {
            path: ":id",
            name: "EditBudgetExchangeRates",
            component: EditBudgetExchangeRates
          }
        ]
      },
      {
        path: "cost-buckets",
        name: "CostBuckets",
        component: CostBuckets,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateCostBuckets",
            component: CreateCostBuckets
          },
          {
            path: ":id",
            name: "EditCostBuckets",
            component: EditCostBuckets
          }
        ]
      },
      {
        path: "exchange-rate-overrides",
        name: "ExchangeRateOverrides",
        component: ExchangeRateOverrides,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateExchangeRateOverrides",
            component: CreateExchangeRateOverrides
          },
          {
            path: ":id",
            name: "EditExchangeRateOverrides",
            component: EditExchangeRateOverrides
          }
        ]
      },
      {
        path: "extra-items",
        name: "ExtraItems",
        component: ExtraItems,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateExtraItems",
            component: CreateExtraItems
          },
          {
            path: ":id",
            name: "EditExtraItems",
            component: EditExtraItems
          }
        ]
      },
      {
        path: "global-portfolios",
        name: "GlobalPortfolios",
        component: GlobalPortfolios,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateGlobalPortfolios",
            component: CreateGlobalPortfolios
          },
          {
            path: ":id",
            name: "EditGlobalPortfolios",
            component: EditGlobalPortfolios
          }
        ]
      },
      {
        path: "item-translations",
        name: "ItemTranslations",
        component: ItemTranslations,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateItemTranslations",
            component: CreateItemTranslations
          },
          {
            path: ":id",
            name: "EditItemTranslations",
            component: EditItemTranslations
          }
        ]
      },
      {
        path: "item-translations",
        name: "ItemTranslations",
        component: ItemTranslations,
        meta: `ppv-actuals`,
        children: []
      },
      {
        path: "project-item-translations",
        name: "ProjectItemTranslations",
        component: ProjectItemTranslations,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateProjectItemTranslations",
            component: CreateProjectItemTranslations
          },
          {
            path: ":id",
            name: "EditProjectItemTranslations",
            component: EditProjectItemTranslations
          }
        ]
      },
      {
        path: "project-subclass-translations",
        name: "ProjectSubclassTranslations",
        component: ProjectSubclassTranslations,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateProjectSubclassTranslation",
            component: CreateProjectSubclassTranslation
          },
          {
            path: ":id",
            name: "EditProjectSubclassTranslations",
            component: EditProjectSubclassTranslations
          }
        ]
      },
      {
        path: "project-types",
        name: "ProjectTypes",
        component: ProjectTypes,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateProjectTypes",
            component: CreateProjectTypes
          },
          {
            path: ":id",
            name: "EditProjectTypes",
            component: EditProjectTypes
          }
        ]
      },
      {
        path: "subclass-translations",
        name: "SubclassTranslations",
        component: SubclassTranslations,
        meta: `ppv-actuals`,
        children: [
          {
            path: "create",
            name: "CreateSubclassTranslations",
            component: CreateSubclassTranslations
          },
          {
            path: ":id",
            name: "EditSubclassTranslations",
            component: EditSubclassTranslations
          }
        ]
      },
      {
        path: "maintain-postings",
        name: "MaintainPostings",
        component: MaintainPostings,
        meta: `ppv-actuals`,
        children: [
          {
            path: ":id/split",
            name: "SplitLine",
            component: SplitLine
          },
          {
            path: "",
            name: "ListMaintainPostings",
            component: ListMaintainPostings,
            children: [
              {
                path: "create",
                name: "CreateMaintainPostings",
                component: CreateMaintainPostings
              },
              {
                path: ":id",
                name: "EditMaintainPostings",
                component: EditMaintainPostings
              }
            ]
          }
        ]
      },
      {
        path: "refresh-cube",
        name: "RefreshCube",
        component: RefreshCube,
        meta: `ppv-actuals`,
        children: []
      },
      {
        path: "ppv-actual-report",
        name: "PpvActualReports",
        component: PpvActualReports,
        meta: `ppv-actuals`,
        children: []
      },
      {
        path: "reports-admin",
        name: "ReportsAdmin",
        component: ReportsAdmin,
        meta: `reports-admin`,
        children: [
          {
            path: "create",
            name: "CreateReportsAdmin",
            component: CreateReportsAdmin
          },
          {
            path: ":id",
            name: "EditReportsAdmin",
            component: EditReportsAdmin
          }
        ]
      },
      {
        path: "upload-access-database",
        name: "UploadAccessDatabase",
        component: UploadAccessDatabase
      },
      {
        path: "overlays",
        name: "OverlayScreen",
        component: OverlayScreen
      },
      {
        path: "cut-reason-comment",
        name: "CutCommentMaintaince",
        component: FilterComment,
        meta: `casefillReporting;${VIEW_PERMISSION};${WEB_ANALYTICS_OOS}`,
        children: [
          {
            path: "create",
            name: "AddCommentMaintenance",
            component: AddCommentMaintenance
          },
          {
            path: ":id",
            name: "EditCommentMaintenance",
            component: EditCommentMaintenance,
            props: (route) => ({
              itemId: +route.params.id,
              country: route.query.country
            })
          }
        ]
      }
    ]
  }
];
const install = (Vue, options = {}) => {
  const { router } = options;
  if (router) {
    router.addRoutes(routes);
  }
};
export { install as default };
