import { VIEW_PERMISSION, PPV_ACTUALS_PROCUREMENT_SPECIALISTS, PPV_ACTUALS_ASSIGN_CATEGORIES, PPV_ACTUALS_ASSIGN_GLOBAL_PORTFOLIO, PPV_ACTUALS_BUDGET_EXCHANGE_RATES, PPV_ACTUALS_COST_BUCKETS, PPV_ACTUALS_EXCHANGE_RATE_OVERRIDES, PPV_ACTUALS_EXTRA_ITEMS, PPV_ACTUALS_GLOBAL_PORTFOLIOS, PPV_ACTUALS_ITEM_TRANSLATIONS, PPV_ACTUALS_PROJECT_ITEM_TRANSLATIONS, PPV_ACTUALS_PROJECT_SUBCLASS_TRANSLATIONS, PPV_ACTUALS_PROJECT_TYPE, PPV_ACTUALS_SUBCLASS_TRANSLATIONS, PPV_ACTUALS_MAINTAIN_POSTINGS, REFRESH_PERMISSION, PPV_ACTUALS_REFRESH_CUBE, PPV_ACTUALS_REPORTS } from "@kraftheinz/common";
const PPVActuals = () => import("./PPVActuals.js");
const ProcurementSpecialists = () => import("./index.js");
const CreateProcurementSpecialists = () => import("./index2.js");
const EditProcurementSpecialists = () => import("./index3.js");
const AssignCategories = () => import("./index4.js");
const CreateAssignCategories = () => import("./index5.js");
const EditAssignCategories = () => import("./index6.js");
const AssignGlobalPortfolio = () => import("./index7.js");
const CreateAssignGlobalPortfolio = () => import("./index8.js");
const EditAssignGlobalPortfolio = () => import("./index9.js");
const BudgetExchangeRates = () => import("./index10.js");
const CreateBudgetExchangeRates = () => import("./index11.js");
const CopyBudgetExchangeRates = () => import("./index12.js");
const EditBudgetExchangeRates = () => import("./index13.js");
const CostBuckets = () => import("./index14.js");
const EditCostBuckets = () => import("./index15.js");
const CreateCostBuckets = () => import("./index16.js");
const ExchangeRateOverrides = () => import("./index17.js");
const CreateExchangeRateOverrides = () => import("./index18.js");
const EditExchangeRateOverrides = () => import("./index19.js");
const ExtraItems = () => import("./index20.js");
const CreateExtraItems = () => import("./index21.js");
const EditExtraItems = () => import("./index22.js");
const GlobalPortfolios = () => import("./index23.js");
const CreateGlobalPortfolios = () => import("./index24.js");
const EditGlobalPortfolios = () => import("./index25.js");
const ItemTranslations = () => import("./index26.js");
const CreateItemTranslations = () => import("./index27.js");
const EditItemTranslations = () => import("./index28.js");
const MaintainPostings = () => import("./index29.js");
const ListMaintainPostings = () => import("./index30.js");
const CreateMaintainPostings = () => import("./index31.js");
const EditMaintainPostings = () => import("./index32.js");
const SplitLine = () => import("./index33.js");
const ProjectItemTranslations = () => import("./index34.js");
const CreateProjectItemTranslations = () => import("./index35.js");
const EditProjectItemTranslations = () => import("./index36.js");
const ProjectSubclassTranslations = () => import("./index37.js");
const CreateProjectSubclassTranslation = () => import("./index38.js");
const EditProjectSubclassTranslations = () => import("./index39.js");
const ProjectTypes = () => import("./index40.js");
const CreateProjectTypes = () => import("./index41.js");
const EditProjectTypes = () => import("./index42.js");
const SubclassTranslations = () => import("./index43.js");
const CreateSubclassTranslations = () => import("./index44.js");
const EditSubclassTranslations = () => import("./index45.js");
const RefreshCube = () => import("./index46.js");
const PpvActualReports = () => import("./index47.js");
var routes = [
  {
    path: "/ppv-actuals",
    name: "PPVActuals",
    component: PPVActuals,
    redirect: { name: "PPVProcurementSpecialists" },
    children: [
      {
        path: "procurement-specialists",
        name: "PPVProcurementSpecialists",
        component: ProcurementSpecialists,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_PROCUREMENT_SPECIALISTS};`,
        children: [
          {
            path: "create",
            name: "PPVCreateProcurementSpecialists",
            component: CreateProcurementSpecialists
          },
          {
            path: ":id",
            name: "PPVEditProcurementSpecialists",
            component: EditProcurementSpecialists
          }
        ]
      },
      {
        path: "assign-categories",
        name: "PPVAssignCategories",
        component: AssignCategories,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_ASSIGN_CATEGORIES};`,
        children: [
          {
            path: "create",
            name: "PPVCreateAssignCategories",
            component: CreateAssignCategories
          },
          {
            path: ":id",
            name: "PPVEditAssignCategories",
            component: EditAssignCategories
          }
        ]
      },
      {
        path: "assign-global-portfolio",
        name: "PPVAssignGlobalPortfolio",
        component: AssignGlobalPortfolio,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_ASSIGN_GLOBAL_PORTFOLIO};`,
        children: [
          {
            path: "create",
            name: "PPVCreateAssignGlobalPortfolio",
            component: CreateAssignGlobalPortfolio
          },
          {
            path: ":id",
            name: "PPVCreateAssignGlobalPortfolio",
            component: EditAssignGlobalPortfolio
          }
        ]
      },
      {
        path: "budget-exchange-rates",
        name: "PPVBudgetExchangeRates",
        component: BudgetExchangeRates,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_BUDGET_EXCHANGE_RATES};`,
        children: [
          {
            path: "create",
            name: "PPVCreateBudgetExchangeRates",
            component: CreateBudgetExchangeRates
          },
          {
            path: "copy",
            name: "PPVCopyBudgetExchangeRates",
            component: CopyBudgetExchangeRates
          },
          {
            path: ":id",
            name: "PPVEditBudgetExchangeRates",
            component: EditBudgetExchangeRates
          }
        ]
      },
      {
        path: "cost-buckets",
        name: "PPVCostBuckets",
        component: CostBuckets,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_COST_BUCKETS};`,
        children: [
          {
            path: "create",
            name: "PPVCreateCostBuckets",
            component: CreateCostBuckets
          },
          {
            path: ":id",
            name: "PPVEditCostBuckets",
            component: EditCostBuckets
          }
        ]
      },
      {
        path: "exchange-rate-overrides",
        name: "PPVExchangeRateOverrides",
        component: ExchangeRateOverrides,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_EXCHANGE_RATE_OVERRIDES};`,
        children: [
          {
            path: "create",
            name: "PPVCreateExchangeRateOverrides",
            component: CreateExchangeRateOverrides
          },
          {
            path: ":id",
            name: "PPVEditExchangeRateOverrides",
            component: EditExchangeRateOverrides
          }
        ]
      },
      {
        path: "extra-items",
        name: "PPVExtraItems",
        component: ExtraItems,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_EXTRA_ITEMS};`,
        children: [
          {
            path: "create",
            name: "PPVCreateExtraItems",
            component: CreateExtraItems
          },
          {
            path: ":id",
            name: "PPVEditExtraItems",
            component: EditExtraItems
          }
        ]
      },
      {
        path: "global-portfolios",
        name: "PPVGlobalPortfolios",
        component: GlobalPortfolios,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_GLOBAL_PORTFOLIOS};`,
        children: [
          {
            path: "create",
            name: "PPVCreateGlobalPortfolios",
            component: CreateGlobalPortfolios
          },
          {
            path: ":id",
            name: "PPVEditGlobalPortfolios",
            component: EditGlobalPortfolios
          }
        ]
      },
      {
        path: "item-translations",
        name: "PPVItemTranslations",
        component: ItemTranslations,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_ITEM_TRANSLATIONS};`,
        children: [
          {
            path: "create",
            name: "PPVCreateItemTranslations",
            component: CreateItemTranslations
          },
          {
            path: ":id",
            name: "PPVEditItemTranslations",
            component: EditItemTranslations
          }
        ]
      },
      {
        path: "project-item-translations",
        name: "PPVProjectItemTranslations",
        component: ProjectItemTranslations,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_PROJECT_ITEM_TRANSLATIONS};`,
        children: [
          {
            path: "create",
            name: "PPVCreateProjectItemTranslations",
            component: CreateProjectItemTranslations
          },
          {
            path: ":id",
            name: "PPVEditProjectItemTranslations",
            component: EditProjectItemTranslations
          }
        ]
      },
      {
        path: "project-subclass-translations",
        name: "PPVProjectSubclassTranslations",
        component: ProjectSubclassTranslations,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_PROJECT_SUBCLASS_TRANSLATIONS};`,
        children: [
          {
            path: "create",
            name: "PPVCreateProjectSubclassTranslation",
            component: CreateProjectSubclassTranslation
          },
          {
            path: ":id",
            name: "PPVEditProjectSubclassTranslations",
            component: EditProjectSubclassTranslations
          }
        ]
      },
      {
        path: "project-types",
        name: "PPVProjectTypes",
        component: ProjectTypes,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_PROJECT_TYPE};`,
        children: [
          {
            path: "create",
            name: "PPVCreateProjectTypes",
            component: CreateProjectTypes
          },
          {
            path: ":id",
            name: "PPVEditProjectTypes",
            component: EditProjectTypes
          }
        ]
      },
      {
        path: "subclass-translations",
        name: "PPVSubclassTranslations",
        component: SubclassTranslations,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_SUBCLASS_TRANSLATIONS};`,
        children: [
          {
            path: "create",
            name: "PPVCreateSubclassTranslations",
            component: CreateSubclassTranslations
          },
          {
            path: ":id",
            name: "PPVEditSubclassTranslations",
            component: EditSubclassTranslations
          }
        ]
      },
      {
        path: "maintain-postings",
        name: "PPVMaintainPostings",
        component: MaintainPostings,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_MAINTAIN_POSTINGS};`,
        children: [
          {
            path: ":id/split",
            name: "PPVSplitLine",
            component: SplitLine
          },
          {
            path: "",
            name: "PPVListMaintainPostings",
            component: ListMaintainPostings,
            children: [
              {
                path: "create",
                name: "PPVCreateMaintainPostings",
                component: CreateMaintainPostings
              },
              {
                path: ":id",
                name: "PPVEditMaintainPostings",
                component: EditMaintainPostings
              }
            ]
          }
        ]
      },
      {
        path: "refresh-cube",
        name: "PPVRefreshCube",
        component: RefreshCube,
        meta: `${REFRESH_PERMISSION};${PPV_ACTUALS_REFRESH_CUBE};`,
        children: []
      },
      {
        path: "ppv-actual-report",
        name: "PPVPpvActualReports",
        component: PpvActualReports,
        meta: `${VIEW_PERMISSION};${PPV_ACTUALS_REPORTS};`,
        children: []
      }
    ]
  }
];
const install = (Vue, options = {}) => {
  const { router } = options;
  if (router) {
    router.addRoutes(routes);
  }
};
export { install as default };
