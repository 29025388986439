import { VIEW_PERMISSION, LINE_PERFORMANCE_DASHBOARD, LINE_PERFORMANCE_SHOP_ORDER, LINE_PERFORMANCE_DOWNTIME_CATEGORIES, LINE_PERFORMANCE_TARGETS, LINE_PERFORMANCE_PRODUCT_STANDARDS, LINE_PERFORMANCE_SHIFT_TIMES, LINE_PERFORMANCE_QUALITY_CATEGORIES, LINE_PERFORMANCE_REPORT, LINE_PERFORMANCE_UNSCHEDULED_TIME, LINE_PERFORMANCE_PRODUCTION_LINES } from "@kraftheinz/common";
const LinePerformance = () => import("./LinePerformance.js");
const ListTargets = () => import("./index.js");
const EditTargets = () => import("./index2.js");
const CreateTargets = () => import("./index3.js");
const Dashboard = () => import("./index4.js");
const ListProductStandards = () => import("./index5.js");
const CreateProductStandard = () => import("./index7.js");
const EditProductStandard = () => import("./index8.js");
const ImportStandardSpeed = () => import("./ImportStandardSpeed.js");
const ListShiftTimes = () => import("./index9.js");
const CreateShiftTime = () => import("./index10.js");
const EditShiftTime = () => import("./index11.js");
const ListQualityReasons = () => import("./index12.js");
const CreateQualityReason = () => import("./index13.js");
const EditQualityReason = () => import("./index14.js");
const ListDowntimeCategories = () => import("./index15.js");
const ShopOrders = () => import("./index16.js");
const ListShopOrders = () => import("./index17.js");
const EditShopOrders = () => import("./index18.js");
const CreateShopOrder = () => import("./index19.js");
const ShiftKPI = () => import("./index20.js");
const ListShiftKpi = () => import("./index21.js");
const CreateShiftKpi = () => import("./index22.js");
const EditShiftKpi = () => import("./index23.js");
const UnscheduledDowntime = () => import("./index24.js");
const CreateUnscheduledDowntime = () => import("./index25.js");
const EditUnscheduledDowntime = () => import("./index26.js");
const ProductionLine = () => import("./index27.js");
const CreateProductionLine = () => import("./index28.js");
const EditProductionLine = () => import("./index29.js");
const PlannedOrders = () => import("./index30.js");
const CtpShopOrders = () => import("./index31.js");
const Weekly = () => import("./index32.js");
const ReasonCode = () => import("./index33.js");
const CreateReasonCode = () => import("./index34.js");
const EditReasonCode = () => import("./index35.js");
const PtpTolerance = () => import("./index36.js");
const FinancialCalendar = () => import("./index37.js");
const CreateFinancialYear = () => import("./index38.js");
const EditFinancialYear = () => import("./index39.js");
const ExportDowntimeCategory = () => import("./index40.js");
var routes = [
  {
    path: "/line-performance",
    name: "LinePerformance",
    component: LinePerformance,
    redirect: { name: "Dashboard" },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: `dashboard;${VIEW_PERMISSION};${LINE_PERFORMANCE_DASHBOARD}`
      },
      {
        path: "shop-orders",
        name: "ShopOrders",
        component: ShopOrders,
        meta: `shop-orders;${VIEW_PERMISSION};${LINE_PERFORMANCE_SHOP_ORDER}`,
        children: [
          {
            path: "",
            name: "ListShopOrders",
            component: ListShopOrders,
            children: [
              {
                path: "create",
                name: "CreateShopOrder",
                component: CreateShopOrder
              }
            ]
          },
          {
            path: ":id",
            name: "EditShopOrders",
            component: EditShopOrders,
            children: []
          }
        ]
      },
      {
        path: "downtime-categories",
        name: "ListDowntimeCategories",
        component: ListDowntimeCategories,
        meta: `masterfiles;${VIEW_PERMISSION};${LINE_PERFORMANCE_DOWNTIME_CATEGORIES}`
      },
      {
        path: "targets",
        name: "ListTargets",
        component: ListTargets,
        meta: `masterfiles;${VIEW_PERMISSION};${LINE_PERFORMANCE_TARGETS}`,
        children: [
          {
            path: "create",
            name: "CreateTargets",
            component: CreateTargets
          },
          {
            path: ":id",
            name: "EditTargets",
            component: EditTargets
          }
        ]
      },
      {
        path: "product-standards",
        name: "ListProductStandards",
        component: ListProductStandards,
        meta: `masterfiles;${VIEW_PERMISSION};${LINE_PERFORMANCE_PRODUCT_STANDARDS}`,
        children: [
          {
            path: "create",
            name: "CreateProductStandard",
            component: CreateProductStandard
          },
          {
            path: ":id",
            name: "EditProductStandard",
            component: EditProductStandard
          },
          {
            path: "import",
            name: "ImportStandardSpeed",
            component: ImportStandardSpeed
          }
        ]
      },
      {
        path: "shifts",
        name: "ListShiftTimes",
        component: ListShiftTimes,
        meta: `masterfiles;${VIEW_PERMISSION};${LINE_PERFORMANCE_SHIFT_TIMES}`,
        children: [
          {
            path: "create",
            name: "CreateShiftTime",
            component: CreateShiftTime
          },
          {
            path: ":id",
            name: "EditShiftTime",
            component: EditShiftTime
          }
        ]
      },
      {
        path: "quality",
        name: "ListQualityReasons",
        component: ListQualityReasons,
        meta: `masterfiles;${VIEW_PERMISSION};${LINE_PERFORMANCE_QUALITY_CATEGORIES}`,
        children: [
          {
            path: "create",
            name: "CreateQualityReason",
            component: CreateQualityReason
          },
          {
            path: ":id",
            name: "EditQualityReason",
            component: EditQualityReason
          }
        ]
      },
      {
        path: "shift-kpi",
        name: "ShiftKPI",
        component: ShiftKPI,
        meta: `${VIEW_PERMISSION};${LINE_PERFORMANCE_REPORT}`,
        children: [
          {
            path: "",
            name: "ListShiftKPI",
            component: ListShiftKpi
          },
          {
            path: "create",
            name: "CreateShiftKPI",
            component: CreateShiftKpi
          },
          {
            path: ":id",
            name: "EditShiftKPI",
            component: EditShiftKpi
          }
        ]
      },
      {
        path: "unscheduled-time",
        name: "UnscheduledDowntime",
        component: UnscheduledDowntime,
        meta: `unscheduled-time;${VIEW_PERMISSION};${LINE_PERFORMANCE_UNSCHEDULED_TIME}`,
        children: [
          {
            path: "create",
            name: "CreateUnscheduledDowntime",
            component: CreateUnscheduledDowntime
          },
          {
            path: ":id",
            name: "EditUnscheduledDowntime",
            component: EditUnscheduledDowntime
          }
        ]
      },
      {
        path: "production-line",
        name: "ProductionLine",
        component: ProductionLine,
        meta: `masterfiles;${VIEW_PERMISSION};${LINE_PERFORMANCE_PRODUCTION_LINES}`,
        children: [
          {
            path: "create",
            name: "CreateProductionLine",
            component: CreateProductionLine
          },
          {
            path: ":id",
            name: "EditProductionLine",
            component: EditProductionLine
          }
        ]
      },
      {
        path: "planned-orders",
        name: "PlannedOrders",
        component: PlannedOrders,
        meta: `ctp_central-database;`
      },
      {
        path: "ctp-shop-orders",
        name: "CtpShopOrders",
        component: CtpShopOrders,
        meta: `ctp_central-database;`
      },
      {
        path: "weekly",
        name: "Weekly",
        component: Weekly,
        meta: `ctp_central-database;`
      },
      {
        path: "reason-code",
        name: "ReasonCode",
        component: ReasonCode,
        meta: `ctp_ctp-master-files`,
        children: [
          {
            path: "create",
            name: "CreateReasonCode",
            component: CreateReasonCode
          },
          {
            path: ":id",
            name: "EditReasonCode",
            component: EditReasonCode
          }
        ]
      },
      {
        path: "ptp-tolerance",
        name: "PtpTolerance",
        component: PtpTolerance,
        meta: `ctp_ptp-tolerance`
      },
      {
        path: "financial-calendar",
        name: "FinancialCalendar",
        component: FinancialCalendar,
        meta: `ctp_financial-calendar`,
        children: [
          {
            path: "create",
            name: "CreateFinancialYear",
            component: CreateFinancialYear
          },
          {
            path: ":id",
            name: "EditFinancialYear",
            component: EditFinancialYear
          }
        ]
      },
      {
        path: "export-downtime-category",
        name: "ExportDowntimeCategory",
        component: ExportDowntimeCategory,
        meta: ``
      }
    ]
  }
];
const install = (Vue, options = {}) => {
  const { router } = options;
  if (router) {
    router.addRoutes(routes);
  }
};
export { install as default };
