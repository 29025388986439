const FixedAssetScanning = () => import("./FixedAssetScanning.js");
const FixedAssetRegister = () => import("./index2.js");
const CreateFixedAssetRegister = () => import("./index3.js");
const EditFixedAssetRegister = () => import("./index4.js");
const CreateFinancialYear = () => import("./index5.js");
const AssetList = () => import("./index6.js");
const CreateAsset = () => import("./index7.js");
const EditAsset = () => import("./index8.js");
const UserManagement = () => import("./index9.js");
const AssignFacility = () => import("./index10.js");
const MasterFile = () => import("./index11.js");
const Sitemap = () => import("./index12.js");
var routes = [
  {
    path: "/fixed-asset-scanning",
    name: "FixedAssetScanning",
    component: FixedAssetScanning,
    redirect: { name: "FixedAssetRegister" },
    children: [
      {
        path: "/fixed-asset-register",
        name: "FixedAssetRegister",
        component: FixedAssetRegister,
        meta: `reports`,
        children: [
          {
            path: "create",
            name: "CreateFixedAssetRegister",
            component: CreateFixedAssetRegister
          },
          {
            path: ":id",
            name: "EditFixedAssetRegister",
            component: EditFixedAssetRegister
          },
          {
            path: "financial-year/create",
            name: "CreateFinancialYear",
            component: CreateFinancialYear
          }
        ]
      },
      {
        path: "/assets",
        name: "AssetList",
        component: AssetList,
        meta: `reports`,
        children: [
          {
            path: "create",
            name: "CreateAsset",
            component: CreateAsset
          }
        ]
      },
      {
        path: "/assets/:id",
        name: "EditAsset",
        meta: `reports`,
        component: EditAsset
      },
      {
        path: "/sitemap",
        name: "Sitemap",
        meta: `reports`,
        component: Sitemap
      },
      {
        path: "/assign-facility",
        name: "UserManagement",
        component: UserManagement,
        meta: `configuration`,
        children: [
          {
            path: ":id",
            name: "AssignFacility",
            component: AssignFacility
          }
        ]
      },
      {
        path: "/fas-master-file",
        name: "MasterFile",
        meta: `configuration`,
        component: MasterFile
      }
    ]
  }
];
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "fas__header d-flex align-items-center justify-content-between px-5 pt-2 pb-3"
  }, [_c("div", {
    staticClass: "d-flex align-items-center"
  }, [_vm.redirectRoute ? _c("a-icon", {
    staticClass: "fas__header__title mr-2",
    attrs: {
      "type": "arrow-left"
    },
    on: {
      "click": _vm.onBack
    }
  }) : _vm._e(), _vm._t("customTitle", [_c("h2", {
    staticClass: "m-0"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])])], 2), _c("div", [_vm._t("customButton", [_c("a-button", {
    attrs: {
      "type": "primary",
      "disabled": _vm.disableCreateButton
    },
    on: {
      "click": _vm.onCreateClick
    }
  }, [_vm._v(" " + _vm._s(_vm.createButtonText ? _vm.createButtonText : "Add New") + " ")])])], 2)]);
};
var staticRenderFns$1 = [];
var FasHeader_vue_vue_type_style_index_0_scoped_true_lang = "";
function normalizeComponent(scriptExports, render2, staticRenderFns2, functionalTemplate, injectStyles, scopeId, moduleIdentifier, shadowMode) {
  var options = typeof scriptExports === "function" ? scriptExports.options : scriptExports;
  if (render2) {
    options.render = render2;
    options.staticRenderFns = staticRenderFns2;
    options._compiled = true;
  }
  if (functionalTemplate) {
    options.functional = true;
  }
  if (scopeId) {
    options._scopeId = "data-v-" + scopeId;
  }
  var hook;
  if (moduleIdentifier) {
    hook = function(context) {
      context = context || this.$vnode && this.$vnode.ssrContext || this.parent && this.parent.$vnode && this.parent.$vnode.ssrContext;
      if (!context && typeof __VUE_SSR_CONTEXT__ !== "undefined") {
        context = __VUE_SSR_CONTEXT__;
      }
      if (injectStyles) {
        injectStyles.call(this, context);
      }
      if (context && context._registeredComponents) {
        context._registeredComponents.add(moduleIdentifier);
      }
    };
    options._ssrRegister = hook;
  } else if (injectStyles) {
    hook = shadowMode ? function() {
      injectStyles.call(this, (options.functional ? this.parent : this).$root.$options.shadowRoot);
    } : injectStyles;
  }
  if (hook) {
    if (options.functional) {
      options._injectStyles = hook;
      var originalRender = options.render;
      options.render = function renderWithStyleInjection(h, context) {
        hook.call(context);
        return originalRender(h, context);
      };
    } else {
      var existing = options.beforeCreate;
      options.beforeCreate = existing ? [].concat(existing, hook) : [hook];
    }
  }
  return {
    exports: scriptExports,
    options
  };
}
const __vue2_script$1 = {
  name: "FasHeader",
  props: {
    title: {
      type: String,
      default: ""
    },
    createButtonText: {
      type: String,
      default: ""
    },
    createRoute: {
      type: String,
      default: ""
    },
    customBack: {
      type: Function,
      default: void 0
    },
    disableCreateButton: {
      type: Boolean,
      default: false
    },
    redirectRoute: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  methods: {
    onCreateClick() {
      this.$router.push(this.createRoute);
    },
    onBack() {
      if (this.customBack)
        this.customBack();
      else
        this.$router.push(this.redirectRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "7eabc5b4", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FasHeader = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
const f = "DD/MM/YYYY LT";
var MyMixin = {
  methods: {
    hello() {
      return "hello from mixin!";
    },
    pushToRouteWithQuery(newRoute, query = {}) {
      const { id, status, fullName } = this.$route.query;
      this.$router.push({
        path: newRoute,
        query: {
          ...query,
          id,
          status,
          fullName
        }
      });
    },
    stringToCsvFile(fileName, data, isExcel = false, type = "") {
      let hiddenElement = document.createElement("a");
      if (isExcel) {
        let blob = new Blob([data], {
          type
        });
        hiddenElement.href = URL.createObjectURL(blob);
      } else {
        hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(data);
      }
      hiddenElement.target = "_blank";
      hiddenElement.download = fileName;
      hiddenElement.click();
    },
    dateBefore(dateA, dateB) {
      const a = this.$moment(dateA).format(f);
      const b = this.$moment(dateB).format(f);
      return this.$moment(a).isBefore(b);
    },
    formatDate(date) {
      return this.$moment(date).format(f);
    }
  }
};
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-card", {
    staticClass: "asset-card",
    attrs: {
      "body-style": {
        padding: "12px"
      }
    }
  }, [_c("div", {
    staticClass: "d-flex align-items-center"
  }, [_c("div", {
    staticClass: "asset-card__thumbnail mr-3",
    style: {
      backgroundImage: "url(" + _vm.apiUrl + _vm.record.attachments[0].path + ")"
    }
  }), _c("div", {
    staticClass: "asset-card__info"
  }, [_c("a-button", {
    staticClass: "p-0 mb-1",
    style: {
      height: "auto",
      fontSize: "16px",
      fontWeight: "500"
    },
    attrs: {
      "type": "link"
    },
    on: {
      "click": function($event) {
        return _vm.pushToRouteWithQuery(`/assets/${_vm.record.id}`, {
          barcode: _vm.record.barcode
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.record.barcode) + " ")]), _c("p", {
    staticClass: "mb-1"
  }, [_c("a-icon", {
    staticClass: "mr-1",
    attrs: {
      "type": "clock-circle"
    }
  }), _vm._v(" " + _vm._s(_vm.formatDate(_vm.record.createdAt)) + " ")], 1), _c("p", {
    staticClass: "mb-1"
  }, [_c("a-icon", {
    staticClass: "mr-1",
    attrs: {
      "type": "user"
    }
  }), _vm._v(" " + _vm._s(_vm.record.createUserName) + " ")], 1), _c("p", {
    staticClass: "mb-0"
  }, [_c("a-icon", {
    staticClass: "mr-1",
    attrs: {
      "type": "environment"
    }
  }), _vm._v(" " + _vm._s(_vm.record.location) + " ")], 1)], 1)])]);
};
var staticRenderFns = [];
var AssetCard_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "AssetCard",
  mixins: [MyMixin],
  props: {
    record: {
      type: Object,
      default: void 0
    }
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "4632db15", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var AssetCard = /* @__PURE__ */ function() {
  return __component__.exports;
}();
const BarcodeScanner = () => import("./BarcodeScanner.js");
const MAGIC_NUM = 100;
const LIST_FACILITY_STATUS = [
  {
    name: "Open",
    status: "true"
  },
  {
    name: "Closed",
    status: "false"
  }
];
var FacilityConstants = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  MAGIC_NUM,
  LIST_FACILITY_STATUS
}, Symbol.toStringTag, { value: "Module" }));
function add(a, b) {
  return a + b;
}
var MyUtil = {
  add
};
const install = (Vue, options = {}) => {
  const { router } = options;
  if (router) {
    router.addRoutes(routes);
  }
};
export { AssetCard as A, BarcodeScanner as B, FasHeader as F, LIST_FACILITY_STATUS as L, MyMixin as M, FacilityConstants as a, MyUtil as b, install as i, normalizeComponent as n };
