import _ from "lodash";
import { FILTERS_CONTAINS, createCrudProps, FILTERS_EQUAL, VIEW_PERMISSION, CONTAINER_FILLING_ITEM, CONTAINER_FILLING_SPECIFICATION, CONTAINER_FILLING_PRODUCTIONLINE, CONTAINER_FILLING_LABELFORMAT, CONTAINER_FILLING_PRINTER, CONTAINER_FILLING_STATUS, CONTAINER_FILLING_CONTAINTERTYPE, CONTAINER_FILLING_ADMINISTRATION, CONTAINER_FILLING_SAMPLEPROCESSING, CONTAINER_FILLING_MANUALLABEL, CONTAINER_FILLING_CONTAINERPRODUCT, CONTAINER_FILLING_WEDGEMAINTAIN } from "@kraftheinz/common";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "select-input", class: _vm.className }, [_vm.formItem ? _c("validation-provider", { attrs: { "name": _vm.label, "rules": _vm.rules }, scopedSlots: _vm._u([{ key: "default", fn: function(slotProps) {
    return [_c("a-form-item", { attrs: { "colon": false, "has-feedback": "", "help": slotProps.errors[0], "label": _vm.showLabel ? _vm.label : void 0, "validate-status": _vm.resolveState(slotProps) } }, [_c("a-select", { staticClass: "w-100", attrs: { "disabled": _vm.disabled, "filter-option": _vm.searchBy.length > 0 || _vm.customSearch ? false : _vm.filterOption, "loading": _vm.loading || _vm.isFetching || _vm.isSearching, "mode": _vm.mode, "option-filter-prop": "children", "placeholder": _vm.disabled && !_vm.disableHasPlaceholder ? "" : _vm.placeholder, "show-search": "", "option-label-prop": _vm.optionLabelProp }, on: { "change": _vm.onChange, "search": _vm.fetchSearchData, "blur": _vm.onBlur }, model: { value: _vm.inputValue, callback: function($$v) {
      _vm.inputValue = $$v;
    }, expression: "inputValue" } }, [_vm._t("data-source", _vm._l(_vm.options, function(option, index) {
      return _c("a-select-option", { key: index, attrs: { "value": option[_vm.source] } }, [_c("span", { style: { color: option.color ? option.color : "#272d35" } }, [_vm._v(_vm._s(_vm.generateLabel(option)))])]);
    }), { "options": _vm.options }), _vm.lazy && !_vm.isComplete ? _c("a-select-option", { key: "infinite" }, [_c("infinite-loading", { on: { "infinite": _vm.onInfinite } })], 1) : _vm._e()], 2)], 1)];
  } }], null, true) }) : [_vm.label && _vm.showLabel ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c("div", [_c("a-select", { staticClass: "w-100", attrs: { "default-active-first-option": _vm.defaultActiveFirstOption, "disabled": _vm.disabled, "filter-option": _vm.searchBy.length > 0 || _vm.customSearch ? false : _vm.filterOption, "loading": _vm.loading || _vm.isFetching || _vm.isSearching, "mode": _vm.mode, "option-filter-prop": "children", "placeholder": _vm.disabled && !_vm.disableHasPlaceholder ? "" : _vm.placeholder, "show-search": "", "option-label-prop": _vm.optionLabelProp }, on: { "change": _vm.onChange, "search": _vm.fetchSearchData, "blur": _vm.onBlur }, model: { value: _vm.inputValue, callback: function($$v) {
    _vm.inputValue = $$v;
  }, expression: "inputValue" } }, [_vm._t("data-source", _vm._l(_vm.options, function(option, index) {
    return _c("a-select-option", { key: index, attrs: { "value": option[_vm.source] } }, [_c("span", { style: { color: option.color ? option.color : "#272d35" } }, [_vm._v(_vm._s(_vm.generateLabel(option)))])]);
  }), { "options": _vm.options }), _vm.lazy && !_vm.isComplete ? _c("a-select-option", { key: "infinite" }, [_c("infinite-loading", { on: { "infinite": _vm.onInfinite } })], 1) : _vm._e()], 2)], 1)]], 2);
};
var staticRenderFns = [];
function normalizeComponent(scriptExports, render2, staticRenderFns2, functionalTemplate, injectStyles, scopeId, moduleIdentifier, shadowMode) {
  var options = typeof scriptExports === "function" ? scriptExports.options : scriptExports;
  if (render2) {
    options.render = render2;
    options.staticRenderFns = staticRenderFns2;
    options._compiled = true;
  }
  if (functionalTemplate) {
    options.functional = true;
  }
  if (scopeId) {
    options._scopeId = "data-v-" + scopeId;
  }
  var hook;
  if (moduleIdentifier) {
    hook = function(context) {
      context = context || this.$vnode && this.$vnode.ssrContext || this.parent && this.parent.$vnode && this.parent.$vnode.ssrContext;
      if (!context && typeof __VUE_SSR_CONTEXT__ !== "undefined") {
        context = __VUE_SSR_CONTEXT__;
      }
      if (injectStyles) {
        injectStyles.call(this, context);
      }
      if (context && context._registeredComponents) {
        context._registeredComponents.add(moduleIdentifier);
      }
    };
    options._ssrRegister = hook;
  } else if (injectStyles) {
    hook = shadowMode ? function() {
      injectStyles.call(this, (options.functional ? this.parent : this).$root.$options.shadowRoot);
    } : injectStyles;
  }
  if (hook) {
    if (options.functional) {
      options._injectStyles = hook;
      var originalRender = options.render;
      options.render = function renderWithStyleInjection(h, context) {
        hook.call(context);
        return originalRender(h, context);
      };
    } else {
      var existing = options.beforeCreate;
      options.beforeCreate = existing ? [].concat(existing, hook) : [hook];
    }
  }
  return {
    exports: scriptExports,
    options
  };
}
const __vue2_script = {
  name: "CfSelectInput",
  props: {
    source: {
      type: String,
      required: true
    },
    advancedFilters: {
      type: Array,
      default: null
    },
    allInValue: {
      type: Boolean,
      default: false
    },
    allowDestroyed: {
      type: Boolean,
      default: false
    },
    apiNotGetPaging: {
      type: Boolean,
      default: false
    },
    className: {
      type: [String, Object],
      default: ""
    },
    clearDataOnDestroy: {
      type: Boolean,
      default: true
    },
    clearValueOnOptionsChange: {
      type: Boolean,
      default: true
    },
    confirmBeforeChange: {
      type: Boolean,
      default: false
    },
    confirmTitle: {
      type: String,
      default: ""
    },
    customQuery: {
      type: String,
      default: ""
    },
    customSearch: {
      type: Boolean,
      default: false
    },
    customUrl: {
      type: String,
      default: ""
    },
    dataSource: {
      type: Array,
      default: null
    },
    defaultActiveFirstOnChange: {
      type: Boolean,
      default: false
    },
    defaultActiveFirstOption: {
      type: Boolean,
      default: false
    },
    deleteFilter: {
      type: [String, Boolean],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disableHasPlaceholder: {
      type: Boolean,
      default: false
    },
    filterType: {
      type: String,
      default: FILTERS_CONTAINS
    },
    formItem: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    lazy: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: "default"
    },
    onlyCustomQuery: {
      type: Boolean,
      default: false
    },
    onSelectChange: {
      type: Function,
      default: () => {
      }
    },
    optionFilter: {
      type: Function,
      default: void 0
    },
    optionLabelProp: {
      type: String,
      default: null
    },
    parseValue: {
      type: Function,
      default: (value) => value
    },
    placeholder: {
      type: String,
      default: ""
    },
    queries: {
      type: Array,
      default: null
    },
    reference: {
      type: String,
      default: null
    },
    resetDefautFirstOption: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    searchBy: {
      type: String,
      default: ""
    },
    selectedWithValue: {
      type: String,
      default: null
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    sourceLabel: {
      type: [String, Function],
      default: ""
    },
    value: {
      type: [Number, String, Array],
      default: void 0
    },
    defaultActiveWithOneOption: {
      type: Boolean,
      default: false
    },
    invalidChange: {
      type: Function,
      default: void 0
    },
    isDelQueryStringInSearch: {
      type: Boolean,
      default: false
    },
    allowOnChangeInValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const data = {
      currentValue: null,
      isComplete: false,
      isFetching: false,
      isSearching: false,
      isValueFirstChange: true,
      notSearchNull: false,
      isRevertChanging: false
    };
    if (this.reference) {
      const crudProps = createCrudProps({
        resourceName: this.reference,
        resourceIdName: this.source,
        router: this.$router,
        store: this.$store
      });
      Object.assign(data, crudProps);
    }
    data.inputValue = this.parseValue(this.value);
    return data;
  },
  computed: {
    options() {
      if (this.reference) {
        let options = this.crud.getList();
        if (this.optionFilter) {
          return options.filter(this.optionFilter);
        }
        return options;
      }
      if (this.optionFilter) {
        return this.dataSource.filter(this.optionFilter);
      }
      return this.dataSource;
    }
  },
  watch: {
    advancedFilters(newVal) {
      if (!newVal)
        return;
      newVal.forEach(([key, value]) => this.crud.setFilter(key, value));
      this.fetchList();
    },
    customQuery() {
      this.fetchList();
    },
    customUrl() {
      this.fetchList();
    },
    options(newVal, oldVal) {
      if (!this.clearValueOnOptionsChange && oldVal && oldVal.length)
        return;
      if (this.defaultActiveFirstOption && !this.value && newVal.length || this.defaultActiveFirstOption && this.resetDefautFirstOption && newVal.length || this.defaultActiveWithOneOption && newVal.length === 1) {
        if (this.mode == "multiple") {
          this.inputValue = [newVal[0][this.source]];
        } else {
          this.inputValue = newVal[0][this.source];
        }
        this.handleChange(this.inputValue);
        return;
      }
      if (this.inputValue) {
        if (this.mode == "multiple") {
          const values = this._.intersection(this.inputValue, newVal.map((option) => option[this.source]));
          this.setValue(values);
          this.commitChange(values);
          return;
        }
      }
    },
    async queries(newVal, oldVal) {
      if (!newVal)
        return;
      if (JSON.stringify(newVal) === JSON.stringify(oldVal))
        return;
      this.isFetching = true;
      newVal.forEach(([key, value]) => this.crud.setQueryString(key, value));
      await this.fetchList();
      this.isFetching = false;
    },
    async value(newVal, oldVal) {
      const hasOnChange = newVal === this.currentValue;
      this.currentValue = this.parseValue(newVal);
      if (this.isValueFirstChange && this.selectedWithValue) {
        this.isFetching = true;
        this.crud.setQueryString(this.selectedWithValue, this.parseValue(newVal));
        await this.fetchList();
        this.crud.deleteQueryStrings();
        this.isValueFirstChange = false;
        this.isFetching = false;
      }
      this.inputValue = this.parseValue(newVal);
      if (this.mode !== "multiple") {
        if (this.defaultActiveFirstOnChange && this._.isNil(oldVal)) {
          this.handleChange(this.parseValue(newVal));
          return;
        }
      }
      if (this.allowOnChangeInValue && !hasOnChange) {
        this.handleChange(this.parseValue(newVal));
      }
    },
    loading(isLoading) {
      if (!isLoading && this.customSearch && this.isRevertChanging) {
        this.inputValue = this.currentValue;
        this.isRevertChanging = false;
      }
    }
  },
  async created() {
    try {
      if (this.reference) {
        this.isFetching = true;
        if (this.lazy) {
          this.crud.setPagination({ page: 1, pageSize: 50 });
          this.crud.setFilter("IsInactive", {
            operator: FILTERS_EQUAL,
            value: "false"
          });
        } else {
          this.crud.deleteFilter("IsInactive");
        }
        if (this.advancedFilters) {
          this.advancedFilters.forEach(([key, value]) => this.crud.setFilter(key, value));
        }
        if (this.queries) {
          this.queries.forEach(([key, value]) => this.crud.setQueryString(key, value));
        }
        if (this.selectedWithValue && this.isValueFirstChange)
          return;
        await this.fetchList();
      }
      if (this.dataSource && this.dataSource.length && !this.value && this.defaultActiveFirstOption) {
        this.inputValue = this.options[0][this.source];
        this.handleChange(this.inputValue);
      }
    } finally {
      this.isFetching = false;
    }
  },
  destroyed() {
    if (this.clearDataOnDestroy && this.crud) {
      this.crud.clearList();
      this.crud.clearFilters();
      this.crud.deleteQueryStrings();
    }
  },
  methods: {
    commitChange(value) {
      this.currentValue = value;
      if (this.mode == "multiple") {
        let objs = value.map((val) => this.options.find((option) => option[this.source] == val));
        if (!this.allInValue) {
          objs = objs.map((obj) => obj[this.source]);
        }
        this.$emit("change", objs);
        this.onSelectChange(objs);
      } else {
        if (!this.allInValue) {
          this.$emit("change", value);
          this.onSelectChange(value);
          return;
        }
        const obj = this.options.find((item) => item[this.source] == value);
        this.$emit("change", obj || {});
        this.onSelectChange(obj);
      }
    },
    fetchList(customUrl = this.customUrl, refresh = true) {
      if (this.deleteFilter) {
        this.crud.deleteFilter(this.deleteFilter);
      }
      if (refresh) {
        this.crud.setPagination({ page: 1, pageSize: 50 });
      }
      if (this.apiNotGetPaging) {
        this.crud.deletePagination();
      }
      return this.crud.fetchList(customUrl, refresh, true, this.customQuery, this.onlyCustomQuery);
    },
    revertChange() {
      const findCurrentValue = (option) => option.id === this.currentValue;
      const currentValueFound = this.options.some(findCurrentValue);
      if (!currentValueFound && this.customSearch) {
        this.isRevertChanging = true;
        this.$emit("revert-change", this.currentValue);
      } else {
        this.inputValue = this.currentValue;
      }
    },
    setValue(newVal) {
      this.inputValue = newVal;
    },
    fetchSearchData: _.debounce(async function(value) {
      if (this.customSearch) {
        if (this.notSearchNull && !value) {
          this.notSearchNull = false;
          return;
        }
        this.$emit("search", value);
        return;
      }
      if (this.searchBy.length === 0 || this.notSearchNull && !value) {
        this.notSearchNull = false;
        return;
      }
      this.isSearching = true;
      const val = {
        operator: this.filterType,
        value
      };
      if (this.deleteFilter) {
        this.crud.deleteFilter(this.deleteFilter);
      }
      if (this.isDelQueryStringInSearch) {
        this.crud.deleteQueryStrings();
      }
      this.crud.setFilter(this.searchBy, val);
      this.crud.setPagination({ page: 1, pageSize: 50 });
      await this.fetchList();
      this.isSearching = false;
    }, 600),
    filterOption(input, option) {
      const stringQueryFn = this.filterType == FILTERS_CONTAINS ? "includes" : "startsWith";
      return option.componentOptions.children[0].elm.innerText.toLowerCase()[stringQueryFn](input.toLowerCase());
    },
    generateLabel(option) {
      if (!this.sourceLabel)
        return option[this.source];
      if (typeof this.sourceLabel == "string")
        return option[this.sourceLabel];
      return this.sourceLabel(option);
    },
    resolveState({ errors }) {
      if (errors[0])
        return "error";
      return "";
    },
    onChange(value) {
      this.handleChange(value);
    },
    handleChange(value) {
      this.notSearchNull = true;
      if (!this.confirmBeforeChange) {
        return this.commitChange(value);
      }
      let valChange = value;
      if (this.allInValue) {
        valChange = this.options.find((item) => item[this.source] == value);
      }
      if (this.invalidChange(valChange))
        return;
      this.$confirm({
        title: this.confirmTitle,
        icon: "warning",
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onOk: () => this.commitChange(value),
        onCancel: this.revertChange
      });
    },
    async onInfinite($state) {
      const { page, pageSize } = this.crud.getPagination();
      this.crud.setPagination({ page: page + 1, pageSize });
      const res = await this.fetchList(this.customUrl, false);
      if (res.data.length) {
        $state.loaded();
      } else {
        $state.complete();
        this.isComplete = true;
      }
    },
    onBlur(value) {
      if (value === "labelSelectOption") {
        this.inputValue = this.options[0][this.source];
        this.handleChange(this.options[0][this.source]);
      }
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var SelectInput = /* @__PURE__ */ function() {
  return __component__.exports;
}();
SelectInput.install = (Vue) => {
  Vue.component(SelectInput.name, SelectInput);
};
var components = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  SelectInput
}, Symbol.toStringTag, { value: "Module" }));
const ContainerFilling = () => import("./ContainerFilling.js");
const ListPrinter = () => import("./index2.js");
const CreatePrinter = () => import("./index3.js");
const EditPrinter = () => import("./index4.js");
const ListContainerType = () => import("./index5.js");
const CreateContainerType = () => import("./index6.js");
const EditContainerType = () => import("./index7.js");
const ListStatus = () => import("./index8.js");
const CreateStatus = () => import("./index9.js");
const EditStatus = () => import("./index10.js");
const Item = () => import("./index11.js");
const ListItem = () => import("./index12.js");
const CreateItem = () => import("./index13.js");
const EditItem = () => import("./index14.js");
const Specification = () => import("./index15.js");
const ListSpecification = () => import("./index16.js");
const CreateSpecification = () => import("./index17.js");
const EditSpecification = () => import("./index18.js");
const EditSpecificationTest = () => import("./index19.js");
const ProductionLine = () => import("./index20.js");
const ListProductionLine = () => import("./index21.js");
const CreateProductionLine = () => import("./index22.js");
const EditProductionLine = () => import("./index23.js");
const FilterProductionLine = () => import("./index24.js");
const LabelFormats = () => import("./index25.js");
const ListLabelFormat = () => import("./index26.js");
const CreateLabelFormat = () => import("./index27.js");
const EditLabelFormat = () => import("./index28.js");
const ProcessLabelFormat = () => import("./index29.js");
const ListAdministration = () => import("./index30.js");
const EditAdministration = () => import("./index31.js");
const SampleProcessing = () => import("./index32.js");
const ListSampleProcessing = () => import("./index33.js");
const CreateSampleProcessing = () => import("./index34.js");
const EditSampleProcessing = () => import("./index35.js");
const PrintSampleProcessing = () => import("./index36.js");
const ManualLabel = () => import("./index37.js");
const ListManualLabel = () => import("./index38.js");
const CreateManualLabel = () => import("./index39.js");
const EditManualLabel = () => import("./index40.js");
const PrintManualLabel = () => import("./index41.js");
const ContainerProduction = () => import("./index42.js");
const ListContainerProduction = () => import("./index43.js");
const CreateContainerProduction = () => import("./index44.js");
const EditContainerProduction = () => import("./index45.js");
const PrinterContainerProduction = () => import("./index46.js");
const WedgeMaintenance = () => import("./index47.js");
const Reports = () => import("./index48.js");
const ReportsMenu = () => import("./index49.js");
var routes = [
  {
    path: "/container-filling",
    name: "ContainerFilling",
    component: ContainerFilling,
    redirect: { name: "ListFillingsReports" },
    children: [
      {
        path: "item",
        name: "Item",
        component: Item,
        meta: "masterfiles",
        children: [
          {
            path: "",
            name: "ListItem",
            component: ListItem,
            meta: `masterfiles;${VIEW_PERMISSION};${CONTAINER_FILLING_ITEM}`
          },
          {
            path: "create",
            name: "CreateItem",
            component: CreateItem
          },
          {
            path: ":id",
            name: "EditItem",
            component: EditItem
          }
        ]
      },
      {
        path: "specification",
        name: "specification",
        component: Specification,
        meta: `masterfiles;${VIEW_PERMISSION};${CONTAINER_FILLING_SPECIFICATION}`,
        children: [
          {
            path: "",
            name: "ListSpecification",
            component: ListSpecification
          },
          {
            path: "create",
            name: "CreateSpecification",
            component: CreateSpecification
          },
          {
            path: ":id",
            name: "EditSpecification",
            component: EditSpecification,
            children: [
              {
                path: ":idTest",
                name: "EditSpecificationTest",
                component: EditSpecificationTest
              }
            ]
          }
        ]
      },
      {
        path: "production-line",
        name: "ProductionLine",
        component: ProductionLine,
        meta: `masterfiles;${VIEW_PERMISSION};${CONTAINER_FILLING_PRODUCTIONLINE}`,
        children: [
          {
            path: "",
            name: "ListProductionLine",
            component: ListProductionLine,
            children: [
              {
                path: "advanced-search",
                name: "FilterProductionLine",
                component: FilterProductionLine
              }
            ]
          },
          {
            path: "create",
            name: "CreateProductionLine",
            component: CreateProductionLine
          },
          {
            path: ":id",
            name: "EditProductionLine",
            component: EditProductionLine
          }
        ]
      },
      {
        path: "label-formats",
        name: "LabelFormats",
        component: LabelFormats,
        meta: `masterfiles;${VIEW_PERMISSION};${CONTAINER_FILLING_LABELFORMAT}`,
        children: [
          {
            path: "",
            name: "ListLabelFormat",
            component: ListLabelFormat,
            children: [
              {
                path: "create",
                name: "CreateLabelFormat",
                component: CreateLabelFormat
              },
              {
                path: ":id",
                name: "EditLabelFormat",
                component: EditLabelFormat
              }
            ]
          },
          {
            path: "detail/:id",
            name: "ProcessLabelFormat",
            component: ProcessLabelFormat
          }
        ]
      },
      {
        path: "label-printers",
        name: "LabelPrinters",
        component: ListPrinter,
        meta: `masterfiles;${VIEW_PERMISSION};${CONTAINER_FILLING_PRINTER}`,
        children: [
          {
            path: "create",
            name: "CreateLabelPrinter",
            component: CreatePrinter
          },
          {
            path: ":id",
            name: "EditLabelPrinter",
            component: EditPrinter
          }
        ]
      },
      {
        path: "status",
        name: "Status",
        component: ListStatus,
        meta: `masterfiles;${VIEW_PERMISSION};${CONTAINER_FILLING_STATUS}`,
        children: [
          {
            path: "create",
            name: "CreateStatus",
            component: CreateStatus
          },
          {
            path: ":id",
            name: "EditStatus",
            component: EditStatus
          }
        ]
      },
      {
        path: "container-type",
        name: "ContainerType",
        component: ListContainerType,
        meta: `masterfiles;${VIEW_PERMISSION};${CONTAINER_FILLING_CONTAINTERTYPE}`,
        children: [
          {
            path: "create",
            name: "CreateContainerType",
            component: CreateContainerType
          },
          {
            path: ":id",
            name: "EditContainerType",
            component: EditContainerType
          }
        ]
      },
      {
        path: "administration",
        name: "Administration",
        component: ListAdministration,
        meta: `;${VIEW_PERMISSION};${CONTAINER_FILLING_ADMINISTRATION}`,
        children: [
          {
            path: ":id",
            name: "EditAdministration",
            component: EditAdministration
          }
        ]
      },
      {
        path: "sample-processing",
        name: "SampleProcessing",
        component: SampleProcessing,
        children: [
          {
            path: "",
            name: "ListSampleProcessing",
            component: ListSampleProcessing,
            meta: `;${VIEW_PERMISSION};${CONTAINER_FILLING_SAMPLEPROCESSING}`
          },
          {
            path: "create",
            name: "CreateSampleProcessing",
            component: CreateSampleProcessing
          },
          {
            path: ":id",
            name: "EditSampleProcessing",
            component: EditSampleProcessing,
            props: (route) => ({
              id: +route.params.id
            }),
            children: [
              {
                path: "print",
                name: "PrintSampleInEdit",
                component: PrintSampleProcessing
              }
            ]
          }
        ]
      },
      {
        path: "manual-label",
        name: "ManualLabel",
        component: ManualLabel,
        meta: `containerprocessing;${VIEW_PERMISSION};${CONTAINER_FILLING_MANUALLABEL}`,
        children: [
          {
            path: "",
            name: "ListManualLabel",
            component: ListManualLabel,
            children: [
              {
                path: "print/:id",
                name: "PrintManualLabelInList",
                component: PrintManualLabel
              }
            ]
          },
          {
            path: "create",
            name: "CreateManualLabel",
            component: CreateManualLabel
          },
          {
            path: ":id",
            name: "EditManualLabel",
            component: EditManualLabel,
            children: [
              {
                path: "print",
                name: "PrintManualLabelInEdit",
                component: PrintManualLabel
              }
            ]
          }
        ]
      },
      {
        path: "container-production",
        name: "ContainerProduction",
        component: ContainerProduction,
        meta: `containerprocessing;${VIEW_PERMISSION};${CONTAINER_FILLING_CONTAINERPRODUCT}`,
        children: [
          {
            path: "",
            name: "ListContainerProduction",
            component: ListContainerProduction,
            children: [
              {
                path: "printer/:id",
                name: "PrinterContainerProductionInList",
                component: PrinterContainerProduction
              }
            ]
          },
          {
            path: "create",
            name: "CreateContainerProduction",
            component: CreateContainerProduction
          },
          {
            path: ":id",
            name: "EditContainerProduction",
            component: EditContainerProduction,
            children: [
              {
                path: "printer",
                name: "PrinterContainerProductionInEdit",
                component: PrinterContainerProduction
              }
            ]
          }
        ]
      },
      {
        path: "reports",
        name: "FillingReports",
        component: Reports,
        children: [
          {
            path: "",
            name: "ListFillingsReports",
            component: ReportsMenu
          }
        ]
      },
      {
        path: "wedge-maintenance",
        name: "WedgeMaintenance",
        component: WedgeMaintenance,
        meta: `wedge-maintenance;${VIEW_PERMISSION};${CONTAINER_FILLING_WEDGEMAINTAIN}`,
        children: []
      }
    ]
  }
];
const install = (Vue, options = {}) => {
  const { router } = options;
  Object.values(components).map((component) => {
    let name = options.prefix ? options.prefix + component.name : component.name;
    Vue.component(name, component);
  });
  if (router) {
    router.addRoutes(routes);
  }
};
export { install as i, normalizeComponent as n };
