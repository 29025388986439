var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'application-card',
    'd-flex',
    'flex-column',
    { disabled: _vm.app.disabled || _vm.app.isComingSoon },
    { hidden: _vm.app.hidden } ],style:({
    backgroundImage: ("linear-gradient(" + (_vm.app.color) + "," + (_vm.app.color) + "),url(" + _vm.apiUrl + (_vm.app.image) + ")"),
  })},[_c('div',{staticClass:"d-flex justify-content-between mb-auto"},[_c('div',[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.app.title))]),_c('h5',[_vm._v(" "+_vm._s(_vm.app.name)+" "),(_vm.app.isComingSoon)?_c('p',{staticClass:"neutral-3--text"},[_vm._v(" ("+_vm._s(_vm.$t("app_portal.coming_soon"))+") ")]):_vm._e()]),_c('h5',{staticClass:"neutral-3--text text-lowercase"},[_vm._v(" "+_vm._s(_vm.app.ciNumber)+" ")])]),_c('div',[_c('a-rate',{attrs:{"count":1,"value":_vm.app.userId ? 1 : 0,"disabled":_vm.app.disabled || _vm.app.isComingSoon},on:{"change":_vm.onBookmarkChange}})],1)]),_c('span',{staticClass:"description neutral-1--text mb-4"},[_vm._v(_vm._s(_vm.app.description))]),_c('div',{staticClass:"d-flex justify-content-between"},[_c('a-button',{staticStyle:{"width":"100px"},attrs:{"type":"primary","disabled":_vm.app.disabled || _vm.app.isComingSoon || _vm.env == 'staging'},on:{"click":function($event){return _vm.launchApp(_vm.app.launchUrl, _vm.app.code)}}},[_vm._v(" "+_vm._s(_vm.$t("app_portal.launch"))+" "),_c('a-icon',{staticClass:"ml-2",attrs:{"type":"select"}})],1),_c('a-button',{staticStyle:{"width":"100px","color":"white"},attrs:{"type":"link","disabled":_vm.app.disabled || _vm.app.isComingSoon || !_vm.app.testUrl || _vm.env == 'prod'},on:{"click":function($event){return _vm.launchApp(_vm.app.testUrl, _vm.app.code)}}},[_vm._v(" "+_vm._s(_vm.$t("app_portal.launch_test"))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }