const LabelVerification = () => import("./LabelVerification.js");
const Report = () => import("./index2.js");
const Operators = () => import("./index3.js");
const CreateOperator = () => import("./index4.js");
const EditOperator = () => import("./index5.js");
const VerifyingLabel = () => import("./index6.js");
var routes = [
  {
    path: "/label-verification",
    name: "LabelVerification",
    component: LabelVerification,
    redirect: { name: "VerifyingLabel" },
    children: [
      {
        path: "report",
        name: "Report",
        component: Report,
        children: []
      },
      {
        path: "operators",
        name: "Operators",
        component: Operators,
        meta: "masterFiles;",
        children: [
          {
            path: "create",
            name: "CreateOperator",
            component: CreateOperator
          },
          {
            path: ":id",
            name: "EditOperator",
            component: EditOperator
          }
        ]
      },
      {
        path: "verifying-label",
        name: "VerifyingLabel",
        component: VerifyingLabel,
        children: []
      }
    ]
  }
];
const DEFAULT_FACILITY_CODE = "pt";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _vm.showFilter ? _c("div", [_c("a-row", { attrs: { "type": "flex", "justify": "end", "gutter": 8 } }, [_c("a-col", [_c("div", { style: { width: "250px" } }, [_c("select-input", { attrs: { "source": "code", "source-label": "fullName", "data-source": _vm.listFacilities, "value": _vm.facility }, on: { "change": _vm.onFacilityChange } })], 1)]), _c("a-col", [_c("div", { style: { width: "166px" } }, [_c("select-input", { attrs: { "source": "code", "source-label": "name", "data-source": _vm.listAreas, "default-active-first-option": "" }, on: { "change": _vm.onAreaChange } })], 1)])], 1)], 1) : _vm._e();
};
var staticRenderFns$1 = [];
function normalizeComponent(scriptExports, render2, staticRenderFns2, functionalTemplate, injectStyles, scopeId, moduleIdentifier, shadowMode) {
  var options = typeof scriptExports === "function" ? scriptExports.options : scriptExports;
  if (render2) {
    options.render = render2;
    options.staticRenderFns = staticRenderFns2;
    options._compiled = true;
  }
  if (functionalTemplate) {
    options.functional = true;
  }
  if (scopeId) {
    options._scopeId = "data-v-" + scopeId;
  }
  var hook;
  if (moduleIdentifier) {
    hook = function(context) {
      context = context || this.$vnode && this.$vnode.ssrContext || this.parent && this.parent.$vnode && this.parent.$vnode.ssrContext;
      if (!context && typeof __VUE_SSR_CONTEXT__ !== "undefined") {
        context = __VUE_SSR_CONTEXT__;
      }
      if (injectStyles) {
        injectStyles.call(this, context);
      }
      if (context && context._registeredComponents) {
        context._registeredComponents.add(moduleIdentifier);
      }
    };
    options._ssrRegister = hook;
  } else if (injectStyles) {
    hook = shadowMode ? function() {
      injectStyles.call(this, (options.functional ? this.parent : this).$root.$options.shadowRoot);
    } : injectStyles;
  }
  if (hook) {
    if (options.functional) {
      options._injectStyles = hook;
      var originalRender = options.render;
      options.render = function renderWithStyleInjection(h, context) {
        hook.call(context);
        return originalRender(h, context);
      };
    } else {
      var existing = options.beforeCreate;
      options.beforeCreate = existing ? [].concat(existing, hook) : [hook];
    }
  }
  return {
    exports: scriptExports,
    options
  };
}
const __vue2_script$1 = {
  name: "FacilityAreaFilter",
  inject: ["resourceProps"],
  data() {
    const [facilityProps, areaProps] = this.resourceProps;
    return {
      apiUrl: "#{VUE_APP_API_URL}#",
      showFilter: false,
      facilityProps,
      areaProps,
      facility: DEFAULT_FACILITY_CODE,
      area: ""
    };
  },
  computed: {
    listFacilities() {
      return this.facilityProps.crud.getList();
    },
    listAreas() {
      return this.areaProps.crud.getList();
    }
  },
  watch: {
    async listFacilities(newVal) {
      if (newVal.length > 0) {
        const selectedFacility = newVal.filter((item) => item.code === this.facility)[0];
        await this.areaProps.crud.fetchList(`${this.apiUrl}/identities/common/departments?facility=${selectedFacility.code}`);
      }
      this.showFilter = true;
    },
    listAreas() {
      this.$emit("facilityChange", this.facility);
    }
  },
  created() {
    this.deleteFilterPagination();
    this.facilityProps.crud.fetchList(`${this.apiUrl}/identities/common/facilities`);
  },
  methods: {
    async onFacilityChange(facilityCode) {
      this.facility = facilityCode;
      this.$emit("facilityChange", this.facility);
      await this.areaProps.crud.fetchList(`${this.apiUrl}/identities/common/departments?facility=${facilityCode}`);
    },
    onAreaChange(areaCode) {
      this.area = areaCode;
      this.$emit("areaChange", areaCode);
    },
    deleteFilterPagination() {
      this.facilityProps.crud.deleteFilter("IsInactive");
      this.facilityProps.crud.deletePagination();
      this.areaProps.crud.deleteFilter("IsInactive");
      this.areaProps.crud.deletePagination();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FacilityAreaFilter = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "facility-area-filter" }, [_c("resource", { attrs: { "name": ["identities.common.facilities", "identities.common.departments"], "api-url": _vm.apiUrl } }, [_c("facility-area-filter", { on: { "facilityChange": _vm.onFacilityChange, "areaChange": _vm.onAreaChange } })], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "LabelVerificationFilter",
  components: {
    FacilityAreaFilter
  },
  data() {
    return {
      apiUrl
    };
  },
  methods: {
    onFacilityChange(value) {
      this.$emit("facilityChange", value);
    },
    onAreaChange(value) {
      this.$emit("areaChange", value);
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var LabelVerificationFilter = /* @__PURE__ */ function() {
  return __component__.exports;
}();
var MyMixin = {
  methods: {
    hello() {
      return "hello from mixin!";
    }
  }
};
const STATUS_ALL = 0;
const STATUS_MATCH = 1;
const STATUS_NOT_MATCH = 2;
const STATUS_TEXTS = {
  [STATUS_MATCH]: "Match",
  [STATUS_NOT_MATCH]: "Doesn't Match"
};
const STATUS_COLORS = {
  [STATUS_MATCH]: "cyan",
  [STATUS_NOT_MATCH]: "orange"
};
const LIST_STATUS = [
  {
    id: STATUS_MATCH,
    status: STATUS_TEXTS[STATUS_MATCH]
  },
  {
    id: STATUS_NOT_MATCH,
    status: STATUS_TEXTS[STATUS_NOT_MATCH]
  }
];
const LOCATION_ANY = 0;
const LOCATION_FRONT = 1;
const LOCATION_BACK = 2;
const LOCATION_OTHER = 3;
const SECONDARY_PACKAGING = 4;
const LOCATION_TEXTS = {
  [LOCATION_ANY]: "Any Location",
  [LOCATION_FRONT]: "Front Location",
  [LOCATION_BACK]: "Back Location",
  [LOCATION_OTHER]: "Other Location",
  [SECONDARY_PACKAGING]: "Secondary Packaging"
};
const LIST_LOCATIONS = [
  {
    id: LOCATION_ANY,
    name: LOCATION_TEXTS[LOCATION_ANY]
  },
  {
    id: LOCATION_FRONT,
    name: LOCATION_TEXTS[LOCATION_FRONT]
  },
  {
    id: LOCATION_BACK,
    name: LOCATION_TEXTS[LOCATION_BACK]
  },
  {
    id: LOCATION_OTHER,
    name: LOCATION_TEXTS[LOCATION_OTHER]
  },
  {
    id: SECONDARY_PACKAGING,
    name: LOCATION_TEXTS[SECONDARY_PACKAGING]
  }
];
const BARCODE_1D = 1;
const BARCODE_2D = 2;
const BARCODE_TYPE_TEXTS = {
  [BARCODE_1D]: "1D",
  [BARCODE_2D]: "2D"
};
const LIST_BARCODE_TYPE = [
  { id: BARCODE_1D, name: BARCODE_TYPE_TEXTS[BARCODE_1D] },
  { id: BARCODE_2D, name: BARCODE_TYPE_TEXTS[BARCODE_2D] }
];
function add(a, b) {
  return a + b;
}
var MyUtil = {
  add
};
const install = (Vue, options = {}) => {
  const { router } = options;
  if (router) {
    router.addRoutes(routes);
  }
};
export { BARCODE_1D as B, DEFAULT_FACILITY_CODE as D, LabelVerificationFilter as L, MyMixin as M, STATUS_TEXTS as S, LIST_STATUS as a, LOCATION_TEXTS as b, STATUS_COLORS as c, BARCODE_2D as d, LIST_BARCODE_TYPE as e, STATUS_MATCH as f, STATUS_NOT_MATCH as g, STATUS_ALL as h, install as i, LOCATION_ANY as j, LOCATION_FRONT as k, LOCATION_BACK as l, LOCATION_OTHER as m, normalizeComponent as n, SECONDARY_PACKAGING as o, LIST_LOCATIONS as p, BARCODE_TYPE_TEXTS as q, MyUtil as r };
