<template>
  <div id="app">
    <admin
      v-if="
        location.host != 'prod.anjappportal.mykft.net' ||
        location.pathname != '/'
      "
      :app-layout="MasterLayout"
      :auth-provider="authProvider"
    />
    <a-modal v-else title="News" :visible="visible">
      <p>
        The portal is migrated to Azure, please click on "Ok" to redirect to the
        new Portal
      </p>
      <template slot="footer">
        <a-button @click="onclose"> Ok </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import MasterLayout from "./layouts/MasterLayout";
import createAuthProvider from "./store/auth.provider";

export default {
  name: "App",

  data() {
    const authProvider = createAuthProvider(this.axios, {
      authUrls: {
        login: `${process.env.VUE_APP_API_URL}/login`,
        operations: `${process.env.VUE_APP_API_URL}/identities/self/operations`,
        userInfo: `${process.env.VUE_APP_API_URL}/identities/users`,
      },
      storageKey: "accessToken",
      userField: "userId",
    });

    return {
      MasterLayout,
      authProvider,
      visible: true,
    };
  },
  computed: {
    location() {
      return window.location;
    },
  },
  methods: {
    onclose() {
      window.location.href = "https://anjappportal.kraftheinz.com/";
    },
  },
};
</script>

<style lang="scss">
.list__modal-confirm {
  .ant-modal-confirm-body {
    .anticon {
      color: #0062ff !important;
    }
  }

  &.list__modal-reverse-transaction {
    .ant-modal-confirm-body {
      .anticon {
        color: #d9363e !important;
      }
    }
  }

  &.list__modal-warning {
    .ant-modal-confirm-body {
      .anticon {
        color: #fa8c16 !important;
      }
    }
  }
  .ant-modal-confirm-btns {
    margin-top: 52px;
  }
}

.list__modal-delete {
  .ant-modal-confirm-body {
    .anticon {
      color: #d9363e !important;
    }
  }
}

.ant-notification-notice {
  .ant-notification-notice-content {
    word-break: break-word;
  }
}
</style>
