var components = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  [Symbol.toStringTag]: "Module"
});
const ShipmentClosure = () => import("./ShipmentClosure.js");
const ShipmentClosureStatus = () => import("./index.js");
const ListShipmentClosureStatus = () => import("./index2.js");
const PortfolioData = () => import("./index3.js");
const CreatePortfolioData = () => import("./index4.js");
const EditPortfolioData = () => import("./index5.js");
const ListPortfolioData = () => import("./index6.js");
const CreateCategoryData = () => import("./index7.js");
const EditCategoryData = () => import("./index8.js");
const ListCategoryData = () => import("./index9.js");
const CreateItemClassificationData = () => import("./index10.js");
const EditItemClassificationData = () => import("./index11.js");
const ListItemClassificationsData = () => import("./index12.js");
var routes = [
  {
    path: "/shipment-closure",
    name: "ShipmentClosure",
    component: ShipmentClosure,
    redirect: {
      name: "ListShipmentClosureStatus"
    },
    children: [
      {
        path: "portfolio-data",
        component: PortfolioData,
        name: "PortfolioData",
        meta: "masterfiles",
        children: [
          {
            path: "",
            name: "ListPortfolioData",
            component: ListPortfolioData,
            children: [
              {
                path: "create",
                name: "CreatePortfolioData",
                component: CreatePortfolioData
              },
              {
                path: ":id",
                name: "EditPortfolioData",
                component: EditPortfolioData
              }
            ]
          }
        ]
      },
      {
        path: "category-data",
        name: "Category",
        component: ListCategoryData,
        children: [
          {
            path: "create",
            name: "CreateCategoryData",
            component: CreateCategoryData
          },
          {
            path: ":id",
            name: "EditCategoryData",
            component: EditCategoryData
          }
        ]
      },
      {
        path: "item-classification-data",
        name: "Item Classification",
        component: ListItemClassificationsData,
        children: [
          {
            path: "create",
            name: "CreateItemClassificationData",
            component: CreateItemClassificationData
          },
          {
            path: ":id",
            name: "EditItemClassificationData",
            component: EditItemClassificationData
          }
        ]
      },
      {
        path: "shipment-closure-status",
        name: "ShipmentClosureStatus",
        component: ShipmentClosureStatus,
        children: [
          {
            path: "",
            name: "ListShipmentClosureStatus",
            component: ListShipmentClosureStatus,
            children: []
          }
        ]
      }
    ]
  }
];
const install = (Vue, options = {}) => {
  const { router } = options;
  Object.values(components).map((component) => {
    let name = options.prefix ? options.prefix + component.name : component.name;
    Vue.component(name, component);
  });
  if (router) {
    router.addRoutes(routes);
  }
};
export { install as default };
