export const APP_CONTAINER_FILLING = "container-filling";
export const APP_GROWER = "grower";
export const APP_WEB_ANALYTICS = "web-analytics";
export const APP_FAS = "fas";
export const APP_EXPORT_ORDERS = "exportorder";
export const APP_PPV_ACTUALS = "ppv";

export const ENTITY_LEVEL_FACILITY = "facility";
export const ENTITY_LEVEL_COUNTRY = "country";
export const ENTITY_LEVEL_COMPANY = "company";
