import { VIEW_PERMISSION, GROWERS_BUNKERMASTER, MAINTAIN_PERMISSION, GROWERS_CROPFAMILY, GROWERS_DEDUCTIONS, GROWERS_CULTIVARS, GROWERS_PRICINGCROPS, GROWERS_GONDOLA, GROWERS_GONDOLALOG, GROWERS_TUBS, GROWERS_TRUCKTYPES, GROWERS_PRINTERS, GROWERS_WAREHOURSES, GROWERS_STORAGEGRADES, GROWERS_BINSTATUS, GROWERS_CONTRACTS, GROWERS_HARVESTSTATUS, GROWERS_SAMPLETYPES, GROWERS_CROPS, GROWERS_PADDOCKHARVESTWEIGHT, GROWERS_INTAKELIST, GROWERS_UPLOADINTAKES, GROWERS_REVERSECORRECT, GROWERS_SAMPLEBINS, GROWERS_BARCODEREPRINT, GROWERS_OFFSITESTORAGE, GROWERS_SAMPLEPROCESSING, GROWERS_BUNKERPROCESSING, GROWERS_VBINSPROCESSING, GROWERS_BUNKERDASHBOARD, GROWERS_OPERATORS, GROWERS_TRUCKS, EDITTRUCK_PERMISSION, EDITTARE_PERMISSION, GROWERS_ATTRIBUTES, GROWERS_GRADES, GROWERS_GRADEMAP, GROWERS_FINANCEPROCESSING, GROWERS_SYSTEMADMINISTRATION } from "@kraftheinz/common";
const WattiesGrower = () => import("./WattiesGrower.js");
const ListCropFamily = () => import("./index.js");
const CreateCropFamily = () => import("./index2.js");
const EditCropFamily = () => import("./index3.js");
const ListCultivars = () => import("./index4.js");
const ListPricingCrops = () => import("./index5.js");
const ListGondola = () => import("./index6.js");
const CreateGondola = () => import("./index7.js");
const EditGondola = () => import("./index8.js");
const GondolaLog = () => import("./index9.js");
const CreateGondolaLog = () => import("./index10.js");
const EditGondolaLog = () => import("./index11.js");
const ListTubs = () => import("./index12.js");
const CreateTubs = () => import("./index13.js");
const EditTubs = () => import("./index14.js");
const ListTruckTypes = () => import("./index15.js");
const CreateTruckTypes = () => import("./index16.js");
const EditTruckTypes = () => import("./index17.js");
const ListPrinters = () => import("./index18.js");
const CreatePrinters = () => import("./index19.js");
const EditPrinters = () => import("./index20.js");
const ListWarehouses = () => import("./index21.js");
const CreateWarehouses = () => import("./index22.js");
const EditWarehouses = () => import("./index23.js");
const ListStorageGrades = () => import("./index24.js");
const CreateStorageGrades = () => import("./index25.js");
const EditStorageGrades = () => import("./index26.js");
const ListBinStatus = () => import("./index27.js");
const CreateBinStatus = () => import("./index28.js");
const EditBinStatus = () => import("./index29.js");
const ListContracts = () => import("./index30.js");
const EditContracts = () => import("./index31.js");
const FilterContract = () => import("./index32.js");
const ListBunkerMaster = () => import("./index33.js");
const CreateBunkerMaster = () => import("./index34.js");
const EditBunkerMaster = () => import("./index35.js");
const ListHarvestStatus = () => import("./index36.js");
const CreateHarvestStatus = () => import("./index37.js");
const EditHarvestStatus = () => import("./index38.js");
const SampleTypes = () => import("./index39.js");
const CreateSampleType = () => import("./index40.js");
const EditSampleType = () => import("./index41.js");
const Deductions = () => import("./index42.js");
const EditDeduction = () => import("./index43.js");
const Operators = () => import("./index44.js");
const CreateOperator = () => import("./index45.js");
const EditOperator = () => import("./index46.js");
const Crops = () => import("./index47.js");
const ListCrops = () => import("./index48.js");
const EditCrops = () => import("./index49.js");
const Intake = () => import("./index50.js");
const ListIntakes = () => import("./index51.js");
const FilterIntake = () => import("./index52.js");
const CreateEditIntake = () => import("./index53.js");
const IntakeSample = () => import("./index55.js");
const ListIntakeSamples = () => import("./index56.js");
const EditIntakeSamples = () => import("./index57.js");
const EditSampleObservations = () => import("./index58.js");
const FilterIntakeSample = () => import("./index59.js");
const ListTrucks = () => import("./index60.js");
const CreateTrucks = () => import("./index61.js");
const EditTrucks = () => import("./index62.js");
const EditTrucksTare = () => import("./index63.js");
const ListAttribute = () => import("./index64.js");
const FilterAttribute = () => import("./index65.js");
const CreateAttribute = () => import("./index66.js");
const EditAttribute = () => import("./index67.js");
const ListGrades = () => import("./index68.js");
const CreateGrades = () => import("./index69.js");
const EditGrades = () => import("./index70.js");
const ListGradeMap = () => import("./index71.js");
const FilterGradeMap = () => import("./index72.js");
const CreateGradeMap = () => import("./index73.js");
const EditGradeMap = () => import("./index74.js");
const PaddockHarvest = () => import("./index75.js");
const ListPaddockHarvest = () => import("./index76.js");
const EditPaddockHarvest = () => import("./index77.js");
const FilterPaddockHarvest = () => import("./index78.js");
const ListBunkerProcessing = () => import("./index79.js");
const FilterBunkerProcessing = () => import("./index80.js");
const BunkerDashboard = () => import("./index81.js");
const UploadIntakes = () => import("./index82.js");
const ListUploadIntake = () => import("./index83.js");
const DetailUploadIntake = () => import("./index84.js");
const ListReverseCorrectIntake = () => import("./index85.js");
const ReverseIntake = () => import("./index86.js");
const CorrectIntake = () => import("./index87.js");
const SampleBins = () => import("./index88.js");
const CreateSampleBin = () => import("./index89.js");
const FilterSampleBin = () => import("./index90.js");
const ListBunkerVbins = () => import("./index91.js");
const FinanceProcessing = () => import("./index92.js");
const BarcodeReprint = () => import("./index93.js");
const OffsiteStorage = () => import("./index94.js");
const WriteoffOffsiteStorage = () => import("./index95.js");
const EditOffsiteStorage = () => import("./index96.js");
const FilterOffsiteStorage = () => import("./index97.js");
const SystemAdministration = () => import("./index98.js");
const EditSystemAdministration = () => import("./index99.js");
const Reports = () => import("./index100.js");
var routes = [
  {
    path: "/watties-grower",
    name: "WattiesGrower",
    component: WattiesGrower,
    redirect: { name: "GrowerReports" },
    children: [
      {
        path: "bunker-master",
        name: "BunkerMaster",
        component: ListBunkerMaster,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_BUNKERMASTER}`,
        children: [
          {
            path: "create",
            name: "CreateBunkerMaster",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_BUNKERMASTER}`,
            component: CreateBunkerMaster
          },
          {
            path: ":id",
            name: "EditBunkerMaster",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_BUNKERMASTER}`,
            component: EditBunkerMaster
          }
        ]
      },
      {
        path: "crop-family",
        name: "CropFamily",
        component: ListCropFamily,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_CROPFAMILY}`,
        children: [
          {
            path: "create",
            name: "CreateCropFamily",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_CROPFAMILY}`,
            component: CreateCropFamily
          },
          {
            path: ":id",
            name: "EditCropFamily",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_CROPFAMILY}`,
            component: EditCropFamily
          }
        ]
      },
      {
        path: "deductions",
        name: "Deductions",
        component: Deductions,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_DEDUCTIONS}`,
        children: [
          {
            path: ":id",
            name: "EditDeduction",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_DEDUCTIONS}`,
            component: EditDeduction
          }
        ]
      },
      {
        path: "cultivars",
        name: "Cultivars",
        component: ListCultivars,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_CULTIVARS}`
      },
      {
        path: "reports",
        name: "GrowerReports",
        component: Reports,
        meta: "reports"
      },
      {
        path: "pricing-crops",
        name: "PricingCrops",
        component: ListPricingCrops,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_PRICINGCROPS}`
      },
      {
        path: "gondola",
        name: "Gondola",
        component: ListGondola,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_GONDOLA}`,
        children: [
          {
            path: "create",
            name: "CreateGondola",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_GONDOLA}`,
            component: CreateGondola
          },
          {
            path: ":id",
            name: "EditGondola",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_GONDOLA}`,
            component: EditGondola
          }
        ]
      },
      {
        path: "gondola-log",
        name: "GondolaLog",
        component: GondolaLog,
        meta: `harvestprocessing;${VIEW_PERMISSION};${GROWERS_GONDOLALOG}`,
        children: [
          {
            path: "create",
            name: "CreateGondolaLog",
            meta: `harvestprocessing;${MAINTAIN_PERMISSION};${GROWERS_GONDOLALOG}`,
            component: CreateGondolaLog
          },
          {
            path: ":id",
            name: "EditGondolaLog",
            meta: `harvestprocessing;${MAINTAIN_PERMISSION};${GROWERS_GONDOLALOG}`,
            component: EditGondolaLog
          }
        ]
      },
      {
        path: "tubs",
        name: "Tubs",
        component: ListTubs,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_TUBS}`,
        children: [
          {
            path: "create",
            name: "CreateTubs",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_TUBS}`,
            component: CreateTubs
          },
          {
            path: ":id",
            name: "EditTubs",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_TUBS}`,
            component: EditTubs
          }
        ]
      },
      {
        path: "truck-types",
        name: "Truck Types",
        component: ListTruckTypes,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_TRUCKTYPES}`,
        children: [
          {
            path: "create",
            name: "CreateTruckTypes",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_TRUCKTYPES}`,
            component: CreateTruckTypes
          },
          {
            path: ":id",
            name: "EditTruckTypes",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_TRUCKTYPES}`,
            component: EditTruckTypes
          }
        ]
      },
      {
        path: "printers",
        name: "Printers",
        component: ListPrinters,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_PRINTERS}`,
        children: [
          {
            path: "create",
            name: "CreatePrinters",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_PRINTERS}`,
            component: CreatePrinters
          },
          {
            path: ":id",
            name: "EditPrinters",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_PRINTERS}`,
            component: EditPrinters
          }
        ]
      },
      {
        path: "ware-houses",
        name: "Warehouses",
        component: ListWarehouses,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_WAREHOURSES}`,
        children: [
          {
            path: "create",
            name: "CreateWarehouses",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_WAREHOURSES}`,
            component: CreateWarehouses
          },
          {
            path: ":id",
            name: "EditWarehouses",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_WAREHOURSES}`,
            component: EditWarehouses
          }
        ]
      },
      {
        path: "storage-grades",
        name: "StorageGrades",
        component: ListStorageGrades,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_STORAGEGRADES}`,
        children: [
          {
            path: "create",
            name: "CreateStorageGrades",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_STORAGEGRADES}`,
            component: CreateStorageGrades
          },
          {
            path: ":id",
            name: "EditStorageGrades",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_STORAGEGRADES}`,
            component: EditStorageGrades
          }
        ]
      },
      {
        path: "bin-status",
        name: "BinStatus",
        component: ListBinStatus,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_BINSTATUS}`,
        children: [
          {
            path: "create",
            name: "CreateBinStatus",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_BINSTATUS}`,
            component: CreateBinStatus
          },
          {
            path: ":id",
            name: "EditBinStatus",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_BINSTATUS}`,
            component: EditBinStatus
          }
        ]
      },
      {
        path: "contracts",
        name: "Contracts",
        component: ListContracts,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_CONTRACTS}`,
        children: [
          {
            path: "advanced-search",
            name: "FilterContract",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_CONTRACTS}`,
            component: FilterContract
          },
          {
            path: ":id",
            name: "EditContracts",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_CONTRACTS}`,
            component: EditContracts
          }
        ]
      },
      {
        path: "harvest-status",
        name: "HarvestStatus",
        component: ListHarvestStatus,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_HARVESTSTATUS}`,
        children: [
          {
            path: "create",
            name: "CreateHarvestStatus",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_HARVESTSTATUS}`,
            component: CreateHarvestStatus
          },
          {
            path: ":id",
            name: "EditHarvestStatus",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_HARVESTSTATUS}`,
            component: EditHarvestStatus
          }
        ]
      },
      {
        path: "sample-types",
        name: "ListSampleTypes",
        component: SampleTypes,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_SAMPLETYPES}`,
        children: [
          {
            path: "create-sample-type",
            name: "CreateSampleType",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_SAMPLETYPES}`,
            component: CreateSampleType
          },
          {
            path: ":id",
            name: "EditSampleType",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_SAMPLETYPES}`,
            component: EditSampleType
          }
        ]
      },
      {
        path: "crops",
        name: "crops",
        component: Crops,
        children: [
          {
            path: "",
            name: "ListCrops",
            meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_CROPS}`,
            component: ListCrops
          },
          {
            path: ":id",
            name: "EditCrops",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_CROPS}`,
            component: EditCrops
          }
        ]
      },
      {
        path: "paddock-harvest",
        name: "paddockharvest",
        component: PaddockHarvest,
        children: [
          {
            path: "",
            name: "ListPaddockHarvest",
            component: ListPaddockHarvest,
            meta: `harvestprocessing;${VIEW_PERMISSION};${GROWERS_PADDOCKHARVESTWEIGHT}`,
            children: [
              {
                path: "advanced-search",
                name: "FilterPaddockHarvest",
                component: FilterPaddockHarvest
              }
            ]
          },
          {
            path: ":id",
            name: "EditPaddockHarvest",
            meta: `harvestprocessing;${MAINTAIN_PERMISSION};${GROWERS_PADDOCKHARVESTWEIGHT}`,
            component: EditPaddockHarvest
          }
        ]
      },
      {
        path: "intakes",
        name: "Intakes",
        component: Intake,
        children: [
          {
            path: "",
            name: "ListIntakes",
            meta: `intakeprocessing;${VIEW_PERMISSION};${GROWERS_INTAKELIST}`,
            component: ListIntakes,
            children: [
              {
                path: "advanced-search",
                name: "FilterIntake",
                component: FilterIntake
              }
            ]
          },
          {
            path: ":id",
            name: "CreateEditIntake",
            meta: `intakeprocessing;${MAINTAIN_PERMISSION};${GROWERS_INTAKELIST}`,
            component: CreateEditIntake,
            props: (route) => ({
              id: route.params.id,
              create: route.query.create == 1
            })
          }
        ]
      },
      {
        path: "upload-intakes",
        name: "UploadIntakes",
        component: UploadIntakes,
        children: [
          {
            path: "",
            name: "ListUploadIntake",
            meta: `intakeprocessing;${VIEW_PERMISSION};${GROWERS_UPLOADINTAKES}`,
            component: ListUploadIntake
          },
          {
            path: ":id",
            name: "DetailUploadIntake",
            meta: `intakeprocessing;${MAINTAIN_PERMISSION};${GROWERS_UPLOADINTAKES}`,
            component: DetailUploadIntake
          }
        ]
      },
      {
        path: "reverse-correct-intakes",
        name: "ReverseCorrectIntakes",
        meta: `intakeprocessing;${VIEW_PERMISSION};${GROWERS_REVERSECORRECT}`,
        component: ListReverseCorrectIntake,
        children: [
          {
            path: "reverse",
            name: "ReverseIntake",
            meta: `intakeprocessing;${MAINTAIN_PERMISSION};${GROWERS_REVERSECORRECT}`,
            component: ReverseIntake
          },
          {
            path: ":id/correct",
            name: "CorrectIntake",
            meta: `intakeprocessing;${MAINTAIN_PERMISSION};${GROWERS_REVERSECORRECT}`,
            component: CorrectIntake
          }
        ]
      },
      {
        path: "sample-bins",
        name: "SampleBins",
        meta: `intakeprocessing;${VIEW_PERMISSION};${GROWERS_SAMPLEBINS}`,
        component: SampleBins,
        children: [
          {
            path: "create-sample-bin",
            name: "CreateSampleBin",
            meta: `intakeprocessing;${MAINTAIN_PERMISSION};${GROWERS_SAMPLEBINS}`,
            component: CreateSampleBin
          },
          {
            path: "advanced-search",
            name: "FilterSampleBin",
            component: FilterSampleBin
          }
        ]
      },
      {
        path: "barcode-reprint",
        name: "BarcodeReprint",
        meta: `intakeprocessing;${VIEW_PERMISSION};${GROWERS_BARCODEREPRINT}`,
        component: BarcodeReprint
      },
      {
        path: "offsite-storage",
        name: "OffsiteStorage",
        meta: `;${VIEW_PERMISSION};${GROWERS_OFFSITESTORAGE}`,
        component: OffsiteStorage,
        children: [
          {
            path: ":id/writeoff/",
            name: "WriteoffOffsiteStorage",
            meta: `;${VIEW_PERMISSION};${GROWERS_OFFSITESTORAGE}`,
            component: WriteoffOffsiteStorage
          },
          {
            path: ":id/edit/",
            name: "EditOffsiteStorage",
            meta: `;${MAINTAIN_PERMISSION};${GROWERS_OFFSITESTORAGE}`,
            component: EditOffsiteStorage
          },
          {
            path: "advanced-search",
            name: "FilterOffsiteStorage",
            component: FilterOffsiteStorage
          }
        ]
      },
      {
        path: "intake-samples",
        name: "sampleprocessing",
        component: IntakeSample,
        children: [
          {
            path: "",
            name: "ListIntakeSamples",
            meta: `;${VIEW_PERMISSION};${GROWERS_SAMPLEPROCESSING}`,
            component: ListIntakeSamples,
            children: [
              {
                path: "advanced-search",
                name: "FilterIntakeSample",
                component: FilterIntakeSample
              }
            ]
          },
          {
            path: ":id",
            name: "EditIntakeSamples",
            meta: `;${VIEW_PERMISSION};${GROWERS_SAMPLEPROCESSING}`,
            component: EditIntakeSamples
          },
          {
            path: ":id/samples/",
            name: "EditSampleObservations",
            meta: `;${VIEW_PERMISSION};${GROWERS_SAMPLEPROCESSING}`,
            component: EditSampleObservations
          }
        ]
      },
      {
        path: "bunker-processing",
        name: "bunkerprocessing",
        component: ListBunkerProcessing,
        meta: `bunkers;${VIEW_PERMISSION};${GROWERS_BUNKERPROCESSING}`,
        children: [
          {
            path: "advanced-search",
            name: "FilterBunkerProcessing",
            component: FilterBunkerProcessing
          }
        ]
      },
      {
        path: "bunker-vbins",
        name: "bunkervbins",
        component: ListBunkerVbins,
        meta: `bunkers;${VIEW_PERMISSION};${GROWERS_VBINSPROCESSING}`
      },
      {
        path: "bunker-dashboard",
        name: "BunkerDashboard",
        component: BunkerDashboard,
        meta: `bunkers;${VIEW_PERMISSION};${GROWERS_BUNKERDASHBOARD}`
      },
      {
        path: "operators",
        name: "ListOperators",
        component: Operators,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_OPERATORS}`,
        children: [
          {
            path: "create-operator",
            name: "CreateOperator",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_OPERATORS}`,
            component: CreateOperator
          },
          {
            path: ":id",
            name: "EditOperator",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_OPERATORS}`,
            component: EditOperator
          }
        ]
      },
      {
        path: "trucks",
        name: "Trucks",
        component: ListTrucks,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_TRUCKS}`,
        children: [
          {
            path: "create",
            name: "CreateTrucks",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_TRUCKS}`,
            component: CreateTrucks
          },
          {
            path: ":id",
            name: "EditTrucks",
            meta: `masterfiles;${EDITTRUCK_PERMISSION};${GROWERS_TRUCKS}`,
            component: EditTrucks
          },
          {
            path: ":id/tare",
            name: "EditTruckTare",
            meta: `masterfiles;${EDITTARE_PERMISSION};${GROWERS_TRUCKS}`,
            component: EditTrucksTare
          }
        ]
      },
      {
        path: "attributes",
        name: "Attribute",
        component: ListAttribute,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_ATTRIBUTES}`,
        children: [
          {
            path: "advanced-search",
            name: "FilterAttribute",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_ATTRIBUTES}`,
            component: FilterAttribute
          },
          {
            path: "create",
            name: "CreateAttribute",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_ATTRIBUTES}`,
            component: CreateAttribute
          },
          {
            path: ":id",
            name: "EditAttribute",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_ATTRIBUTES}`,
            component: EditAttribute
          }
        ]
      },
      {
        path: "grades",
        name: "Grades",
        component: ListGrades,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_GRADES}`,
        children: [
          {
            path: "create",
            name: "CreateGrades",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_GRADES}`,
            component: CreateGrades
          },
          {
            path: ":id",
            name: "EditGrades",
            meta: `masterfiles;${MAINTAIN_PERMISSION};${GROWERS_GRADES}`,
            component: EditGrades
          }
        ]
      },
      {
        path: "grade-maps",
        name: "Grade Map",
        component: ListGradeMap,
        meta: `masterfiles;${VIEW_PERMISSION};${GROWERS_GRADEMAP}`,
        children: [
          {
            path: "advanced-search",
            name: "FilterGradeMap",
            component: FilterGradeMap
          },
          {
            path: "create",
            name: "CreateGradeMap",
            component: CreateGradeMap
          },
          {
            path: ":id",
            name: "EditGradeMap",
            component: EditGradeMap
          }
        ]
      },
      {
        path: "finance-processing",
        name: "FinanceProcessing",
        meta: `;${VIEW_PERMISSION};${GROWERS_FINANCEPROCESSING}`,
        component: FinanceProcessing
      },
      {
        path: "system-administration",
        name: "SystemAdministration",
        meta: `;${VIEW_PERMISSION};${GROWERS_SYSTEMADMINISTRATION}`,
        component: SystemAdministration,
        children: [
          {
            path: ":id",
            name: "EditSystemAdministration",
            meta: `;${MAINTAIN_PERMISSION};${GROWERS_SYSTEMADMINISTRATION}`,
            component: EditSystemAdministration
          }
        ]
      }
    ]
  }
];
const install = (Vue, options = {}) => {
  const { router } = options;
  if (router) {
    router.addRoutes(routes);
  }
};
export { install as default };
