import { VIEW_PERMISSION, CFR_OOS, CFR_ADDATE } from "@kraftheinz/common";
const Cfr = () => import("./Cfr.js");
const FilterStocks = () => import("./index.js");
const EditOOSMaintaince = () => import("./index2.js");
const FilterDeliveryDate = () => import("./index3.js");
const FilterComment = () => import("./index4.js");
const EditCommentMaintenance = () => import("./index5.js");
const AddCommentMaintenance = () => import("./index6.js");
var routes = [
  {
    path: "/cfr",
    name: "Cfr",
    component: Cfr,
    redirect: { name: "CfrOutOfStock" },
    children: [
      {
        path: "oo-out-of-stocks-stagings",
        name: "CfrOutOfStock",
        component: FilterStocks,
        meta: `casefillReporting;${VIEW_PERMISSION};${CFR_OOS}`,
        children: [
          {
            path: ":id",
            name: "EditOOSMaintaince",
            component: EditOOSMaintaince,
            props: (route) => ({
              entityId: +route.params.id,
              country: route.query.country
            })
          }
        ]
      },
      {
        path: "actual-delivery-date",
        name: "ActualDeliveryDate",
        component: FilterDeliveryDate,
        meta: `casefillReporting;${VIEW_PERMISSION};${CFR_ADDATE}`,
        children: []
      },
      {
        path: "cut-reason-comment",
        name: "CutCommentMaintaince",
        component: FilterComment,
        meta: `casefillReporting;${VIEW_PERMISSION};${CFR_OOS}`,
        children: [
          {
            path: "create",
            name: "AddCommentMaintenance",
            component: AddCommentMaintenance
          },
          {
            path: ":id",
            name: "EditCommentMaintenance",
            component: EditCommentMaintenance,
            props: (route) => ({
              itemId: +route.params.id,
              country: route.query.country
            })
          }
        ]
      }
    ]
  }
];
const install = (Vue, options = {}) => {
  const { router } = options;
  if (router) {
    router.addRoutes(routes);
  }
};
export { install as default };
