import { VIEW_PERMISSION, EXO_NOTE_REASON, MAINTAIN_PERMISSION, EXO_TRACKING_STATUS, EXO_MANAGE_OWNER, EXO_DATA, EDIT_PERMISSION, EXO_SUMMARY_REPORT, EXO_STATUS, EXO_SUMMARY_BY_TRACKING_STATUS } from "@kraftheinz/common";
const ExportOrders = () => import("./ExportOrders.js");
const ListNoteReasons = () => import("./index.js");
const CreateNoteReasons = () => import("./index2.js");
const EditNoteReasons = () => import("./index3.js");
const ListTrackingStatus = () => import("./index4.js");
const CreateTrackingStatus = () => import("./index5.js");
const EditTrackingStatus = () => import("./index6.js");
const ListExportOrdersData = () => import("./index7.js");
const ExportOrdersData = () => import("./index9.js");
const FilterAdvanced = () => import("./index10.js");
const DetailsExportOrdersData = () => import("./index11.js");
const EditExportOrdersData = () => import("./index12.js");
const PullData = () => import("./index13.js");
const Owner = () => import("./index14.js");
const ListOwner = () => import("./index15.js");
const CreateOwner = () => import("./index16.js");
const EditOwner = () => import("./index18.js");
const SummaryReport = () => import("./index19.js");
const ListSummaryReport = () => import("./index20.js");
const ExportOrdersStatus = () => import("./index21.js");
const ListExportOrdersStatus = () => import("./index22.js");
const FilterAdvancedStatus = () => import("./index23.js");
const SummaryTrackingStatus = () => import("./index24.js");
var routes = [
  {
    path: "/export-orders",
    name: "ExportOrders",
    component: ExportOrders,
    redirect: {
      name: "ListExportOrdersData"
    },
    children: [
      {
        path: "note-reasons",
        name: "ListNoteReasons",
        component: ListNoteReasons,
        meta: `masterfiles;${VIEW_PERMISSION};${EXO_NOTE_REASON}`,
        children: [
          {
            path: "create",
            name: "CreateNoteReason",
            component: CreateNoteReasons,
            meta: `masterfiles;${MAINTAIN_PERMISSION};${EXO_NOTE_REASON}`
          },
          {
            path: ":id",
            name: "EditNoteReason",
            component: EditNoteReasons,
            meta: `masterfiles;${MAINTAIN_PERMISSION};${EXO_NOTE_REASON}`
          }
        ]
      },
      {
        path: "tracking-status",
        name: "TrackingStatus",
        component: ListTrackingStatus,
        meta: `masterfiles;${VIEW_PERMISSION};${EXO_TRACKING_STATUS}`,
        children: [
          {
            path: "create",
            name: "CreateTrackingStatus",
            component: CreateTrackingStatus,
            meta: `masterfiles;${MAINTAIN_PERMISSION};${EXO_TRACKING_STATUS}`
          },
          {
            path: ":id",
            name: "EditTrackingStatus",
            component: EditTrackingStatus,
            meta: `masterfiles;${MAINTAIN_PERMISSION};${EXO_TRACKING_STATUS}`
          }
        ]
      },
      {
        path: "owner",
        component: Owner,
        name: "Owner",
        meta: `masterfiles;${VIEW_PERMISSION};${EXO_MANAGE_OWNER}`,
        children: [
          {
            path: "",
            name: "ListOwner",
            component: ListOwner,
            meta: `masterfiles;${VIEW_PERMISSION};${EXO_MANAGE_OWNER}`,
            children: [
              {
                path: "create",
                name: "CreateOwner",
                component: CreateOwner,
                meta: `masterfiles;${MAINTAIN_PERMISSION};${EXO_MANAGE_OWNER}`
              },
              {
                path: ":id",
                name: "EditOwner",
                component: EditOwner,
                meta: `masterfiles;${MAINTAIN_PERMISSION};${EXO_MANAGE_OWNER}`
              }
            ]
          }
        ]
      },
      {
        path: "export-orders-data",
        name: "ExportOrdersData",
        component: ExportOrdersData,
        meta: `exportorderdata;${VIEW_PERMISSION};${EXO_DATA}`,
        children: [
          {
            path: "",
            name: "ListExportOrdersData",
            component: ListExportOrdersData,
            meta: `exportorderdata;${VIEW_PERMISSION};${EXO_DATA}`,
            children: [
              {
                path: "advanced-search",
                name: "FilterAdvanced",
                component: FilterAdvanced,
                meta: `exportorderdata;${VIEW_PERMISSION};${EXO_DATA}`
              },
              {
                path: "pull-data",
                name: "PullData",
                component: PullData,
                meta: `exportorderdata;${VIEW_PERMISSION};${EXO_DATA}`
              },
              {
                path: ":id",
                name: "EditExportOrdersData",
                component: EditExportOrdersData,
                meta: `exportorderdata;${EDIT_PERMISSION};${EXO_DATA}`
              }
            ]
          },
          {
            path: "detail/:id",
            name: "DetailsExportOrdersData",
            component: DetailsExportOrdersData,
            meta: `exportorderdata;${VIEW_PERMISSION};${EXO_DATA}`
          }
        ]
      },
      {
        path: "summary-report",
        component: SummaryReport,
        name: "SummaryReport",
        meta: `exportorderdata;${VIEW_PERMISSION};${EXO_SUMMARY_REPORT}`,
        children: [
          {
            path: "",
            name: "ListSummaryReport",
            component: ListSummaryReport,
            meta: `exportorderdata;${VIEW_PERMISSION};${EXO_SUMMARY_REPORT}`
          }
        ]
      },
      {
        path: "export-orders-status",
        name: "ExportOrdersStatus",
        component: ExportOrdersStatus,
        meta: `exportorderdata;${VIEW_PERMISSION};${EXO_STATUS}`,
        children: [
          {
            path: "",
            name: "ListExportOrdersStatus",
            component: ListExportOrdersStatus,
            meta: `exportorderdata;${VIEW_PERMISSION};${EXO_STATUS}`,
            children: [
              {
                path: "advanced-search",
                name: "FilterAdvancedStatus",
                component: FilterAdvancedStatus,
                meta: `exportorderdata;${VIEW_PERMISSION};${EXO_STATUS}`
              }
            ]
          }
        ]
      },
      {
        path: "summary-tracking-status",
        name: "SummaryTrackingStatus",
        component: SummaryTrackingStatus,
        meta: `exportorderdata;${VIEW_PERMISSION};${EXO_SUMMARY_BY_TRACKING_STATUS}`
      }
    ]
  }
];
const install = (Vue, options = {}) => {
  const { router } = options;
  if (router) {
    router.addRoutes(routes);
  }
};
export { install as default };
