const VIEW_PERMISSION = "view";
const JidoSeisei = () => import("./JidoSeisei.js");
const ReportDetail = () => import("./index.js");
const ReportFolders = () => import("./index2.js");
const PaymentStatementCreation = () => import("./index3.js");
var routes = [
  {
    path: "/jido-seisei",
    name: "JidoSeisei",
    component: JidoSeisei,
    redirect: { name: "PaymentStatementCreation" },
    children: [
      {
        path: "payment-statement-creation",
        name: "PaymentStatementCreation",
        component: PaymentStatementCreation,
        redirect: { name: "ReportFolders" },
        meta: `;${VIEW_PERMISSION}`,
        children: [
          {
            path: "report",
            name: "ReportFolders",
            component: ReportFolders,
            meta: `;${VIEW_PERMISSION}`
          },
          {
            path: "detail-report/:id/dafiles/:saleName",
            name: "ReportDetail",
            component: ReportDetail,
            meta: `;${VIEW_PERMISSION}`
          }
        ]
      }
    ]
  }
];
const TableReport = () => import("./TableReport.js");
const PDFReview = () => import("./PDFReview.js");
const SendAllModal = () => import("./SendAllModal.js");
const SendSingleFileModal = () => import("./SendSingleFileModal.js");
var MyMixin = {
  methods: {
    hello() {
      return "hello from mixin!";
    }
  }
};
const OBJ_MAIL_STATUS_COLOR = {
  sent: { type: "sent", colorCode: "#0062FF", icon: null },
  failed: { type: "failed", colorCode: "#F5222D", icon: "warning" },
  noEfax: { type: "no-efax", colorCode: "#FA8C16", icon: "mail" }
};
const OBJ_APPROVED_STATUS_COLORS = {
  approved: { type: "approved", colorCode: "#15B982", icon: "check-circle" },
  notApproved: { type: "notApproved", colorCode: "#92A0B0", icon: "minus-circle" }
};
const OBJ_TABLE_STATUS_COLORS = {
  folder: {
    type: "folder",
    colorCode: "#92A0B0",
    icon: "folder"
  },
  file: {
    type: "file",
    colorCode: "#15B982",
    icon: "file-text"
  }
};
const excelType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const pdfType = "application/pdf";
const zipType = "application/octet-stream";
const apiUrl = "#{VUE_APP_API_URL}#";
const exportFileUrl = `${apiUrl}/jidoseisei/filestorages/download`;
var JidoUtils = {
  methods: {
    async handleDownload(typeOfFile, params, customFileName = "") {
      let filterString = [];
      Object.keys(params).map((filter) => filterString.push(`(${filter} ${params[filter]["operator"]} \`${params[filter]["value"]}\`)`));
      filterString = `fe=(${filterString.join("and")})`;
      const res = await this.axios.get(`${exportFileUrl}/${typeOfFile}?${filterString}`, {
        responseType: "blob"
      });
      if (res && res.data) {
        let typeFile = res.headers.filename.split(".");
        typeFile = typeFile[typeFile.length - 1];
        const fileName = customFileName ? `${customFileName}.${typeFile}` : res.headers.filename;
        this.downloadClassification(typeOfFile, fileName, res.data);
      }
    },
    downloadClassification(typeOfFile, fileName, data) {
      switch (typeOfFile) {
        case "excel":
          this.downloadFile(excelType, fileName, data);
          break;
        case "pdf":
          this.downloadFile(pdfType, fileName, data);
          break;
        case "both":
          this.downloadFile(zipType, fileName, data);
          break;
      }
    },
    downloadFile(typeOfFile, fileName, data) {
      let hiddenElement = document.createElement("a");
      const blob = new Blob([data], {
        type: typeOfFile
      });
      hiddenElement.href = URL.createObjectURL(blob);
      hiddenElement.target = "_blank";
      hiddenElement.download = fileName;
      hiddenElement.click();
    }
  }
};
const install = (Vue, options = {}) => {
  const { router } = options;
  if (router) {
    router.addRoutes(routes);
  }
};
export { JidoUtils, MyMixin, OBJ_APPROVED_STATUS_COLORS, OBJ_MAIL_STATUS_COLOR, OBJ_TABLE_STATUS_COLORS, PDFReview, SendAllModal, SendSingleFileModal, TableReport, install as default };
