var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.onSubmit}},[_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{attrs:{"md":24,"lg":6}},[_c('h5',{staticClass:"h6"},[_vm._v("User Settings")])]),_c('a-col',{attrs:{"md":24,"lg":18}},[_c('div',{staticClass:"card p-4"},[_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{staticClass:"mb-3",attrs:{"xs":12}},[_c('a-form-item',{attrs:{"label":"Username/LAN ID"}},[_c('a-auto-complete',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'userName',
                  {
                    rules: [
                      { required: true, message: _vm.errorMsg.userNameRequired },
                      {
                        pattern: /^[a-zA-Z0-9-._@+]*$/,
                        message: _vm.errorMsg.userNameInvalid,
                      },
                      {
                        max: 255,
                        message: _vm.errorMsg.userNameTooLong,
                      } ],
                    initialValue: _vm.entity.userName,
                  } ]),expression:"[\n                  'userName',\n                  {\n                    rules: [\n                      { required: true, message: errorMsg.userNameRequired },\n                      {\n                        pattern: /^[a-zA-Z0-9-._@+]*$/,\n                        message: errorMsg.userNameInvalid,\n                      },\n                      {\n                        max: 255,\n                        message: errorMsg.userNameTooLong,\n                      },\n                    ],\n                    initialValue: entity.userName,\n                  },\n                ]"}],attrs:{"disabled":!_vm.isCreatingNewEmployee,"option-label-prop":"title"},on:{"select":_vm.onSelect,"search":_vm.onSearch},scopedSlots:_vm._u([{key:"dataSource",fn:function(){return [(_vm.dataSource.length > 0)?_c('a-select-option',{key:"label"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"width":"50%"}},[_vm._v("Account Name")]),_c('div',{staticStyle:{"width":"50%"}},[_vm._v("Email")])])]):_vm._e(),_vm._l((_vm.dataSource),function(option){return _c('a-select-option',{key:option.samAccountName,attrs:{"value":option.samAccountName,"title":option.samAccountName}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-ellipsis",staticStyle:{"width":"50%"}},[_vm._v(_vm._s(option.samAccountName))]),_c('div',{staticClass:"text-ellipsis",staticStyle:{"width":"50%"}},[_vm._v(_vm._s(option.email))])])])})]},proxy:true}])})],1)],1),_c('a-col',{staticClass:"mb-3",attrs:{"xs":12}},[_c('a-form-item',{attrs:{"label":"Job Title"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['jobTitle', { initialValue: _vm.entity.jobTitle }]),expression:"['jobTitle', { initialValue: entity.jobTitle }]"}],attrs:{"disabled":!_vm.can(_vm.MAINTAIN_PERMISSION)},on:{"change":function($event){return _vm.storeValue(
                    $event.target.value,
                    'jobTitle',
                    _vm.convertFirstLetterToUppercase
                  )}}})],1)],1),_c('a-col',{staticClass:"mb-3",attrs:{"xs":12}},[_c('a-form-item',{attrs:{"label":"First Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'firstName',
                  {
                    rules: [
                      { required: true, message: _vm.errorMsg.firstNameRequired } ],
                    initialValue: _vm.entity.firstName,
                  } ]),expression:"[\n                  'firstName',\n                  {\n                    rules: [\n                      { required: true, message: errorMsg.firstNameRequired },\n                    ],\n                    initialValue: entity.firstName,\n                  },\n                ]"}],attrs:{"disabled":!_vm.can(_vm.MAINTAIN_PERMISSION)},on:{"change":function($event){return _vm.storeValue(
                    $event.target.value,
                    'firstName',
                    _vm.convertFirstLetterToUppercase
                  )}}})],1)],1),_c('a-col',{staticClass:"mb-3",attrs:{"xs":12}},[_c('a-form-item',{attrs:{"label":"Last Name"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'lastName',
                  {
                    rules: [
                      { required: true, message: _vm.errorMsg.lastNameRequired } ],
                    initialValue: _vm.entity.lastName,
                  } ]),expression:"[\n                  'lastName',\n                  {\n                    rules: [\n                      { required: true, message: errorMsg.lastNameRequired },\n                    ],\n                    initialValue: entity.lastName,\n                  },\n                ]"}],attrs:{"disabled":!_vm.can(_vm.MAINTAIN_PERMISSION)},on:{"change":function($event){return _vm.storeValue(
                    $event.target.value,
                    'lastName',
                    _vm.convertFirstLetterToUppercase
                  )}}})],1)],1)],1),_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{attrs:{"xs":12}},[_c('a-form-item',{attrs:{"label":"Phone"}},[_c('a-input',{attrs:{"disabled":!_vm.can(_vm.MAINTAIN_PERMISSION),"value":_vm.entity.phoneNumber},on:{"change":function($event){return _vm.storeValue($event.target.value, 'phoneNumber')}}})],1)],1),_c('a-col',{attrs:{"xs":12}},[_c('a-form-item',{attrs:{"label":"Email"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'email',
                  {
                    rules: [
                      {
                        type: 'email',
                        message: _vm.errorMsg.emailInvalid,
                      },
                      { required: true, message: _vm.errorMsg.emailRequired } ],
                    initialValue: _vm.entity.email,
                  } ]),expression:"[\n                  'email',\n                  {\n                    rules: [\n                      {\n                        type: 'email',\n                        message: errorMsg.emailInvalid,\n                      },\n                      { required: true, message: errorMsg.emailRequired },\n                    ],\n                    initialValue: entity.email,\n                  },\n                ]"}],attrs:{"disabled":!_vm.can(_vm.MAINTAIN_PERMISSION)},on:{"change":function($event){return _vm.storeValue($event.target.value, 'email')}}})],1)],1)],1)],1)])],1),_c('a-divider'),(_vm.can(_vm.MAINTAIN_PERMISSION))?_c('a-row',{attrs:{"gutter":0,"justify":"end","type":"flex"}},[_c('a-button',{attrs:{"loading":_vm.isRefreshing || _vm.isSubmitting,"icon":"reload"},on:{"click":function($event){return _vm.onLookUp(_vm.entity.userName, true)}}},[_vm._v("Sync from AD")]),_c('a-button',{staticClass:"ml-2",on:{"click":_vm.onBack}},[_vm._v("Cancel")]),(!_vm.isCreatingNewEmployee)?_c('a-button',{staticClass:"ml-2",attrs:{"ghost":"","loading":_vm.statusUpdating,"type":_vm.entity.isActive ? 'danger' : 'primary'},on:{"click":function($event){return _vm.toggleUserStatus(_vm.entity)}}},[_vm._v(_vm._s(_vm.entity.isActive ? "Deactivate" : "Activate"))]):_vm._e(),_c('a-button',{staticClass:"ml-2",attrs:{"loading":_vm.isSubmitting,"type":"primary","html-type":"submit"}},[_vm._v("Save")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }