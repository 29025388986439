import { Spin } from "ant-design-vue";
import Vue from "vue";
import InfiniteLoading from "vue-infinite-loading";

Vue.use(InfiniteLoading, {
  slots: {
    spinner: Spin,
    noMore: "",
  },
});
