var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import _ from "lodash";
import { FILTERS_STARTS_WITH, MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_BRITESTACK_TRIAL, FILTERS_EQUAL, VIEW_PERMISSION, STERILIZATION_SPECIFICATIONS_COOKERCAPACITIES, STERILIZATION_SPECIFICATIONS_FUNCTIONS, STERILIZATION_SPECIFICATIONS_STACKINGPATTERN, STERILIZATION_SPECIFICATIONS_CONTAINERSIZES, STERILIZATION_SPECIFICATIONS_FILLTYPES, STERILIZATION_SPECIFICATIONS_HYDROSECTIONTIMES, STERILIZATION_SPECIFICATIONS_DATA_ENTRY, STERILIZATION_SPECIFICATIONS_REPORTS, STERILIZATION_SPECIFICATIONS_BRITESTACK, STERILIZATION_SPECIFICATIONS_AUDITLOGS } from "@kraftheinz/common";
var render$2 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "item-code-input" }, [_vm.formItem ? _c("validation-provider", { attrs: { "name": _vm.label, "rules": _vm.rules }, scopedSlots: _vm._u([{ key: "default", fn: function(slotProps) {
    return [_c("a-form-item", { attrs: { "colon": false, "has-feedback": "", "help": slotProps.errors[0], "label": _vm.label, "validate-status": _vm.resolveState(slotProps) } }, [_c("a-auto-complete", { attrs: { "option-label-prop": "title", "placeholder": "Type to start searching" }, on: { "select": _vm.onSelect, "search": _vm.onSearch, "change": _vm.onChange }, scopedSlots: _vm._u([{ key: "dataSource", fn: function() {
      return [_vm.dataSource.length > 0 ? _c("a-select-option", { key: "label" }, [_c("div", { staticClass: "d-flex" }, [_c("div", { staticStyle: { "width": "20%" } }, [_vm._v("Item Code")]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v("Proc Code")]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v("FG Item Code")]), _c("div", { staticStyle: { "width": "30%" } }, [_vm._v("Description")])])]) : _vm._e(), _vm._l(_vm.dataSource, function(option) {
        return _c("a-select-option", { key: option.id, attrs: { "title": option[_vm.searchByLowerCase] } }, [_c("div", { staticClass: "d-flex" }, [_c("div", { staticStyle: { "width": "20%" } }, [_vm._v(_vm._s(option.itemCode))]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v(_vm._s(option.procCode))]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v(_vm._s(option.fgItemCode))]), _c("div", { staticStyle: { "width": "30%" } }, [_vm._v(_vm._s(option.description))])])]);
      }), _vm.dataSource.length && !_vm.isComplete ? _c("a-select-option", { key: "infinite" }, [_c("infinite-loading", { on: { "infinite": _vm.onInfinite } })], 1) : _vm._e()];
    }, proxy: true }], null, true), model: { value: _vm.inputValue, callback: function($$v) {
      _vm.inputValue = $$v;
    }, expression: "inputValue" } })], 1)];
  } }], null, false, 4237791082) }) : [_vm.label ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c("a-auto-complete", { attrs: { "placeholder": _vm.placeholder }, on: { "select": _vm.onSelect, "search": _vm.onSearch, "change": _vm.onChange }, model: { value: _vm.inputValue, callback: function($$v) {
    _vm.inputValue = $$v;
  }, expression: "inputValue" } }, [_c("template", { slot: "dataSource" }, [_vm.dataSource.length > 0 ? _c("a-select-option", { key: "label" }, [_c("div", { staticClass: "d-flex" }, [_c("div", { staticStyle: { "width": "20%" } }, [_vm._v("Item Code")]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v("Proc Code")]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v("FG Item Code")]), _c("div", { staticStyle: { "width": "30%" } }, [_vm._v("Description")])])]) : _vm._e(), _vm._l(_vm.dataSource, function(option) {
    return _c("a-select-option", { key: option.id }, [_c("div", { staticClass: "d-flex" }, [_c("div", { staticStyle: { "width": "20%" } }, [_vm._v(_vm._s(option.itemCode))]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v(_vm._s(option.procCode))]), _c("div", { staticStyle: { "width": "25%" } }, [_vm._v(_vm._s(option.fgItemCode))]), _c("div", { staticStyle: { "width": "30%" } }, [_vm._v(_vm._s(option.description))])])]);
  }), _vm.dataSource.length && !_vm.isComplete ? _c("a-select-option", { key: "infinite" }, [_c("infinite-loading", { on: { "infinite": _vm.onInfinite } })], 1) : _vm._e()], 2)], 2)]], 2);
};
var staticRenderFns$2 = [];
var ItemCodeInput_vue_vue_type_style_index_0_scoped_true_lang = "";
function normalizeComponent(scriptExports, render2, staticRenderFns2, functionalTemplate, injectStyles, scopeId, moduleIdentifier, shadowMode) {
  var options = typeof scriptExports === "function" ? scriptExports.options : scriptExports;
  if (render2) {
    options.render = render2;
    options.staticRenderFns = staticRenderFns2;
    options._compiled = true;
  }
  if (functionalTemplate) {
    options.functional = true;
  }
  if (scopeId) {
    options._scopeId = "data-v-" + scopeId;
  }
  var hook;
  if (moduleIdentifier) {
    hook = function(context) {
      context = context || this.$vnode && this.$vnode.ssrContext || this.parent && this.parent.$vnode && this.parent.$vnode.ssrContext;
      if (!context && typeof __VUE_SSR_CONTEXT__ !== "undefined") {
        context = __VUE_SSR_CONTEXT__;
      }
      if (injectStyles) {
        injectStyles.call(this, context);
      }
      if (context && context._registeredComponents) {
        context._registeredComponents.add(moduleIdentifier);
      }
    };
    options._ssrRegister = hook;
  } else if (injectStyles) {
    hook = shadowMode ? function() {
      injectStyles.call(this, (options.functional ? this.parent : this).$root.$options.shadowRoot);
    } : injectStyles;
  }
  if (hook) {
    if (options.functional) {
      options._injectStyles = hook;
      var originalRender = options.render;
      options.render = function renderWithStyleInjection(h, context) {
        hook.call(context);
        return originalRender(h, context);
      };
    } else {
      var existing = options.beforeCreate;
      options.beforeCreate = existing ? [].concat(existing, hook) : [hook];
    }
  }
  return {
    exports: scriptExports,
    options
  };
}
const apiUrl$1 = "#{VUE_APP_API_URL}#";
const __vue2_script$2 = {
  name: "ItemCodeInput",
  inject: ["can"],
  props: {
    filterType: {
      type: String,
      default: FILTERS_STARTS_WITH
    },
    formItem: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    reference: {
      type: String,
      default: null
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    searchBy: {
      type: String,
      default: ""
    },
    value: {
      type: [Number, String, Array],
      default: void 0
    }
  },
  data() {
    return {
      dataSource: [],
      inputValue: this.value,
      isComplete: false,
      page: 1
    };
  },
  computed: {
    searchByLowerCase() {
      const searchBy = this.searchBy.split("");
      searchBy[0] = searchBy[0].toLowerCase();
      return searchBy.join("");
    },
    query() {
      let abcCode;
      if (this.$can(MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_BRITESTACK_TRIAL)) {
        abcCode = `and(ABCCode ${FILTERS_EQUAL} \`T\`)`;
      }
      if (this.can(MAINTAIN_PERMISSION)) {
        abcCode = "";
      }
      return {
        fe: `((${this.searchBy} ${this.filterType} \`${this.inputValue}\`)${abcCode})`,
        isBpcsOnly: true,
        page: this.page
      };
    }
  },
  watch: {
    value(newVal) {
      this.inputValue = newVal;
    }
  },
  methods: {
    fetchList: _.debounce(async function() {
      const res = await this.axios.get(`${apiUrl$1}/sterilization/common/item-masters`, {
        params: this.query
      });
      this.dataSource = res.data.results.map((item, index) => __spreadProps(__spreadValues({}, item), {
        id: index.toString()
      }));
    }, 1e3),
    handleEmitValue(value) {
      const obj = this.dataSource.find((item) => item.id == value);
      if (!obj)
        return;
      this.$emit("change", obj[this.searchByLowerCase]);
    },
    resolveState({ errors }) {
      if (errors[0])
        return "error";
      return "";
    },
    onChange: _.debounce(function(value) {
      this.handleEmitValue(value);
    }, 1e3),
    async onInfinite($state) {
      this.page += 1;
      const res = await this.axios.get(`${apiUrl$1}/sterilization/common/item-masters`, {
        params: this.query
      });
      if (res.data.results.length) {
        const lastIndex = this.dataSource.length;
        const dataSource = res.data.results.map((item, index) => __spreadProps(__spreadValues({}, item), {
          id: (lastIndex + index).toString()
        }));
        this.dataSource = [...this.dataSource, ...dataSource];
        $state.loaded();
      } else {
        $state.complete();
        this.isComplete = true;
      }
    },
    onSearch(value) {
      if (this.searchBy.length == 0)
        return;
      this.page = 1;
      this.fetchList(this.page);
      this.$emit("change", value);
    },
    onSelect(value) {
      this.handleEmitValue(value);
    }
  }
};
const __cssModules$2 = {};
var __component__$2 = /* @__PURE__ */ normalizeComponent(__vue2_script$2, render$2, staticRenderFns$2, false, __vue2_injectStyles$2, "d9d8876c", null, null);
function __vue2_injectStyles$2(context) {
  for (let o in __cssModules$2) {
    this[o] = __cssModules$2[o];
  }
}
var ItemCodeInput = /* @__PURE__ */ function() {
  return __component__$2.exports;
}();
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "stacking-pattern-upload" }, [_vm.title ? _c("p", { staticClass: "mb-0" }, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _c("div", { staticClass: "d-flex flex-row card-container" }, _vm._l(_vm.arrUpload, function(val) {
    return _c("a-card", { key: val.key, staticClass: "upload-container mt-3", style: { width: _vm.styleInput.width, height: _vm.styleInput.height }, attrs: { "title": val.text, "bordered": true } }, [_c("a-row", { style: { position: "relative", height: "100%" }, attrs: { "align": "middle", "type": "flex" } }, [_c("upload-input", { attrs: { "action": _vm.actionUrl, "form-item": "", "input-image-url": _vm.value[val.key] ? _vm.apiUrl + _vm.value[val.key] : null, "label": val.text, "rules": val.required ? "required" : "", "type": val.key, "disabled": val.disabled }, on: { "change": _vm.emitImg } })], 1)], 1);
  }), 1)]);
};
var staticRenderFns$1 = [];
var StackingPatternUpload_vue_vue_type_style_index_0_scoped_true_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script$1 = {
  name: "StackingPatternUpload",
  props: {
    arrUpload: {
      type: Array,
      default: () => [{}]
    },
    title: {
      type: String,
      default: null
    },
    styleInput: {
      type: Object,
      default: () => ({
        width: "auto",
        height: "auto"
      })
    },
    value: {
      type: Object,
      default: () => ({
        picFrontDomain: apiUrl,
        picBackDomain: apiUrl,
        picFrontPath: "",
        picBackPath: ""
      })
    }
  },
  data() {
    return {
      apiUrl,
      stackingVal: __spreadProps(__spreadValues({}, this.value), {
        picFrontDomain: apiUrl,
        picBackDomain: apiUrl
      }),
      actionUrl: apiUrl + "/files/images"
    };
  },
  methods: {
    emitImg(newVal) {
      this.stackingVal = __spreadValues(__spreadValues({}, this.stackingVal), newVal);
      this.$emit("change", this.stackingVal);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "08ff054b", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var StackingPatternUpload = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "method-notes" }, [_c("a-tabs", { attrs: { "default-active-key": "p1note" } }, [_c("a-tab-pane", { key: "p1note", attrs: { "tab": "PAGE 1 NOTES" } }, [_c("text-input", { attrs: { "disabled": _vm.page1Disabled, "form-item": "", "label": "Operator Values", "max-length": 500, "value": _vm.inputValue && _vm.inputValue.operatorValues, "span": 24 }, on: { "change": function($event) {
    return _vm.onChange("operatorValues", $event);
  } } }), _c("text-input", { attrs: { "disabled": _vm.page1Disabled, "form-item": "", "label": "Process Information", "value": _vm.inputValue && _vm.inputValue.processInfo, "span": 24 }, on: { "change": function($event) {
    return _vm.onChange("processInfo", $event);
  } } }), _c("text-area", { attrs: { "disabled": _vm.page1Disabled, "form-item": "", "label": "Notes", "span": 24, "value": _vm.inputValue && _vm.inputValue.notes }, on: { "change": function($event) {
    return _vm.onChange("notes", $event);
  } } })], 1), _c("a-tab-pane", { key: "p2note", attrs: { "tab": "PAGE 2 NOTES" } }, [_c("text-input", { attrs: { "disabled": _vm.page2Disabled, "form-item": "", "label": "Heading", "span": 24, "value": _vm.inputValue && _vm.inputValue.p2NoteHeading }, on: { "change": function($event) {
    return _vm.onChange("p2NoteHeading", $event);
  } } }), _c("text-input", { attrs: { "disabled": _vm.page2Disabled, "form-item": "", "label": "Note Text", "span": 24, "value": _vm.inputValue && _vm.inputValue.p2NoteText }, on: { "change": function($event) {
    return _vm.onChange("p2NoteText", $event);
  } } }), _c("text-area", { attrs: { "disabled": _vm.page2Disabled, "form-item": "", "label": "Footer", "span": 24, "value": _vm.inputValue && _vm.inputValue.p2NoteFooter }, on: { "change": function($event) {
    return _vm.onChange("p2NoteFooter", $event);
  } } })], 1)], 1)], 1);
};
var staticRenderFns = [];
var MethodNotesInput_vue_vue_type_style_index_0_scoped_true_lang = "";
const __vue2_script = {
  name: "MethodNotesInput",
  props: {
    page1Disabled: {
      type: Boolean,
      default: false
    },
    page2Disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      inputValue: this.value
    };
  },
  watch: {
    value(newVal) {
      this.inputValue = newVal;
    }
  },
  methods: {
    onChange(key, value) {
      this.inputValue = __spreadProps(__spreadValues({}, this.inputValue), {
        [key]: value
      });
      this.$emit("change", this.inputValue);
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, "5b362bc4", null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var MethodNotesInput = /* @__PURE__ */ function() {
  return __component__.exports;
}();
var components = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  [Symbol.toStringTag]: "Module",
  ItemCodeInput,
  StackingPatternUpload,
  MethodNotesInput
});
const SterilizationSpecifications = () => import("./SterilizationSpecifications.js");
const ListCookerCapacities = () => import("./index2.js");
const FilterCookerCapacity = () => import("./index3.js");
const CreateCookerCapacity = () => import("./index4.js");
const EditCookerCapacity = () => import("./index5.js");
const ListFunctions = () => import("./index6.js");
const CreateFunctions = () => import("./index7.js");
const EditFunctions = () => import("./index8.js");
const ListStackingPattern = () => import("./index9.js");
const CreateStackingPattern = () => import("./index10.js");
const EditStackingPattern = () => import("./index11.js");
const ListContainerSizes = () => import("./index12.js");
const CreateContainerSizes = () => import("./index13.js");
const EditContainerSizes = () => import("./index14.js");
const ListFillTypes = () => import("./index15.js");
const CreateFillTypes = () => import("./index16.js");
const EditFillTypes = () => import("./index17.js");
const ListCookerFlights = () => import("./index18.js");
const CreateCookerFlight = () => import("./index19.js");
const EditCookerFlight = () => import("./index20.js");
const ListDataEntry = () => import("./index21.js");
const FilterDataEntry = () => import("./index22.js");
const CreateDataEntry = () => import("./index23.js");
const EditDataEntry = () => import("./index24.js");
const DataEntry = () => import("./index25.js");
const AttachBritestackModal = () => import("./index26.js");
const EditGTCPasteuriser = () => import("./index27.js");
const ReportsMenu = () => import("./index28.js");
const Sterilisation = () => import("./index29.js");
const ReportsPrintedLog = () => import("./index30.js");
const SterilisationProcesses = () => import("./index31.js");
const Reports = () => import("./index32.js");
const Britestack = () => import("./index33.js");
const ListBritestacks = () => import("./index34.js");
const CreateBritestack = () => import("./index35.js");
const EditBritestack = () => import("./index36.js");
const FilterBritestack = () => import("./index37.js");
const ViewFinishedProducts = () => import("./index38.js");
const ListAuditLog = () => import("./index39.js");
const DetailAuditLog = () => import("./index40.js");
const EditCooling = () => import("./index41.js");
var routes = [
  {
    path: "/sterilization-specifications",
    name: "SterilizationSpecifications",
    component: SterilizationSpecifications,
    redirect: { name: "Reports" },
    children: [
      {
        path: "cooker-capacities",
        name: "CookerCapacities",
        component: ListCookerCapacities,
        meta: `masterfiles;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_COOKERCAPACITIES}`,
        children: [
          {
            path: "advanced-search",
            name: "FilterCookerCapacity",
            component: FilterCookerCapacity,
            meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_COOKERCAPACITIES}`
          },
          {
            path: "create",
            name: "CreateCookerCapacity",
            component: CreateCookerCapacity,
            meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_COOKERCAPACITIES}`
          },
          {
            path: ":id",
            name: "EditCookerCapacity",
            component: EditCookerCapacity,
            meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_COOKERCAPACITIES}`
          }
        ]
      },
      {
        path: "functions",
        name: "Functions",
        component: ListFunctions,
        meta: `masterfiles;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_FUNCTIONS}`,
        children: [
          {
            path: "create",
            name: "CreateFunctions",
            component: CreateFunctions,
            meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_FUNCTIONS}`
          },
          {
            path: ":id",
            name: "EditFunctions",
            component: EditFunctions,
            meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_FUNCTIONS}`
          }
        ]
      },
      {
        path: "stacking-pattern",
        name: "StackingPattern",
        component: ListStackingPattern,
        meta: `masterfiles;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_STACKINGPATTERN}`,
        children: [
          {
            path: "create",
            name: "CreateStackingPattern",
            component: CreateStackingPattern,
            meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_STACKINGPATTERN}`
          },
          {
            path: ":id",
            name: "EditStackingPattern",
            component: EditStackingPattern,
            meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_STACKINGPATTERN}`
          }
        ]
      },
      {
        path: "container-sizes",
        name: "ContainerSizes",
        component: ListContainerSizes,
        meta: `masterfiles;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_CONTAINERSIZES}`,
        children: [
          {
            path: "create",
            name: "CreateContainerSizes",
            component: CreateContainerSizes,
            meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_CONTAINERSIZES}`
          },
          {
            path: ":id",
            name: "EditContainerSizes",
            component: EditContainerSizes,
            meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_CONTAINERSIZES}`
          }
        ]
      },
      {
        path: "fill-types",
        name: "FillTypes",
        component: ListFillTypes,
        meta: `masterfiles;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_FILLTYPES}`,
        children: [
          {
            path: "create",
            name: "CreateFillTypes",
            component: CreateFillTypes,
            meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_FILLTYPES}`
          },
          {
            path: ":id",
            name: "EditFillTypes",
            component: EditFillTypes,
            meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_FILLTYPES}`
          }
        ]
      },
      {
        path: "hydro-section-times",
        name: "CookerFlights",
        component: ListCookerFlights,
        meta: `masterfiles;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_HYDROSECTIONTIMES}`,
        children: [
          {
            path: "create",
            name: "CreateCookerFlight",
            component: CreateCookerFlight,
            meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_HYDROSECTIONTIMES}`
          },
          {
            path: ":id",
            name: "EditCookerFlight",
            component: EditCookerFlight,
            meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_HYDROSECTIONTIMES}`
          }
        ]
      },
      {
        path: "data-entries",
        component: DataEntry,
        children: [
          {
            path: "",
            name: "ListDataEntry",
            component: ListDataEntry,
            meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_DATA_ENTRY}`,
            children: [
              {
                path: "advanced-search",
                name: "FilterDataEntry",
                component: FilterDataEntry,
                meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_DATA_ENTRY}`,
                props: {
                  redirectRoute: "/sterilization-specifications/data-entries"
                }
              },
              {
                path: "create-data-entry",
                name: "CreateDataEntry",
                component: CreateDataEntry,
                meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_DATA_ENTRY}`
              }
            ]
          },
          {
            path: ":id",
            name: "EditDataEntry",
            component: EditDataEntry,
            meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_DATA_ENTRY}`,
            props: (route) => ({
              britestackId: route.query.bs,
              bsItemCode: route.query.bsCode
            }),
            children: [
              {
                path: "attach-britestack",
                name: "AttachBritestack",
                component: AttachBritestackModal
              },
              {
                path: "pasteuriser/:gtcId",
                name: "EditGTCPasteuriser",
                component: EditGTCPasteuriser
              },
              {
                path: "edit-cooling",
                name: "EditCooling",
                component: EditCooling
              }
            ]
          }
        ]
      },
      {
        path: "reports",
        component: Reports,
        name: "Reports",
        redirect: { name: "ReportsMenu" },
        children: [
          {
            path: "",
            component: ReportsMenu,
            name: "ReportsMenu",
            meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_REPORTS}`
          },
          {
            path: "sterilisation",
            name: "Sterilisation",
            component: Sterilisation,
            meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_REPORTS}`
          },
          {
            path: "reports-printed-log",
            name: "ReportsPrintedLog",
            component: ReportsPrintedLog,
            meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_REPORTS}`
          },
          {
            path: "sterilisation-processes",
            name: "SterilisationProcesses",
            component: SterilisationProcesses,
            meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_REPORTS}`
          }
        ]
      },
      {
        path: "britestacks",
        component: Britestack,
        name: "Britestacks",
        meta: "masterfiles",
        children: [
          {
            path: "",
            name: "ListBritestacks",
            component: ListBritestacks,
            meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_BRITESTACK}`,
            children: [
              {
                path: "advanced-search",
                name: "FilterBritestacks",
                component: FilterBritestack,
                meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_BRITESTACK}`
              },
              {
                path: "create",
                name: "CreateBritestack",
                component: CreateBritestack,
                meta: `;${MAINTAIN_PERMISSION};${STERILIZATION_SPECIFICATIONS_BRITESTACK}`
              }
            ]
          },
          {
            path: ":id",
            name: "EditBritestack",
            component: EditBritestack,
            meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_BRITESTACK}`,
            props: (route) => ({
              id: +route.params.id,
              tab: route.query.tab
            }),
            children: [
              {
                path: "finished-products",
                name: "ListFinishedProducts",
                component: ViewFinishedProducts,
                meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_BRITESTACK}`
              }
            ]
          }
        ]
      },
      {
        path: "audit-logs",
        name: "ListAuditLog",
        component: ListAuditLog,
        meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_AUDITLOGS}`,
        children: [
          {
            path: ":id",
            name: "DetailAuditLog",
            component: DetailAuditLog,
            meta: `;${VIEW_PERMISSION};${STERILIZATION_SPECIFICATIONS_AUDITLOGS}`
          }
        ]
      }
    ]
  }
];
const install = (Vue, options = {}) => {
  const { router } = options;
  Object.values(components).map((component) => {
    let name = options.prefix ? options.prefix + component.name : component.name;
    Vue.component(name, component);
  });
  if (router) {
    router.addRoutes(routes);
  }
};
export { install as i, normalizeComponent as n };
