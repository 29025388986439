import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

import Vue from "vue";

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

setInteractionMode("lazy");

import "./rules";
