import _ from "lodash";
import { extend } from "vee-validate";
import { messages } from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

const getWeeksBetweenDates = (startDate, endDate) => {
  const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;

  const startTime = startDate.getTime();
  const endTime = endDate.getTime();

  const timeDifference = Math.abs(endTime - startTime);

  const totalWeeks = Math.ceil(timeDifference / millisecondsPerWeek);

  return totalWeeks;
};

Object.keys(rules).forEach((rule) => {
  if (rule == "required") {
    extend(rule, {
      ...rules[rule],
      message: "{_field_} is required",
    });

    return;
  }

  extend(rule, {
    ...rules[rule],
    message: messages[rule],
  });
});

extend("number", {
  validate: (value) => {
    value = typeof value === "string" ? value.trim() : value;
    return !isNaN(value);
  },
  message: (field) => {
    return `${field} must be a number`;
  },
});

extend("integer", {
  validate(value) {
    value = typeof value === "string" ? value.trim() : value;
    return Number.isInteger(Number(value)) && !value.toString().includes(".");
  },
  message: "{_field_} must be an integer",
});

extend("regexCustom", {
  validate: (value, { regex }) => {
    return value ? regex.test(value.toString()) : true;
  },
  params: ["regex", "message"],
  message: "{_field_} {message}",
});

extend("greater", {
  validate(value, args) {
    const calculate = value - args.valueCompare;
    return calculate < 0 ? false : true;
  },
  params: ["valueCompare", "type", "fieldCompare"],
  message:
    "Please make sure that the input {_field_} {type} is greater than {fieldCompare} {type}",
});

extend("message", {
  validate(value, args) {
    return args.customMessage.length === 0 ? true : false;
  },
  params: ["customMessage"],
  message: "{customMessage}",
});

extend("decimal", {
  validate(value, { wholeLength, fractionalLength }) {
    let [whole, fractional] = value.toString().split(".");

    if (_.isNil(fractional)) fractional = "";

    return whole.length <= wholeLength && fractional.length <= fractionalLength;
  },
  params: ["wholeLength", "fractionalLength"],
  message: (field, { _value_, wholeLength, fractionalLength }) => {
    let [, fractional] = _value_.toString().split(".");

    if (fractional && fractional.length > fractionalLength) {
      return `Fractional part is ${fractionalLength} numbers max`;
    }

    return `Whole part is ${wholeLength} numbers max`;
  },
});

extend("positive", {
  validate(value) {
    value = typeof value === "string" ? value.trim() : value;
    return !isNaN(value) && value >= 0;
  },
  message: "{_field_} must be a positive value",
});

extend("not_existed", {
  validate(value, { list }) {
    if (!Array.isArray(list)) list = [list];
    const existedTimes = list && list.filter((item) => item === value).length;
    return existedTimes <= 1;
  },
  params: ["list"],
  message: "{_field_} is already existed",
});

extend("not_equal", {
  validate(value, { list }) {
    if (!isNaN(value)) value = Number(value);

    for (const val of list) {
      if (val == value) return false;
    }

    return true;
  },
  params: ["list"],
  message: "{_field_} is not valid",
});

extend("not_same", {
  validate(value, args) {
    return args.firstValue !== args.secondValue;
  },
  params: ["firstValue", "secondValue", "fieldCompare"],
  message: "{_field_} is the same as {fieldCompare}",
});

extend("max_selected", {
  validate(value, { maxLength }) {
    return value.length <= Number(maxLength);
  },
  params: ["maxLength"],
  message: "{_field_} can have at most {maxLength} options",
});

extend("min_selected", {
  validate(value, { minLength }) {
    return value.length >= Number(minLength);
  },
  params: ["minLength"],
  message: "{_field_} must have at least {minLength} options",
});

extend("is_over_num_week", {
  validate(value, { numWeek }) {
    const totalWeeks = getWeeksBetweenDates(
      new Date(value[0]),
      new Date(value[1])
    );
    return totalWeeks < Number(numWeek);
  },
  params: ["numWeek"],
  message: "{_field_} must be less than {numWeek} weeks",
});
