import { ADD_PERMISSION, PROMOTIONAL_PLANNING_PROMO_LIST, VIEW_PERMISSION, PROMOTIONAL_PLANNING_PROMO_ENTRY, PROMOTIONAL_PLANNING_PROMO_PRODUCT, EDIT_PERMISSION, PROMOTIONAL_PLANNING_PROMO_CUSTOMER, PROMOTIONAL_PLANNING_PROMO_PANDL, PROMOTIONAL_PLANNING_PROMO_FIXED_TERMS, PROMOTIONAL_PLANNING_PROMO_UPLOAD_EXPORT, PROMOTIONAL_PLANNING_PROMO_UNIT_PERCENTAGE, PROMOTIONAL_PLANNING_PROMO_OPTIMIZATION_DETAIL, PROMOTIONAL_PLANNING_PROMO_LOCATION_LIST, PROMOTIONAL_PLANNING_PROMO_EVENT_LIST } from "@kraftheinz/common";
import numeral from "numeral";
const PromotionalPlanning = () => import("./PromotionalPlanning.js");
const PromoEntry = () => import("./index2.js");
const PromoDetail = () => import("./index3.js");
const ListProduct = () => import("./index4.js");
const CreateProduct = () => import("./index5.js");
const EditProduct = () => import("./index6.js");
const FilterProduct = () => import("./index7.js");
const ListCustomer = () => import("./index8.js");
const CreateCustomer = () => import("./index9.js");
const EditCustomer = () => import("./index10.js");
const ListPlLine = () => import("./index11.js");
const CreatePlLine = () => import("./index12.js");
const EditPlLine = () => import("./index13.js");
const ListFixedTerm = () => import("./index14.js");
const CreateFixedTerm = () => import("./index15.js");
const EditFixedTerm = () => import("./index16.js");
const UploadExport = () => import("./index17.js");
const ListUnitPercentage = () => import("./index18.js");
const CreateUnitPercentage = () => import("./index19.js");
const EditUnitPercentage = () => import("./index20.js");
const PerformanceOptimization = () => import("./index21.js");
const PODetail = () => import("./index22.js");
const ListLocation = () => import("./index23.js");
const CreateLocation = () => import("./index24.js");
const EditLocation = () => import("./index25.js");
const LandingPage = () => import("./index26.js");
const ListLP = () => import("./index27.js");
const FilterAdvancedLP = () => import("./index28.js");
const ListEvent = () => import("./index29.js");
const CreateEvent = () => import("./index30.js");
const EditEvent = () => import("./index31.js");
const Retail = () => import("./index32.js");
const ScenarioBuilder = () => import("./index33.js");
var routes = [
  {
    path: "/promotion-planning",
    name: "PromotionalPlanning",
    component: PromotionalPlanning,
    redirect: { name: "LandingPage" },
    children: [
      {
        path: "promo-entries",
        name: "PromoEntries",
        component: PromoEntry,
        children: [
          {
            path: "empty",
            name: "PromoEmpty",
            component: PromoDetail,
            meta: `;${ADD_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_LIST}`
          },
          {
            path: ":id",
            name: "PromoEdit",
            component: PromoDetail,
            meta: `;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_ENTRY}`
          }
        ]
      },
      {
        path: "products",
        name: "Products",
        component: ListProduct,
        meta: `masterfiles;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_PRODUCT}`,
        children: [
          {
            path: "create",
            name: "CreateProduct",
            component: CreateProduct,
            meta: `;${ADD_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_PRODUCT}`
          },
          {
            path: "advanced-search",
            name: "FilterProduct",
            component: FilterProduct,
            meta: `;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_PRODUCT}`
          },
          {
            path: ":id",
            name: "EditProduct",
            component: EditProduct,
            meta: `;${EDIT_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_PRODUCT}`
          }
        ]
      },
      {
        path: "customers",
        name: "Customer",
        component: ListCustomer,
        meta: `masterfiles;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_CUSTOMER}`,
        children: [
          {
            path: "create",
            name: "CreateCustomer",
            component: CreateCustomer,
            meta: `;${ADD_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_CUSTOMER}`
          },
          {
            path: ":id",
            name: "EditCustomer",
            component: EditCustomer,
            meta: `;${EDIT_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_CUSTOMER}`
          }
        ]
      },
      {
        path: "pl-lines",
        name: "PlLines",
        component: ListPlLine,
        meta: `masterfiles;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_PANDL}`,
        children: [
          {
            path: "create",
            name: "CreatePlLine",
            component: CreatePlLine,
            meta: `;${ADD_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_PANDL}`
          },
          {
            path: ":id",
            name: "EditPlLine",
            component: EditPlLine,
            meta: `;${EDIT_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_PANDL}`
          }
        ]
      },
      {
        path: "fixed-terms",
        name: "FixedTerm",
        component: ListFixedTerm,
        meta: `masterfiles;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_FIXED_TERMS}`,
        children: [
          {
            path: "create",
            name: "CreatePlLine",
            component: CreateFixedTerm,
            meta: `;${ADD_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_PANDL}`
          },
          {
            path: ":id",
            name: "EditFixedTerm",
            component: EditFixedTerm,
            meta: `;${EDIT_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_PANDL}`
          }
        ]
      },
      {
        path: "upload-export",
        name: "UploadExport",
        component: UploadExport,
        meta: `upload-export;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_UPLOAD_EXPORT}`,
        children: []
      },
      {
        path: "unit-percentage",
        name: "UnitPercentage",
        component: ListUnitPercentage,
        meta: `masterfiles;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_UNIT_PERCENTAGE}`,
        children: [
          {
            path: "create",
            name: "CreateUnitPercentage",
            component: CreateUnitPercentage,
            meta: ``
          },
          {
            path: ":id",
            name: "EditUnitPercentage",
            component: EditUnitPercentage,
            meta: ``
          }
        ]
      },
      {
        path: "promo-optimization",
        name: "PromoOptimization",
        component: PerformanceOptimization,
        children: [
          {
            path: "create",
            name: "POCreate",
            component: PODetail,
            meta: `;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_OPTIMIZATION_DETAIL}`
          },
          {
            path: ":id",
            name: "POEdit",
            component: PODetail,
            meta: `;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_OPTIMIZATION_DETAIL}`
          }
        ]
      },
      {
        path: "activity-list",
        name: "LocationList",
        component: ListLocation,
        meta: `;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_LOCATION_LIST}`,
        children: [
          {
            path: "create",
            name: "CreateLocation",
            component: CreateLocation,
            meta: `;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_LOCATION_LIST}`
          },
          {
            path: ":id",
            name: "EditLocation",
            component: EditLocation,
            meta: `;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_LOCATION_LIST}`
          }
        ]
      },
      {
        path: "landing-page",
        name: "LandingPage",
        component: LandingPage,
        children: [
          {
            path: "",
            name: "LandingPage",
            component: ListLP,
            children: [
              {
                path: "advanced-search",
                name: "FilterLP",
                component: FilterAdvancedLP
              }
            ]
          }
        ]
      },
      {
        path: "event-list",
        name: "EventList",
        component: ListEvent,
        meta: `;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_EVENT_LIST}`,
        children: [
          {
            path: "create",
            name: "CreateEvent",
            component: CreateEvent,
            meta: `;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_EVENT_LIST}`
          },
          {
            path: ":id",
            name: "EditEvent",
            component: EditEvent,
            meta: `;${VIEW_PERMISSION};${PROMOTIONAL_PLANNING_PROMO_EVENT_LIST}`
          }
        ]
      },
      {
        path: "retail-price",
        name: "Retail Price",
        component: Retail
      },
      {
        path: "scenario-builder",
        name: "Scenario Builder",
        component: ScenarioBuilder
      }
    ]
  }
];
function isNil(value) {
  return value == null;
}
var isNil_1 = isNil;
const QUARTER_LENGTH = 13;
const SHELF_PRICE_CODE = 1;
const COLOR_LIST_ENTRY_PRICE = [
  {
    name: "Shelf Price",
    code: "#BFBFC1",
    opacityColor: "#fff"
  },
  { name: "Promo 1", code: "#45DFAB", opacityColor: "rgb(69, 223, 171, 0.2)" },
  { name: "Promo 2", code: "#FF9C6E", opacityColor: "rgb(255, 156, 110, 0.2)" },
  { name: "Promo 3", code: "#B37FEB", opacityColor: "rgb(179, 127, 235, 0.2)" },
  { name: "Promo 4", code: "#FFD666", opacityColor: "rgb(255, 214, 102, 0.2)" },
  { name: "Promo 5", code: "#FF7875", opacityColor: "rgb(255, 120, 117, 0.2)" },
  { name: "Promo 6", code: "#5CDBD3", opacityColor: "rgb(92, 219, 211, 0.2)" },
  { name: "Promo 7", code: "#85A5FF", opacityColor: "rgb(133, 165, 255, 0.2)" },
  { name: "Promo 8", code: "#FF85C0", opacityColor: "rgb(255, 133, 192, 0.2)" },
  { name: "Promo 9", code: "#D3F261", opacityColor: "rgb(211, 242, 97, 0.2)" },
  { name: "Total", code: "#074E94", opacityColor: "rgb(95, 166, 227, 0.2)" },
  { name: "Disable", code: "#EAEDF0", opacityColor: "rgb(234, 237, 240, 0.2)" }
];
const COLOR_OBJECT_ENTRY_PRICE = {
  ShelfPrice: { code: "#000", opacityColor: "" },
  Promo1: {
    code: "#45DFAB",
    opacityColor: "rgb(69, 223, 171, 0.2)",
    bold: false
  },
  Promo2: {
    code: "#FF9C6E",
    opacityColor: "rgb(255, 156, 110, 0.2)",
    bold: false
  },
  Promo3: {
    code: "#B37FEB",
    opacityColor: "rgb(179, 127, 235, 0.2)",
    bold: false
  },
  Promo4: {
    code: "#FFD666",
    opacityColor: "rgb(255, 214, 102, 0.2)",
    bold: false
  },
  Promo5: {
    code: "#FF7875",
    opacityColor: "rgb(255, 120, 117, 0.2)",
    bold: false
  },
  Promo6: {
    code: "#5CDBD3",
    opacityColor: "rgb(92, 219, 211, 0.2)",
    bold: false
  },
  Promo7: {
    code: "#85A5FF",
    opacityColor: "rgb(133, 165, 255, 0.2)",
    bold: false
  },
  Promo8: {
    code: "#FF85C0",
    opacityColor: "rgb(255, 133, 192, 0.2)",
    bold: false
  },
  Promo9: {
    code: "#D3F261",
    opacityColor: "rgb(211, 242, 97, 0.2)",
    bold: false
  },
  Total: {
    code: "#074E94",
    opacityColor: "rgb(221, 235, 247, 0.2)",
    bold: false
  },
  OverWrite: { code: "#1b00ff", opacityColor: "#fff", bold: true }
};
const LIST_ROW_NAME_ENTRY_PRICE = [
  {
    name: "Tactic",
    dataIndex: "tacticName",
    type: "text",
    valid: true,
    disabled: true
  },
  {
    name: "Price",
    dataIndex: "retailPrice",
    type: "text",
    suffixInput: "$",
    validations: "number|positive|not_equal:0|decimal:12,3",
    numberFormat: "$0,0.00",
    valid: true,
    disabled: true
  },
  {
    name: "Frequency",
    dataIndex: "frequency",
    type: "text",
    validations: "integer|positive",
    numberFormat: "0,0",
    valid: true,
    disabled: true
  },
  {
    name: "Redemption Unit",
    dataIndex: "redemptionUnits",
    type: "text",
    valid: true,
    disabled: true
  },
  {
    name: "Volume Units/Case",
    isBold: true,
    dataIndex: "none",
    type: "none"
  },
  {
    name: "Scan (Week)",
    dataIndex: "scanVolume",
    type: "text",
    canExpand: true,
    validations: "integer|positive|not_equal:0|required",
    numberFormat: "0,0",
    valid: true,
    required: true,
    disabled: false
  },
  {
    name: "Sellthrough (Week)",
    dataIndex: "sellthroughVolume",
    type: "text",
    canExpand: true,
    validations: "integer|positive|not_equal:0|required",
    numberFormat: "0,0",
    valid: true,
    required: true,
    disabled: false
  },
  {
    name: "Deals",
    isBold: true,
    dataIndex: "none",
    type: "none"
  },
  {
    name: "Off Invoice",
    dataIndex: "offInvoice",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0.00",
    valid: true,
    disabled: true
  },
  {
    name: "Margin Support",
    dataIndex: "marginSupport",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0.00",
    valid: true,
    disabled: true
  },
  {
    name: "Case Deal %",
    dataIndex: "caseDealPercent",
    type: "text",
    suffixInput: "%",
    validations: "number|min_value:0|max_value:100",
    numberFormat: "0.0000%",
    valid: true,
    disabled: true
  },
  {
    name: "Case Deal / SGTT",
    dataIndex: "caseDealSGTT",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0.00",
    valid: true,
    disabled: true
  },
  {
    name: "WWD",
    dataIndex: "wwd",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0.00",
    valid: true,
    disabled: true
  },
  {
    name: "Base Scan Deal",
    dataIndex: "baseScanDeal",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0.00",
    valid: true,
    disabled: true
  },
  {
    name: "Top Up Scan Deal",
    dataIndex: "topUpScanDeal",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0.00",
    valid: true,
    disabled: true
  },
  {
    name: "Redemption %",
    dataIndex: "redemptionPercent",
    type: "text",
    suffixInput: "%",
    validations: "number|min_value:0|max_value:100",
    numberFormat: "0.0%",
    valid: true,
    disabled: true
  },
  {
    name: "Coop Display (Lump)",
    dataIndex: "coopDisplay",
    type: "text",
    valid: true,
    disabled: true
  },
  {
    name: "Financials per Unit/Case",
    isBold: true,
    dataIndex: "none",
    type: "none"
  },
  {
    name: "Invoice Value",
    dataIndex: "invoiceValue",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0.00",
    valid: true,
    disabled: true
  },
  {
    name: "Upfront NIS",
    dataIndex: "upfrontNIS",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0.00",
    valid: true,
    disabled: true
  },
  {
    name: "Net NIS",
    dataIndex: "netNIS",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0.00",
    valid: true,
    disabled: true
  },
  {
    name: "NSV",
    dataIndex: "nsvfpu",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0.00",
    valid: true,
    disabled: true
  },
  {
    name: "KHC Financials",
    isBold: true,
    dataIndex: "none",
    type: "none"
  },
  {
    name: "LSV",
    dataIndex: "lsv",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0",
    valid: true,
    disabled: true
  },
  {
    name: "Trade Spend",
    dataIndex: "tradeSpend",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0",
    valid: true,
    disabled: true
  },
  {
    name: "NSV",
    dataIndex: "nsvkf",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0",
    valid: true,
    disabled: true
  },
  {
    name: "CMA",
    dataIndex: "cma",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0",
    valid: true,
    disabled: true
  },
  {
    name: "CMA%",
    dataIndex: "cmaPercent",
    type: "text",
    suffixInput: "%",
    validations: "number|min_value:0|max_value:100",
    numberFormat: "0.0%",
    valid: true,
    disabled: true
  },
  {
    name: "Retailer Financials",
    isBold: true,
    dataIndex: "none",
    type: "none"
  },
  {
    name: "RSV (excl GST)",
    dataIndex: "rsv",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0",
    valid: true,
    disabled: true
  },
  {
    name: "Banner GM",
    dataIndex: "bannerGM",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0",
    valid: true,
    disabled: true
  },
  {
    name: "Banner GM %",
    dataIndex: "bannerGMPercent",
    type: "text",
    suffixInput: "%",
    validations: "number|min_value:0|max_value:100",
    numberFormat: "0.0%",
    valid: true,
    disabled: true
  },
  {
    name: "Store GM",
    dataIndex: "storeGM",
    type: "text",
    suffixInput: "$",
    validations: "number|positive",
    numberFormat: "$0,0",
    valid: true,
    disabled: true
  },
  {
    name: "Store GM %",
    dataIndex: "storeGMPercent",
    type: "text",
    suffixInput: "%",
    validations: "number|min_value:0|max_value:100",
    numberFormat: "0.0%",
    valid: true,
    disabled: true
  }
];
const LIST_HEADER_ENTRY_PRICE = [
  "Sale Price",
  "Promo 1",
  "Promo 2",
  "Promo 3",
  "Promo 4",
  "Promo 5",
  "Promo 6",
  "Promo 7",
  "Promo 8",
  "Promo 9"
];
[...LIST_HEADER_ENTRY_PRICE, "Total"];
const LIST_HEADER_PL = ["Ver. 1", "Ver. 2", "Ver. 3"];
const LIST_ROW_NAME_ENTRY_KH_PL = [
  {
    name: "Units",
    dataIndex: "unit",
    dataNextIndex: "unitPercent",
    isBold: true
  },
  {
    name: "Cases",
    dataIndex: "cases",
    dataNextIndex: "casesPercent",
    isBold: true,
    noneBorder: true
  },
  {
    name: "List Sales Value",
    dataIndex: "listSalesValue",
    dataNextIndex: "listSalesValuePercent",
    isBold: true
  },
  {
    name: "Fixed Terms",
    dataIndex: "fixedTerms",
    dataNextIndex: "fixedTermsPercent",
    isBold: false
  },
  {
    name: "Efficiencies",
    dataIndex: "efficiencies",
    dataNextIndex: "efficienciesPercent",
    isChild: true,
    isBold: false
  },
  {
    name: "Rebates",
    dataIndex: "rebates",
    dataNextIndex: "rebatesPercent",
    isChild: true,
    isBold: false
  },
  {
    name: "Other",
    dataIndex: "other",
    dataNextIndex: "otherPercent",
    isChild: true,
    isBold: false
  },
  {
    name: "Growth Incentive",
    dataIndex: "growthIncentive",
    dataNextIndex: "growthIncentivePercent",
    isChild: true,
    isBold: false
  },
  {
    name: "Volumetric Discounts",
    dataIndex: "volumetricDiscounts",
    dataNextIndex: "volumetricDiscountsPercent",
    isBold: false
  },
  {
    name: "Promo Off Invoice Discounts",
    dataIndex: "promoOffInvoiceDiscounts",
    dataNextIndex: "promoOffInvoiceDiscountsPercent",
    isBold: false
  },
  {
    name: "Off Invoice Discounts",
    dataIndex: "offInvoice",
    dataNextIndex: "offInvoicePercent",
    isChild: true,
    isBold: false
  },
  {
    name: "Margin Support Off Invoice",
    dataIndex: "marginSupport",
    dataNextIndex: "marginSupportPercent",
    isChild: true,
    isBold: false
  },
  {
    name: "Every Day Low Price Off Invoice ",
    dataIndex: "edlp",
    dataNextIndex: "edlpPercent",
    isChild: true,
    isBold: false
  },
  {
    name: "Invoiced Sales",
    dataIndex: "invoicedSales",
    dataNextIndex: "invoicedSalesPercent",
    isBold: true
  },
  {
    name: "Scan",
    dataIndex: "scan",
    dataNextIndex: "scanPercent",
    isBold: false
  },
  {
    name: "Warehouse Withdrawl",
    dataIndex: "warehouseWithdraw",
    dataNextIndex: "warehouseWithdrawPercent",
    isBold: false
  },
  {
    name: "Case Deal Deferred",
    dataIndex: "caseDeal",
    dataNextIndex: "caseDealPercent",
    isBold: false
  },
  {
    name: "Co-op Add",
    dataIndex: "coopAdd",
    dataNextIndex: "coopAddPercent",
    isBold: false
  },
  {
    name: "Co-Op Display",
    dataIndex: "coopDisplay",
    dataNextIndex: "coopDisplayPercent",
    isBold: false
  },
  {
    name: "Total Trade Investment",
    dataIndex: "tti",
    dataNextIndex: "ttiPercent",
    isBold: true
  },
  {
    name: "Net Sales Value (NSV)",
    dataIndex: "nsv",
    dataNextIndex: "nsvPercent",
    noneBorder: true,
    isBold: true
  },
  {
    name: "Cost of Goods",
    dataIndex: "costOfGoods",
    dataNextIndex: "costOfGoodsPercent",
    isBold: false
  },
  {
    name: "Contribution Margin (CMA)",
    dataIndex: "cma",
    dataNextIndex: "cmaPercent",
    noneBorder: true,
    isBold: true
  }
];
const LIST_ROW_NAME_ENTRY_RETAIL_PL = [
  {
    name: "Units",
    dataIndex: "unit",
    dataNextIndex: "unitPercent",
    isBold: true
  },
  {
    name: "Cases",
    dataIndex: "cases",
    dataNextIndex: "casesPercent",
    isBold: true,
    noneBorder: true
  },
  {
    name: "RRP Sales Value",
    dataIndex: "rrpSaleValue",
    dataNextIndex: "rrpSaleValuePercent",
    isBold: true
  },
  {
    name: "Consumer Discount",
    dataIndex: "consumerDiscount",
    dataNextIndex: "consumerDiscountPercent",
    isBold: false
  },
  {
    name: "KH Funded",
    dataIndex: "khFunded",
    dataNextIndex: "khFundedPercent",
    isChild: true,
    isBold: false
  },
  {
    name: "Retailer Funded",
    dataIndex: "retailerFunded",
    dataNextIndex: "retailerFundedPercent",
    isChild: true,
    isBold: false
  },
  {
    name: "none",
    dataIndex: "none",
    dataNextIndex: "none",
    isBold: false
  },
  {
    name: "none",
    dataIndex: "none",
    dataNextIndex: "none",
    isBold: false
  },
  {
    name: "Retail Sales Value",
    dataIndex: "retailSaleValue",
    dataNextIndex: "retailSaleValuePercent",
    isBold: true
  },
  {
    name: "Cost of Goods",
    dataIndex: "costOfGoods2",
    dataNextIndex: "costOfGoods2Percent"
  },
  {
    name: "Invoiced Sales",
    dataIndex: "retailerInvoicedSales",
    dataNextIndex: "retailerInvoicedSalesPercent",
    isBold: false,
    isFirstChild: true
  },
  {
    name: "Rebates",
    dataIndex: "retailerRebates",
    dataNextIndex: "retailerRebatesPercent",
    isBold: false,
    isFirstChild: true
  },
  {
    name: "Other Items",
    dataIndex: "retailerOther",
    dataNextIndex: "retailerOtherPercent",
    isFirstChild: true,
    isBold: false
  },
  {
    name: "Growth Incentive",
    dataIndex: "retailerGrowthIncentive",
    dataNextIndex: "retailerGrowthIncentivePercent",
    isFirstChild: true,
    isBold: false
  },
  {
    name: "Scan",
    dataIndex: "retailerScan",
    dataNextIndex: "retailerScanPercent",
    isFirstChild: true,
    isBold: false
  },
  {
    name: "Warehouse Withdrawl",
    dataIndex: "retailerWarehouseWithdraw",
    dataNextIndex: "retailerWarehouseWithdrawPercent",
    isFirstChild: true,
    isBold: false
  },
  {
    name: "Case Deal Deferred",
    dataIndex: "retailerCaseDeal",
    dataNextIndex: "retailerCaseDealPercent",
    isFirstChild: true,
    isBold: false,
    multiplier: -1
  },
  {
    name: "none",
    dataIndex: "none",
    dataNextIndex: "none",
    isBold: false
  },
  {
    name: "none",
    dataIndex: "none",
    dataNextIndex: "none",
    isBold: false
  },
  {
    name: "Gross Margin excl Co-Op",
    dataIndex: "gmec",
    dataNextIndex: "gmecPercent",
    isBold: true
  },
  {
    name: "Co-op Add",
    dataIndex: "retailerCoopAdd",
    dataNextIndex: "retailerCoopAddPercent",
    isFirstChild: true,
    isBold: false
  },
  {
    name: "Co-Op Display",
    dataIndex: "retailerCoopDisplay",
    dataNextIndex: "retailerCoopDisplayPercent",
    isFirstChild: true,
    isBold: false
  },
  {
    name: "Gross Margin incl Co-Op",
    dataIndex: "gmic",
    dataNextIndex: "gmicPercent",
    isBold: true,
    noneBorder: true
  }
];
const LIST_COL_NAME_WEEKLY_PRICE = [
  {
    name: "Week",
    dataIndex: "startDay",
    defaultVisible: true,
    key: "startDay",
    width: 150
  },
  {
    name: "Primary Plan - Promo",
    dataIndex: "tacticName",
    defaultVisible: true,
    key: "tacticName",
    parse: (value) => value || null,
    readonly: true,
    width: 100
  },
  {
    name: "Primary Plan - Price",
    dataIndex: "seqNumber",
    defaultVisible: true,
    numberFormat: "$0,0.00",
    suffixInput: "$",
    valid: true,
    type: "select",
    dataSource: "primaryPrices",
    source: "seqNumber",
    sourceLabel: "price",
    validations: "required",
    key: "seqNumber",
    width: 100
  },
  {
    name: "Online Plan - Promo",
    dataIndex: "tacticNameOnline",
    defaultVisible: true,
    key: "tacticNameOnline",
    parse: (value) => value || null,
    readonly: true,
    width: 100
  },
  {
    name: "Online Plan - Price",
    dataIndex: "seqNumberOnline",
    defaultVisible: true,
    numberFormat: "$0,0.00",
    suffixInput: "$",
    valid: true,
    type: "select",
    dataSource: "onlinePrices",
    source: "seqNumber",
    sourceLabel: "price",
    key: "seqNumberOnline",
    width: 100
  },
  {
    name: "Total Unit",
    dataIndex: "unit",
    defaultVisible: true,
    numberFormat: "0",
    key: "unit",
    validations: "positive",
    parse: (value, record) => value && record.seqNumber !== 1 ? value : null,
    valid: true,
    width: 100
  },
  {
    name: "Baseline (BY)",
    key: "baseline",
    dataIndex: "baseline",
    defaultVisible: true,
    numberFormat: "0,0",
    readonly: true,
    parse: (value) => value ? numeral(value).format("0,0") : "",
    width: 120
  },
  {
    name: "Uplift",
    key: "uplift",
    dataIndex: "uplift",
    defaultVisible: true,
    hasExpandButton: true,
    numberFormat: "0,0",
    readonly: true,
    parse: (value, record) => !record.baseline || [0, 1].includes(record.seqNumber) || !record.seqNumber ? "" : numeral(value).format("0,0"),
    width: 100
  },
  {
    name: "Co-op",
    dataIndex: "coopDisplay",
    defaultVisible: true,
    numberFormat: "$0,0.0",
    suffixInput: "$",
    valid: true,
    type: "text",
    key: "coopDisplay",
    hasTooltip: true,
    width: 100
  },
  {
    name: "Display",
    dataIndex: "primaryInstoreDisplayId",
    defaultVisible: true,
    numberFormat: "",
    valid: true,
    type: "select",
    dataSource: "instoreDisplays",
    source: "id",
    sourceLabel: "description",
    key: "primaryInstoreDisplayId",
    hasTooltip: true,
    width: 100
  },
  {
    name: "Feature",
    dataIndex: "secondInstoreDisplayId",
    defaultVisible: true,
    numberFormat: "",
    valid: true,
    type: "select",
    dataSource: "instoreDisplays",
    source: "id",
    sourceLabel: "description",
    key: "secondInstoreDisplayId",
    hasTooltip: true,
    width: 100
  },
  {
    name: "Catalogue",
    dataIndex: "thirdInstoreDisplayId",
    defaultVisible: true,
    numberFormat: "",
    valid: true,
    type: "select",
    dataSource: "instoreDisplays",
    source: "id",
    sourceLabel: "description",
    key: "thirdInstoreDisplayId",
    hasTooltip: true,
    width: 100
  },
  {
    name: "Event",
    dataIndex: "eventId",
    defaultVisible: true,
    valid: true,
    type: "select",
    dataSource: "events",
    source: "id",
    sourceDescription: "description",
    sourceLabel: "description",
    key: "eventId",
    hasTooltip: true,
    width: 100
  },
  {
    name: "Note",
    dataIndex: "note",
    defaultVisible: true,
    valid: true,
    key: "note",
    hasTooltip: true,
    width: 200
  }
];
const LIST_ROW_NAME_SUB_PANEL = [
  {
    name: "IRI Retail Price",
    dataIndex: "iriRetailPrice",
    valid: true,
    type: "text",
    key: "iriRetailPrice"
  },
  {
    name: "IRI Units",
    dataIndex: "iriUnits",
    validations: "integer|positive|not_equal:0",
    numberFormat: "0,0",
    valid: true,
    type: "text",
    key: "iriUnits"
  },
  {
    name: "Volume Variance",
    dataIndex: "volumeVariance",
    validations: "integer|not_equal:0",
    numberFormat: "0,0",
    valid: true,
    type: "text",
    key: "volumeVariance"
  }
];
const PROMOTION_LIST = 1;
const PROMOTION_ANALYSIS = 2;
const DEMANTRA = 3;
const PRICE_VOLUME_HISTORY = 4;
const UPLOAD_TYPE = [
  {
    type: 1,
    name: "Product",
    url: "/promotion-planning/products/import",
    templateType: "csv"
  },
  {
    type: 2,
    name: "Promotion List",
    url: "/promotion-planning/promo-entries/upload",
    templateType: "csv"
  },
  {
    type: 3,
    name: "Sales Plan",
    url: "/promotion-planning/promo-entries/upload-detail",
    templateType: "csv"
  },
  {
    type: 4,
    name: "Price Volume History",
    url: "/promotion-planning/promo-entries/upload",
    templateType: "xlsx"
  },
  {
    type: 5,
    name: "POT",
    url: "/promotion-planning/promo-entries/upload-pot",
    templateType: "xlsx"
  },
  {
    type: 6,
    name: "Mass Publish",
    url: "/promotion-planning/promo-entries/upload-publishpot",
    templateType: "csv"
  },
  {
    type: 7,
    name: "Mass Acceptance",
    url: "/promotion-planning/promo-entries/upload-mass-acceptance",
    templateType: "csv"
  },
  {
    type: 8,
    name: "Mass Deletion",
    url: "/promotion-planning/promo-entries/upload-mass-deletion",
    templateType: "csv"
  }
];
const EXPORT_TYPE_NAMES = {
  [PROMOTION_LIST]: "Promotion List",
  [PROMOTION_ANALYSIS]: "Promotion Analysis",
  [DEMANTRA]: "Demantra",
  [PRICE_VOLUME_HISTORY]: "Price Volume History"
};
const EXPORT_TYPE_PERMISSION = {
  [PROMOTION_LIST]: "exportpromolist",
  [PROMOTION_ANALYSIS]: "exportanalysis",
  [DEMANTRA]: "exportdemantra",
  [PRICE_VOLUME_HISTORY]: true
};
const EXPORT_TYPES = [
  {
    value: PROMOTION_LIST,
    name: EXPORT_TYPE_NAMES[PROMOTION_LIST],
    permission: EXPORT_TYPE_PERMISSION[PROMOTION_LIST]
  },
  {
    value: PROMOTION_ANALYSIS,
    name: EXPORT_TYPE_NAMES[PROMOTION_ANALYSIS],
    permission: EXPORT_TYPE_PERMISSION[PROMOTION_ANALYSIS]
  },
  {
    value: DEMANTRA,
    name: EXPORT_TYPE_NAMES[DEMANTRA],
    permission: EXPORT_TYPE_PERMISSION[DEMANTRA]
  },
  {
    value: PRICE_VOLUME_HISTORY,
    name: EXPORT_TYPE_NAMES[PRICE_VOLUME_HISTORY],
    permission: EXPORT_TYPE_PERMISSION[PRICE_VOLUME_HISTORY]
  }
];
const CSV_MIME_TYPES = [
  "",
  "application/csv",
  "application/vnd.ms-excel",
  "application/x-csv",
  "text/comma-separated-values",
  "text/csv",
  "text/plain",
  "text/tab-separated-values",
  "text/x-comma-separated-values",
  "text/x-csv"
];
const EXCEL_MIME_TYPES = [
  "",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel"
];
var ExportTypes = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  [Symbol.toStringTag]: "Module",
  PROMOTION_LIST,
  PROMOTION_ANALYSIS,
  DEMANTRA,
  PRICE_VOLUME_HISTORY,
  UPLOAD_TYPE,
  EXPORT_TYPE_NAMES,
  EXPORT_TYPE_PERMISSION,
  EXPORT_TYPES,
  CSV_MIME_TYPES,
  EXCEL_MIME_TYPES
});
const FREQUENCY_CHECK_GREEN_CODE = 1;
const FREQUENCY_CHECK_ORANGE_CODE = 2;
const FREQUENCY_CHECK_RED_CODE = 3;
const FREQUENCY_CHECK_GREEN_COLOR = "#15b982";
const FREQUENCY_CHECK_ORANGE_COLOR = "#fa8c16";
const FREQUENCY_CHECK_RED_COLOR = "#f5222d";
const FREQUENCY_CHECK_GREEN_ICON = "check-circle";
const FREQUENCY_CHECK_ORANGE_ICON = "info-circle";
const FREQUENCY_CHECK_RED_ICON = "close-circle";
const FREQUENCY_CHECK = {
  [FREQUENCY_CHECK_GREEN_CODE]: {
    color: FREQUENCY_CHECK_GREEN_COLOR,
    icon: FREQUENCY_CHECK_GREEN_ICON
  },
  [FREQUENCY_CHECK_ORANGE_CODE]: {
    color: FREQUENCY_CHECK_ORANGE_COLOR,
    icon: FREQUENCY_CHECK_ORANGE_ICON
  },
  [FREQUENCY_CHECK_RED_CODE]: {
    color: FREQUENCY_CHECK_RED_COLOR,
    icon: FREQUENCY_CHECK_RED_ICON
  }
};
const PROMO_SALES_PLAN = "saleplan";
const PROMO_SALES_ACCEPTED = "saleplanic";
const PROMO_SALES_REVIEW = "salereview";
const PROMO_POT = "pot";
const PROMO_REVMAN_REVIEW = "revmanreview";
const PROMO_SALES_PLAN_COLOR = "success-6";
const PROMO_SALES_ACCEPTED_COLOR = "sales-plan-ic";
const PROMO_SALES_REVIEW_COLOR = "gold-6";
const PROMO_POT_COLOR = "gray";
const PROMO_REVMAN_REVIEW_COLOR = "purple-6";
const PROMO_STATUS_COLORS = {
  [PROMO_SALES_PLAN]: PROMO_SALES_PLAN_COLOR,
  [PROMO_SALES_ACCEPTED]: PROMO_SALES_ACCEPTED_COLOR,
  [PROMO_SALES_REVIEW]: PROMO_SALES_REVIEW_COLOR,
  [PROMO_POT]: PROMO_POT_COLOR,
  [PROMO_REVMAN_REVIEW]: PROMO_REVMAN_REVIEW_COLOR
};
const POT_STATUSES = [PROMO_POT, PROMO_REVMAN_REVIEW];
const REJECT_SUCCESS = "Comment is saved successully!";
const ACCEPT_SUCCESS = "Thank you for your approval!";
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("a-popover", { attrs: { "content": _vm.content, "overlay-class-name": "freq-check--popover__dark" } }, [_vm.hasIcon ? [_c("a-icon", _vm._b({ attrs: { "theme": "filled" } }, "a-icon", _vm.freqCheckIcon, false))] : _vm._e()], 2);
};
var staticRenderFns = [];
var FreqCheckIconField_vue_vue_type_style_index_0_lang = "";
function normalizeComponent(scriptExports, render2, staticRenderFns2, functionalTemplate, injectStyles, scopeId, moduleIdentifier, shadowMode) {
  var options = typeof scriptExports === "function" ? scriptExports.options : scriptExports;
  if (render2) {
    options.render = render2;
    options.staticRenderFns = staticRenderFns2;
    options._compiled = true;
  }
  if (functionalTemplate) {
    options.functional = true;
  }
  if (scopeId) {
    options._scopeId = "data-v-" + scopeId;
  }
  var hook;
  if (moduleIdentifier) {
    hook = function(context) {
      context = context || this.$vnode && this.$vnode.ssrContext || this.parent && this.parent.$vnode && this.parent.$vnode.ssrContext;
      if (!context && typeof __VUE_SSR_CONTEXT__ !== "undefined") {
        context = __VUE_SSR_CONTEXT__;
      }
      if (injectStyles) {
        injectStyles.call(this, context);
      }
      if (context && context._registeredComponents) {
        context._registeredComponents.add(moduleIdentifier);
      }
    };
    options._ssrRegister = hook;
  } else if (injectStyles) {
    hook = shadowMode ? function() {
      injectStyles.call(this, (options.functional ? this.parent : this).$root.$options.shadowRoot);
    } : injectStyles;
  }
  if (hook) {
    if (options.functional) {
      options._injectStyles = hook;
      var originalRender = options.render;
      options.render = function renderWithStyleInjection(h, context) {
        hook.call(context);
        return originalRender(h, context);
      };
    } else {
      var existing = options.beforeCreate;
      options.beforeCreate = existing ? [].concat(existing, hook) : [hook];
    }
  }
  return {
    exports: scriptExports,
    options
  };
}
const __vue2_script = {
  name: "FreqCheckIconField",
  props: {
    value: {
      type: [Boolean, String, Number, Object, Array],
      default: "Empty content"
    },
    record: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      freqCheckStatus: [PROMO_SALES_ACCEPTED, PROMO_SALES_PLAN]
    };
  },
  computed: {
    content() {
      var _a;
      return `Total variance = ${(_a = this.value) != null ? _a : 0} weeks`;
    },
    freqCheckIcon() {
      const freqCheck = FREQUENCY_CHECK[this.freqCheckType];
      return freqCheck ? {
        style: {
          color: freqCheck.color,
          fontSize: "20px"
        },
        type: freqCheck.icon
      } : {};
    },
    freqCheckType() {
      return !this.hasIcon ? null : this.value === 0 ? 1 : this.value <= 2 ? 2 : 3;
    },
    hasIcon() {
      return !isNil_1(this.value) && this.record && this.freqCheckStatus.includes(this.record.statusCode);
    }
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var FreqCheckIconField = /* @__PURE__ */ function() {
  return __component__.exports;
}();
const install = (Vue, options = {}) => {
  const { router } = options;
  if (router) {
    router.addRoutes(routes);
  }
  Vue.component("FreqCheckIconField", FreqCheckIconField);
};
export { ACCEPT_SUCCESS as A, COLOR_LIST_ENTRY_PRICE as C, ExportTypes as E, LIST_HEADER_ENTRY_PRICE as L, PROMO_SALES_REVIEW as P, QUARTER_LENGTH as Q, REJECT_SUCCESS as R, SHELF_PRICE_CODE as S, LIST_HEADER_PL as a, LIST_ROW_NAME_ENTRY_KH_PL as b, LIST_ROW_NAME_ENTRY_RETAIL_PL as c, LIST_ROW_NAME_ENTRY_PRICE as d, PROMO_SALES_ACCEPTED as e, PROMO_SALES_PLAN as f, LIST_ROW_NAME_SUB_PANEL as g, LIST_COL_NAME_WEEKLY_PRICE as h, isNil_1 as i, PROMO_STATUS_COLORS as j, POT_STATUSES as k, COLOR_OBJECT_ENTRY_PRICE as l, install as m, normalizeComponent as n };
